import Button from 'components/ui/Button';
import { IInvitation, IUser } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { Block, BlockTitle, Container, Row } from '../style';
import UserTable from './UserTable';
import { IoMailOutline } from 'react-icons/io5';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_INVITATIONS, GET_PROVIDER_USERS } from 'graphql/queries';
import { Header, NoUsersText, Tab, Tabs } from './style';
import { Loading } from 'components/ui/Animiations';
import InviteUserModal from './Modal/inviteUserModal';
import UserInfoModal from './Modal/userInfoModal';
import { H2 } from 'components/Styled';
import { Controls } from '../Settings/Products/style';
import SearchField from 'components/ui/SearchField';
import InviteeTable from './InviteeTable';
import { addDays } from 'date-fns';

export interface IUsersData {
  users: IUser[];
}

const Users = (): JSX.Element => {
  const [isOpen, toggle] = useState('');
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [userList, setUserList] = useState<IUser[]>([]);
  const [filteredUserList, setFilteredUserList] = useState<IUser[]>([]);
  const [invitations, setInvitations] = useState<IInvitation[]>([]);
  const [searchStr, setSearchStr] = useState('');
  const [activeTab, setActiveTab] = useState(1);
  const { data: usersData, loading: usersLoading } = useQuery<IUsersData>(GET_PROVIDER_USERS);
  const [getInvitations, { data: invitationData, loading: invLoading, error: invError }] = useLazyQuery(
    GET_INVITATIONS,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (usersData && usersData.users.length > 0) {
      setUserList(usersData.users);
      setFilteredUserList(usersData.users);
    }
  }, [usersData]);

  useEffect(() => {
    if (invitationData && invitationData.invitations.length > 0) {
      setInvitations(invitationData.invitations);
    }
  }, [invitationData]);

  useEffect(() => {
    if (activeTab === 2) {
      getInvitations();
    }
  }, [activeTab]);

  const renderUserTable = () => {
    if (usersLoading) return <Loading />;
    if (filteredUserList.length === 0) return <NoUsersText>No users found</NoUsersText>;
    return <UserTable users={filteredUserList} onClick={handleOnUserRowClick} />;
  };

  const renderInviteeTable = () => {
    // getInvitations();

    if (invError) return 'Error :/';
    if (invLoading) return <Loading />;
    if (invitationData?.invitations.length === 0) return <NoUsersText>No invitations found</NoUsersText>;
    return <InviteeTable invitations={invitations} />;
  };

  const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(e.target.value);
    const filteredUsers = userList.filter((user) =>
      (user.name + '|' + user.email).toLowerCase().includes(e.target.value.toLowerCase())
    );

    setFilteredUserList([...filteredUsers]);
  };

  const handleOnUserRowClick = (user: IUser) => {
    setSelectedUser(user);
    toggle('userInfo');
  };

  return (
    <Container>
      <Block>
        <BlockTitle>
          <H2 noMargin>Brugere</H2>
          <Header>
            <Controls>
              <SearchField
                onChange={handleOnSearchChange}
                value={searchStr}
                name='product-search'
                placeholder='Søg efter navn eller email'
              />

              <Tabs>
                <Tab onClick={() => setActiveTab(1)} isActive={activeTab === 1}>
                  Brugere
                </Tab>
                <Tab onClick={() => setActiveTab(2)} isActive={activeTab === 2}>
                  Ventende invitationer
                </Tab>
              </Tabs>
            </Controls>
            <Button
              label='Invitér ny bruger'
              type='button'
              icon={<IoMailOutline />}
              marginTop={24}
              onClick={() => toggle('inviteUser')}
            />
          </Header>
        </BlockTitle>
        <Row center={true}>
          {activeTab === 1 && renderUserTable()}
          {activeTab === 2 && renderInviteeTable()}
        </Row>
      </Block>
      <InviteUserModal isOpen={isOpen === 'inviteUser'} toggle={toggle} onCancel={() => toggle('')} />
      {selectedUser && (
        <UserInfoModal user={selectedUser} isOpen={isOpen === 'userInfo'} toggle={toggle} onCancel={() => toggle('')} />
      )}
    </Container>
  );
};

export default Users;
