import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { WordSpan } from './style';

interface IProps {
  words: string[];
}

const AnimateWords: React.FC<IProps> = ({ words }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      let next = index + 1;
      setIndex(next % words.length);
    }, 3000);
  }, [index, setIndex, words]);

  return (
    <AnimatePresence initial={false}>
      <WordSpan
        key={index}
        layout
        variants={{
          enter: {
            translateY: 20,
            opacity: 0,
            height: 0,
          },
          center: {
            zIndex: 1,
            translateY: 0,
            opacity: 1,
            height: 'auto',
          },
          exit: {
            zIndex: 0,
            translateY: -20,
            opacity: 0,
            height: 0,
          },
        }}
        initial='enter'
        animate='center'
        exit='exit'
        transition={{
          translateY: { type: 'spring', stiffness: 1000, damping: 200 },
          opacity: { duration: 0.5 },
        }}
      >
        {words[index]}
      </WordSpan>
    </AnimatePresence>
  );
};

export default AnimateWords;
