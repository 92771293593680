import React, { useCallback, useState } from 'react';
import BillingTab from './billing';
import BookingsTab from './bookings';
import GeneralTab from './general';
import NotificationsTab from './notifications';
import SettingsTab from './settings';
import { Container } from './style';
import AccountAndSecurity from './accountAndSecurity';
import Modal from 'components/Modal';
import { ModalContent } from 'components/Modal/style';
import { EmailModalForm, NameModalForm, PhoneModalForm, PasswordModalForm } from './ModalForms';
import { FieldValues } from 'react-hook-form';
import { Loading, Success } from 'components/ui/Animiations';

interface IProps {
  activeTab: string;
}

const ProfileContent: React.FC<IProps> = ({ activeTab }) => {
  const [isOpen, toggle] = useState('');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    firstName: 'Christian',
    lastName: 'da Silva',
    email: 'cs@rivevo.com',
    phone: {
      countryCode: '+45',
      number: '61409058',
    },
  });

  const onOpenModal = (inputFieldname: string) => {
    toggle(inputFieldname);
  };

  const onSave = (data: FieldValues) => {
    const userToBeSaved = { ...user, ...data };
    setLoading(true);
    toggle('Gemmer...');
    setTimeout(() => {
      toggle('Success!');
      setTimeout(() => {
        toggle('');
      }, 2000);
    }, 2000);
    setUser(userToBeSaved);
  };

  const renderModalContent = useCallback(() => {
    switch (isOpen) {
      case 'Navn':
        return <NameModalForm {...user} onCancel={() => toggle('')} onSave={onSave} />;
      case 'Email':
        return <EmailModalForm {...user} onCancel={() => toggle('')} onSave={onSave} />;
      case 'Telefonnummer':
        return <PhoneModalForm {...user} onCancel={() => toggle('')} onSave={onSave} />;
      case 'Skift Kodeord':
        return <PasswordModalForm {...user} onCancel={() => toggle('')} onSave={onSave} />;
      case 'Gemmer...':
        return <Loading />;
      case 'Success!':
        return <Success />;

      default:
        return <></>;
    }
  }, [isOpen]);

  const renderContent = () => {
    let componentToBeRendered;

    switch (activeTab) {
      case 'tab1':
        return <GeneralTab user={user} setUser={setUser} openModal={onOpenModal} />;

      case 'tab2':
        return <AccountAndSecurity user={user} setUser={setUser} openModal={onOpenModal} />;

      case 'tab3':
        return <BookingsTab />;

      case 'tab4':
        return <BillingTab />;

      case 'tab5':
        return <NotificationsTab />;

      case 'tab6':
        return <SettingsTab />;

      default:
        break;
    }

    return componentToBeRendered;
  };
  return (
    <>
      <Container>{renderContent()}</Container>;
      <Modal toggle={toggle} isOpen={isOpen !== ''} title={isOpen}>
        <ModalContent>{renderModalContent()}</ModalContent>
      </Modal>
    </>
  );
};

export default ProfileContent;
