import React from 'react';
import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter } from 'react-icons/io5';
import {
  FooterContainer,
  BottomSection,
  TopSection,
  SocialIcon,
  FooterColumn,
  ColumnTitle,
  ColumnLinks,
} from './style';

const footer = () => {
  return (
    <FooterContainer>
      <footer>
        <div className='footer'>
          <TopSection>
            <FooterColumn>
              <ColumnTitle>Rivevo</ColumnTitle>
              <ColumnLinks>
                <ul>
                  <li>
                    <a href='#'>
                      <span>Jobs</span>
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <span>Om os</span>
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <span>Blog</span>
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <span>Presse</span>
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <span>Kontakt</span>
                    </a>
                  </li>
                </ul>
              </ColumnLinks>
            </FooterColumn>
          </TopSection>
          <BottomSection>
            <div className='footer-links'>
              <span>© 2022 Rivevo ApS. Alle rettigheder forbeholdes.</span>
              <span className='dot'>·</span>
              <span>
                <a href='/about/privacy'>Persondatapolitik</a>
              </span>
              <span className='dot'>·</span>
              <span>
                <a href='/about/terms'>Vilkår og betingelser</a>
              </span>
            </div>
            <div className='social-links'>
              <SocialIcon>
                <IoLogoFacebook />
              </SocialIcon>
              <SocialIcon>
                <IoLogoLinkedin />
              </SocialIcon>
              <SocialIcon>
                <IoLogoInstagram />
              </SocialIcon>
              <SocialIcon>
                <IoLogoTwitter />
              </SocialIcon>
            </div>
          </BottomSection>
        </div>
      </footer>
    </FooterContainer>
  );
};

export default footer;
