import React from 'react';
import Landing from './sections/Landing';
import Mobile from './sections/Mobile';
import TopSpecialties from './sections/TopSpecialties';
import TopProviders from './sections/TopProviders';
import { Content } from './style';
import { useQuery } from '@apollo/client';
import { GET_SPECIALIZATIONS } from 'graphql/queries';
import { ISpecialization } from 'interfaces';

// const topProviderCategories = [{
//   profession:

// }]

const Home = () => {
  const { data, loading } = useQuery<{ specializations: ISpecialization[] }>(GET_SPECIALIZATIONS);
  return (
    <Content>
      <Landing specializations={data?.specializations} />
      <TopSpecialties specializations={data?.specializations} loading={loading} />
      <TopProviders />
      {/* <Mobile /> */}
    </Content>
  );
};

export default Home;
