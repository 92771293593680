import React, { ChangeEvent, useState } from 'react';
import { H2, H3, H4, SmallText } from 'components/Styled';
import { Block, Row, BlockTitle, Container, Column } from '../style';
import { ImageDrop, UploadProfileImage } from './style';
import { IoAttach, IoCheckmark, IoTrashOutline, IoSaveOutline } from 'react-icons/io5';
import defaultAvatar from 'assets/images/genderless-avatar.jpeg';
import { useMutation, useQuery } from '@apollo/client';
import { UPLOAD_FILE } from 'graphql/mutations';
import { GET_ME } from 'graphql/queries';
import { ClipLoader } from 'react-spinners';
import Button from 'components/ui/Button';
import { Input } from 'components/ui/Input';
import { IUser, IUserForm } from 'interfaces';
import { Controller, useForm } from 'react-hook-form';
import { Loading } from 'components/ui/Animiations';

// interface IUserInput {
//   profileImage: File | null;
// }

interface IUserData {
  me: IUser;
}

const UserProfile = (): JSX.Element => {
  const [updateProfile, { loading }] = useMutation(UPLOAD_FILE, {
    refetchQueries: [{ query: GET_ME }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setSelectedImage(null);
      setCompleted(true);
    },
  });
  const { data: { me } = {} } = useQuery<IUserData>(GET_ME);
  // const [profile, setProfile] = useState<IUser | null>(null);
  const [completed, setCompleted] = useState<boolean>(false);
  const [showRemoveImage, setShowRemoveImage] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<File | null | undefined>(undefined);
  const {
    // handleSubmit,
    formState: { errors },
    control,
  } = useForm<IUserForm>();

  const onFileChangeHandler = ({ target: { validity, files } }: ChangeEvent<HTMLInputElement>) => {
    if (!validity.valid) return;
    if (files === null) return;

    setSelectedImage(files[0]);
  };

  const onSave = () => {
    // const userInput: IUserInput = {
    //   profileImage: selectedImage,
    // };
    updateProfile({ variables: { file: selectedImage } });
  };

  return (
    <Container>
      <Row>
        <Column flex={2}>
          {/* <Block>
            <BlockTitle>
              <H2 noMargin>Generelt information</H2>
            </BlockTitle>
            {loading && <Loading />}
            {me && (
              <form>
                <Row>
                  <Controller
                    control={control}
                    name='name'
                    defaultValue={me?.name}
                    render={({ field: { onChange, value, name, ref } }) => (
                      // { invalid, isTouched, isDirty }
                      <Input
                        id={name}
                        label='Fornavn'
                        value={value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                        type='text'
                        inputRef={ref}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='lastName'
                    defaultValue={me?.lastName}
                    render={({ field: { onChange, value, name, ref } }) => (
                      // { invalid, isTouched, isDirty }
                      <Input
                        id={name}
                        label='Efternavn'
                        value={value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                        type='text'
                        inputRef={ref}
                      />
                    )}
                  />
                </Row>
                <Row>
                  <Controller
                    control={control}
                    name='phone'
                    defaultValue={me?.phone}
                    render={({ field: { onChange, value, name, ref } }) => (
                      // { invalid, isTouched, isDirty }
                      <Input
                        id={name}
                        label='Telefon'
                        value={value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                        type='phone'
                        inputRef={ref}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='email'
                    defaultValue={me?.email}
                    render={({ field: { onChange, value, name, ref } }) => (
                      // { invalid, isTouched, isDirty }
                      <Input
                        id={name}
                        label='Email'
                        value={value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                        type='email'
                        inputRef={ref}
                      />
                    )}
                  />
                </Row>
              </form>
            )}
          </Block> */}
          <Button
            icon={
              loading ? (
                <ClipLoader color={'white'} loading={loading} size={12} />
              ) : completed ? (
                <IoCheckmark />
              ) : (
                <IoSaveOutline />
              )
            }
            label={completed ? 'Profilen er gemt' : 'Gem profil'}
            onClick={onSave}
          />
        </Column>
        <Column flex={1}>
          <Block>
            <H3 marginBottom={24}>Profil Billede</H3>
            <UploadProfileImage>
              <div
                className='avatar'
                onMouseEnter={() => setShowRemoveImage(true)}
                onMouseLeave={() => setShowRemoveImage(false)}
              >
                <img
                  src={
                    (selectedImage && URL.createObjectURL(selectedImage)) ||
                    (selectedImage !== null && me?.profileImage) ||
                    defaultAvatar
                  }
                  alt='Avatar'
                />
                {showRemoveImage && selectedImage !== null && me?.profileImage && (
                  <div className='remove-image' onClick={() => setSelectedImage(null)}>
                    <IoTrashOutline />
                  </div>
                )}
              </div>
              <div className='file-data'>
                <div className='file-icon'>
                  <IoAttach style={{ transform: 'rotate(45deg)' }} />
                </div>
                <input type='file' accept='image/*' onChange={onFileChangeHandler} />
                <div className='file-text'>
                  <H4 marginBottom={6}>Select Image</H4>
                  <SmallText>JPG(JPEG), GIF,PNG</SmallText>
                  <SmallText>Max size of 800K</SmallText>
                </div>
              </div>
            </UploadProfileImage>
          </Block>
          <ImageDrop>
            <form className='dropzone'>
              <input></input>
              <H2 noMargin>Drop files here to upload</H2>
            </form>
          </ImageDrop>
        </Column>
      </Row>
    </Container>
  );
};

export default UserProfile;
