import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { ModalContent } from 'components/Modal/style';
import Modal from 'components/Modal';
import { IProvider, IProviderAvailability, IProviderTimeSLot, IUser } from 'interfaces';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import Button from 'components/ui/Button';
import {
  IoLockClosedOutline,
  IoCallOutline,
  IoCalendarOutline,
  IoCheckmarkSharp,
  IoCloseSharp,
  IoIdCardOutline,
} from 'react-icons/io5';
import {
  SecureBookingText,
  Icon,
  CheckBoxTermsOfUse,
  OptionalText,
  SelectedDate,
  Label,
  PatientInfo,
  BehandlerInfo,
  ProviderImage,
  ProviderData,
  ProviderName,
  ProviderAddress,
  PatientName,
  SelectedPUser,
  Patient,
  PatientImage,
  PatientPhone,
  PatientCPR,
  ErrorText,
  SelectedIssue,
} from './style';
import Checkbox from 'components/ui/Checkbox';
import Tooltip from 'components/ui/Tooltip';
import { Select } from '../style';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ME } from 'graphql/queries';
import { ADD_BOOKING } from 'graphql/mutations';
import { Loader } from 'app-style';
import { H2 } from 'components/Styled';
import { Loading, Success } from 'components/ui/Animiations';
import ProfileImage from 'components/ui/ProfileImage';

interface IProps {
  slot: IProviderTimeSLot;
  provider: IProvider;
  onCloseModal(): void;
}

interface ICreateBookingInput {
  providerId: string;
  puserId?: string;
  date: Date;
  // CPRNumber: string;
  phoneNumber: string;
  notesToProvider: string;
  productId: string | null;
  acceptedTerms: boolean;
  locationId: string;
  roomId: string;
}

const BookingContent: React.FC<IProps> = ({ onCloseModal, slot, provider }): JSX.Element => {
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [notesToProvider, setNotesToProvider] = useState<string>('');
  // const [selectedPUser, setSelectedPUser] = useState<IUser | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberEditMode, setPhoneNumberEditMode] = useState<boolean>(false);
  // const [CPRNumber, setCPRNumber] = useState<string>('');
  // const [CPRNumberEditMode, setCPRNumberEditMode] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { data: { me } = {} } = useQuery<{ me: IUser }>(GET_ME);
  const [addBooking, { data: bookingData, loading, error }] = useMutation(ADD_BOOKING);

  useEffect(() => {
    if (bookingData) setSuccess(true);
  }, [bookingData]);

  useEffect(() => {
    // reset on modal close;
    // if (!isOpen) {
    //   setSelectedPUser(null);
    //   setAcceptedTerms(false);
    //   setNotesToProvider('');
    //   setPhoneNumberEditMode(false);
    //   // setCPRNumber('');
    //   // setCPRNumberEditMode(false);
    // } else {
    //   if (me?.phone) setPhoneNumber(me.phone);
    //   if (slot.availablePUsers.length === 1) setSelectedPUser(slot.availablePUsers[0]);
    // }
    // console.log(slot);
  }, []);

  // const onChangeCPRHandler = (e: any) => {
  //   const val = e.target.value;

  //   if (val.length === 6) {
  //     if (e.nativeEvent.inputType === 'deleteContentBackward') {
  //       setCPRNumber(val.slice(0, -1));
  //     } else {
  //       setCPRNumber(val + '-');
  //     }
  //   } else {
  //     setCPRNumber(val);
  //   }
  // };

  const onAddBookingHandler = () => {
    const createBookingInput: ICreateBookingInput = {
      providerId: provider._id,
      puserId: slot.availablePUsers.length !== 0 ? slot.availablePUsers[0]!._id : undefined,
      date: slot.timeslot,
      // CPRNumber,
      phoneNumber,
      notesToProvider,
      productId: slot.product ? slot.product._id : null,
      acceptedTerms,
      locationId: slot.availableLocations.length > 1 ? '' : slot.availableLocations[0]._id,
      roomId: slot.availableLocations.length > 1 ? '' : slot.availableLocations[0].rooms[0]._id, // default to first room;
    };

    addBooking({ variables: { createBookingInput } });
  };

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    }

    if (error) return <p>error! :(</p>;

    if (success) {
      setTimeout(() => {
        setSuccess(false);
        onCloseModal();
      }, 2500);

      return <Success />;
    }

    return (
      <>
        <SelectedPUser>
          <Label>Valgte behandler</Label>
          {slot.availablePUsers.length === 1 ? (
            <Patient>
              <ProfileImage src={slot.availablePUsers[0].profileImage} alt={slot.availablePUsers[0].name} />
              <PatientName>{`${slot.availablePUsers[0].name} ${slot.availablePUsers[0].lastName}`}</PatientName>
            </Patient>
          ) : (
            // TODO create default user option
            <>
              <div>ingen valgt</div>
              <ErrorText margin='6px 0 0 0'>
                OBS! Hvis du ikke vælger en behandler, vil systemet selv tildele en
              </ErrorText>
            </>
          )}
        </SelectedPUser>
        <PatientInfo>
          <Label>
            Patient oplysninger
            <Tooltip alignment='top'>
              <div>
                <b>Telefon</b>
                <div>
                  Vi bruger udelukkende telefon nummeret til at holde dig opdateret omkring din booking, og så
                  behandleren kan komme i kontakt med dig.
                </div>
                <br />
                {/* <b>CPR-Nummer</b>
                <div>
                  For at sikre, at journaler bliver oprettet korrekt, er vi nød til at sikre os, at du er en rigtig
                  person, så derfor skal vi bruge dit CPR-Nummer. Vi gemmer ikke dit rigtige CPR-nummer, men en
                  krypteret udgave. <br />
                  <br />
                  Dette er normal procedure hos de fleste behandlere og er 100% sikkert!
                </div> */}
              </div>
            </Tooltip>
          </Label>
          <PatientPhone>
            {!phoneNumberEditMode ? (
              <div
                className='add-phone'
                onClick={() => {
                  // if (CPRNumberEditMode) setCPRNumberEditMode(false);
                  setPhoneNumberEditMode(true);
                }}
              >
                <Icon>
                  <IoCallOutline size={18} />
                </Icon>
                <span className='phone-number'>{phoneNumber ? `(+45) ${phoneNumber}` : 'Tilføj telefon nummer'}</span>
              </div>
            ) : (
              <div className='phone-input'>
                <Icon>
                  <IoCallOutline size={18} />
                  <span>(+45)</span>
                </Icon>
                <input
                  type='text'
                  id='phone'
                  name='phone'
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') setPhoneNumberEditMode(false);
                  }}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                  // pattern='[0-9]{6}-[0-9]{4}'
                />
                <div className='accept-input' onClick={() => setPhoneNumberEditMode(false)}>
                  <IoCheckmarkSharp />
                </div>
                <div
                  className='clear-input'
                  onClick={() => {
                    setPhoneNumber('');
                    setPhoneNumberEditMode(false);
                  }}
                >
                  <IoCloseSharp />
                </div>
              </div>
            )}
          </PatientPhone>
          {/* <PatientCPR>
            {!CPRNumberEditMode ? (
              <div
                className={`add-cpr${
                  CPRNumber.length !== 11 && CPRNumber.length !== 0 && !CPRNumberEditMode ? ' error' : ''
                }`}
                onClick={() => {
                  if (phoneNumberEditMode) setPhoneNumberEditMode(false);
                  setCPRNumberEditMode(true);
                }}
              >
                <div className='cpr-text'>
                  <Icon>
                    <IoIdCardOutline size={18} />
                  </Icon>
                  <span className={`cpr-number`}>{CPRNumber ? CPRNumber : 'Tilføj CPR-nummer'}</span>
                </div>
                {CPRNumber.length !== 11 && CPRNumber.length !== 0 && !CPRNumberEditMode && (
                  <ErrorText margin='6px 0 6px 0'>Et CPR-Nummer er på 10 cifre. F.eks. (xxxxxx-xxxx)</ErrorText>
                )}
              </div>
            ) : (
              <div className='cpr-input'>
                <Icon>
                  <IoIdCardOutline size={18} />
                </Icon>
                <input
                  type='text'
                  id='cpr'
                  name='cpr'
                  maxLength={11}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') setCPRNumberEditMode(false);
                  }}
                  onChange={onChangeCPRHandler}
                  value={CPRNumber}
                />
                <div className='accept-input' onClick={() => setCPRNumberEditMode(false)}>
                  <IoCheckmarkSharp />
                </div>
                <div
                  className='clear-input'
                  onClick={() => {
                    setCPRNumber('');
                    setCPRNumberEditMode(false);
                  }}
                >
                  <IoCloseSharp />
                </div>
              </div>
            )}
          </PatientCPR> */}
        </PatientInfo>
        <SelectedDate>
          <Label>
            <IoCalendarOutline />
            <span className='date'>Valgte dato</span>
          </Label>
          <div>{format(new Date(slot.timeslot), 'd. MMMM, HH:mm', { locale: da })}</div>
        </SelectedDate>
        <SelectedIssue>
          <Label>Valgte årsag</Label>
          <div>{slot.product ? slot.product.name : 'Ingen årsag valgt'}</div>
        </SelectedIssue>
        <OptionalText>
          <Label>
            <span>Ekstra information til behandler</span>
            <Tooltip alignment='top'>
              Tilføj ekstra information, du tænker ville være godt at vide for din behandler. Det kunne f.eks. være
              gamle skader eller specielle ønsker.
            </Tooltip>
          </Label>
          <textarea
            value={notesToProvider}
            className='extra-notes'
            rows={5}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNotesToProvider(e.target.value)}
          />
        </OptionalText>
        <CheckBoxTermsOfUse>
          <Checkbox value={acceptedTerms} onChange={() => setAcceptedTerms(!acceptedTerms)} />
          <span onClick={() => setAcceptedTerms(!acceptedTerms)}>
            Jeg har læst og accepterer Rivevo's{' '}
            <a href='/terms' target='_blank'>
              vilkår for brug.
            </a>
          </span>
        </CheckBoxTermsOfUse>
        <Button disabled={!acceptedTerms} fontSize={20} label='Book tid' onClick={onAddBookingHandler} />
        <SecureBookingText>
          <Icon>
            <IoLockClosedOutline />
          </Icon>
          Sikker booking
        </SecureBookingText>
      </>
    );
  };

  return renderContent();
};

export default BookingContent;
