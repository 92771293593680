import React from 'react';
import { Content, Title, Text, ErrorMessage } from './style';
import PageNotFoundLottie from 'assets/lotties/404page.json';
import { Player } from '@lottiefiles/react-lottie-player';
import Button from 'components/ui/Button';
import { useNavigate } from 'react-router';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <ErrorMessage>
        <Player autoplay loop src={PageNotFoundLottie} style={{ height: '100%', width: '100%' }}></Player>
        <Title>Åh nej! Siden kan desværre ikke findes</Title>
        <Text>Siden du leder efter, er muligvis fjernet, ændret eller midlertidigt nede.</Text>
        <Button gradient shadow label='Gå tilbage' onClick={() => navigate(-1)} />
      </ErrorMessage>
    </Content>
  );
};

export default PageNotFound;
