import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div<{ error?: boolean }>`
  display: flex;
  flex-flow: column;
  flex: 1;

  textarea {
    padding: 12px;
    border-radius: 6px;
    background-color: ${COLORS.Primary.blueTint};
    border: 1px solid ${COLORS.lightgrey};
    outline: none;
    resize: vertical;

    :focus-within {
      border-color: ${({ error }) => (error ? COLORS.Primary.red : COLORS.Primary.blue)};
    }
  }
`;

export const Icon = styled.div`
  margin-right: 6px;
  display: flex;
`;

export const Label = styled.label`
  color: #444;
  font-size: 14px;
  font-weight: 600;
`;
