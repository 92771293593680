import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const DropButton = styled.button<{
  focused: boolean;
}>`
  position: relative;
  display: inline-flex;
  background-color: ${({ focused }) => (focused ? COLORS.Primary.lightblue : COLORS.Primary.blueWhite)};
  border: none;
  border-radius: 30px;
  padding: 8px 16px;
  margin: 0 6px 0 0;
  outline: none;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.Primary.darkblue};
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    /* border-color: ${COLORS.Primary.blue};
      color: ${COLORS.Primary.blue}; */
    background: ${COLORS.Primary.lightblue};
  }
`;

export const DropdownContent = styled.div`
  position: absolute;
  /* padding: 12px; */
  background: #fff;
  border-radius: 6px;
  border: 1px solid ${COLORS.Primary.offwhite};
  z-index: 9999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  top: 48px;
  left: 0;
  right: auto;
  /* overflow: hidden; */
  box-sizing: border-box;

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-top: 1px solid #eee;
    /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); */

    button.clear-button {
      display: flex;
      background: none;
      border: none;
      border-radius: 6px;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      outline: none;
      padding: 10px 12px;
      margin: 0 -10px;
      color: ${COLORS.Primary.darkblue};

      label {
        cursor: pointer;
      }

      &:hover {
        background-color: ${COLORS.Primary.blueWhite};
      }
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
