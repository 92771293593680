import React, { ChangeEvent } from 'react';
import { Container } from './style';

import { FieldError, RefCallBack, RegisterOptions } from 'react-hook-form';
import { Label } from '../Input/style';

interface IProps {
  label: string;
  id: string;
  error?: FieldError | undefined;
  rules?: RegisterOptions;
  value: string | number | undefined;
  inputRef?: RefCallBack;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  placeholder?: string;
}

const TextArea: React.FC<IProps> = ({
  label,
  id,
  onChange,
  inputRef,
  value,
  error,
  rules,
  rows = 5,
  placeholder,
}): JSX.Element => {
  return (
    <Container>
      <Label htmlFor={id} className={error && 'error'}>
        {error?.message || label}
      </Label>
      <textarea
        id={id}
        rows={rows}
        ref={inputRef}
        value={value}
        placeholder={placeholder || ''}
        onChange={onChange}
        aria-invalid={error ? 'true' : 'false'}
        // onKeyPress={handleKeyPress}
        // onFocus={() => !locked && setInputActive(true)}
        // onBlur={() => !locked && setInputActive(false)}
      />
    </Container>
  );
};

export default TextArea;
