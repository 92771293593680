import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-flow: column;
  padding: 24px;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-flow: column;
  background: #fff;
  border-radius: 6px;
  padding: 24px;

  & > .headerInfo {
    display: flex;
    flex-flow: row;
  }
`;

export const ProviderImgWrapper = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-right: 24px;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.darkgrey};

  span.rating {
    display: flex;
    flex-flow: row;

    & > span:last-of-type {
      padding-left: 4px;
    }

    span.review-count {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  span.dot {
    margin: 0 4px;
  }
  span.profession {
    text-transform: capitalize;
  }

  span.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    color: ${COLORS.Primary.blue};
  }
`;

export const ProfileImg = styled.img`
  width: 100%;
  height: auto;
`;

export const ProviderNoticeMessage = styled.div`
  display: flex;
  position: relative;
  flex-flow: row;
  margin-top: 48px;
  border-radius: 6px;
  padding: 24px;
  overflow: hidden;
  background: ${COLORS.Primary.blueWhite};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;

export const Icon = styled.div`
  display: flex;
  position: absolute;
  top: -15px;
  left: -30px;
  justify-content: center;
  align-items: center;
  font-size: 150px;
  transform: rotate(-25deg);
  color: #e1ebff;
`;

export const NoticeMessageContent = styled.div`
  display: flex;
  flex-flow: column;
  overflow: hidden;
  margin-left: 98px;
`;

export const NoticeTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${COLORS.Primary.darkblue};
  margin-bottom: 3px;
`;

export const NoticeSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${COLORS.Primary.blue};
  margin-bottom: 6px;
`;

export const ProviderInfo = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

export const RatingContainer = styled.div`
  display: flex;
  padding: 12px 0;
`;

export const Rating = styled.div``;
