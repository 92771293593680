import { gql } from '@apollo/client';
import { CORE_USER_FIELDS } from '../fragments';

export const GET_ME = gql`
  ${CORE_USER_FIELDS}
  query GetMe {
    me {
      ...CoreUserFields
      languages
      email
      phone
      gender
      roles
    }
  }
`;

export const REFRESH = gql`
  ${CORE_USER_FIELDS}
  query Refresh {
    refresh {
      ...CoreUserFields
      languages
      email
      phone
      gender
    }
  }
`;

export const FACEBOOK_LOGIN = gql`
  query FacebookLogin {
    facebookLogin
  }
`;
