import { COLORS } from 'style';
import styled from 'styled-components';

export const ProductName = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 12px;
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.Primary.blue};
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 25px;
  padding: 2px 8px;
`;
export const Buttons = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 12px;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 28px;
  cursor: pointer;
`;
