import React, { ChangeEvent, useState } from 'react';
import { DayTypes, translateDay } from 'helpers/utils';
import {
  IOpeningHour,
  IProduct,
  IProvider,
  IUser,
  IProviderLocation,
  IProviderAvailability,
  IProviderTimeSLot,
} from 'interfaces';
import ProviderCalendar from 'containers/Search/ProviderCalendar';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { H2 } from 'components/Styled';
import { BookingSlots, Container, OpeningHours, Select } from './style';
import { format, isBefore, isToday, subDays } from 'date-fns';
import { da } from 'date-fns/locale';
import { addDays } from 'date-fns/esm';
import BookingModal from './BookingModal';
import { useQuery } from '@apollo/client';
import { GET_ME } from 'graphql/queries';
import LoginModal from 'components/Modal/Login';
import { Loading } from 'components/ui/Animiations';

interface IProps {
  provider: IProvider;
  setSelectedProduct(providerProductId: string): void;
  selectedProduct: string;
  setSelectedLocation(providerLocationId: string): void;
  selectedLocation: string;
  setSelectedUser(userId: string): void;
  selectedUser: string;
  setSelectedDate(date: Date): void;
  selectedDate: Date;
  loadingAvailability: boolean;
}

const ProviderBooking: React.FC<IProps> = ({
  provider,
  setSelectedProduct,
  selectedProduct,
  setSelectedLocation,
  selectedLocation,
  setSelectedUser,
  selectedUser,
  setSelectedDate,
  selectedDate,
  loadingAvailability,
}): JSX.Element => {
  const [showBookingModal, toggleBooking] = useState<string>('');
  const [showLoginModal, toggleLogin] = useState<string>('');
  const [selectedSlot, setSelectedSlot] = useState<IProviderTimeSLot | null>(null);
  const { data } = useQuery<{ me: IUser }>(GET_ME);

  const onBookingSelect = (slot: IProviderTimeSLot) => {
    if (!data?.me) {
      toggleLogin('login');
    } else {
      setSelectedSlot(slot);
      toggleBooking('booking');
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;

    if (name === 'product') setSelectedProduct(value);
    if (name === 'location') setSelectedLocation(value);
    if (name === 'user') setSelectedUser(value);
  };

  return (
    <>
      <Container>
        <div>
          <div className='innerContainer'>
            <div className='content'>
              {provider.availability.length > 0 ? (
                <>
                  <H2 noMargin>Book en gratis aftale</H2>
                  {provider.products.length > 0 && (
                    <Select>
                      <label>Adresse</label>
                      <select name='location' defaultValue={'default'} onChange={onChangeHandler}>
                        <option key='default' value='default' hidden>
                          Vælg adresse...
                        </option>
                        {provider.locations.map((location: IProviderLocation) => (
                          <option key={location._id} value={location._id}>
                            {location.street}
                          </option>
                        ))}
                      </select>
                    </Select>
                  )}
                  <Select>
                    <label>Årsag</label>
                    <select name='product' onChange={onChangeHandler} defaultValue={'default'}>
                      <option key='default' value='default' hidden>
                        Vælg årsag...
                      </option>
                      {provider.products.map((product: IProduct, i) => (
                        <option key={i} value={product._id}>
                          {`${product.name}`}
                        </option>
                      ))}
                    </select>
                  </Select>
                  <Select>
                    <label>Behandler</label>
                    <select name='user' onChange={onChangeHandler} defaultValue={'default'}>
                      <option key='default' value='default' hidden>
                        Vælg behandler...
                      </option>
                      {provider.users.map((user: IUser) => (
                        <option key={user._id} value={user._id}>
                          {`${user.name} ${user.lastName}`}
                        </option>
                      ))}
                    </select>
                  </Select>
                  <div className='timeslots'>
                    {loadingAvailability ? (
                      <Loading />
                    ) : (
                      <BookingSlots>
                        <div className='date-control'>
                          <div
                            className={`date-controller back ${isToday(selectedDate) ? 'disabled' : ''}`}
                            onClick={() =>
                              setSelectedDate(
                                isBefore(subDays(selectedDate, 5), new Date()) ? new Date() : subDays(selectedDate, 5)
                              )
                            }
                          >
                            <IoChevronBack />
                          </div>
                          <div className='current-week'>
                            {Array.from(Array(5).keys()).map((day: number, idx: number) => {
                              return (
                                <div className='date' key={idx}>
                                  <div className='dayname'>
                                    {format(addDays(selectedDate, idx), 'eee', { locale: da }).replace('.', '')}
                                  </div>
                                  <div className='date'>
                                    {format(addDays(selectedDate, idx), 'MMM d', { locale: da }).replace('.', '')}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div
                            className='date-controller forward'
                            onClick={() => setSelectedDate(addDays(selectedDate, 5))}
                          >
                            <IoChevronForward />
                          </div>
                        </div>
                        {/* <ProviderCalendar availableTimeSlots={provider.availability} /> */}
                      </BookingSlots>
                    )}
                  </div>
                </>
              ) : (
                <div>not claimed</div>
              )}
            </div>
          </div>
          {provider?.openingHours.length > 0 && (
            <OpeningHours>
              {/* //   <Title>
          //     <Icon marginRight={6} size={22}>
          //       <IoTimeOutline />
          //     </Icon>
          //     <H2 noMargin>Åbningstider</H2>
          //   </Title> */}
              {provider.openingHours.map((day: IOpeningHour, dayIdx: number) => {
                return (
                  <div className='day' key={dayIdx}>
                    <div className='day-header'>
                      {translateDay(day.day as DayTypes, 3)}
                      <div className='arrow-up' />
                    </div>
                    <div className='day-hours'>
                      <div>{day.isOpen ? `${day.from} ${day.to}` : 'LUKKET'}</div>
                    </div>
                  </div>
                );
              })}
            </OpeningHours>
          )}
        </div>
      </Container>
      {selectedSlot && (
        <BookingModal
          slot={selectedSlot}
          provider={provider}
          isOpen={showBookingModal === 'booking'}
          toggle={toggleBooking}
        />
      )}
      <LoginModal isOpen={showLoginModal === 'login'} toggle={toggleLogin} isProvider={false} />
    </>
  );
};

export default ProviderBooking;
