import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-flow: column;
  padding: 24px;
`;

export const FAQList = styled.div`
  display: flex;
  flex-flow: column;
  padding: 12px;
  margin-top: 12px;
  background: ${COLORS.white};
  border-radius: 6px;
`;
