import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloProvider, InMemoryCache, NormalizedCacheObject, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import CookieConsent from 'react-cookie-consent';
import cookies from 'assets/images/cookies.png';
import { COLORS } from 'style';

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : process.env.REACT_APP_ENV_API_KEY;

const uploadLink = createUploadLink({
  uri: `${baseUrl}/graphql`,
  credentials: 'include',
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient<NormalizedCacheObject>({
  link: from([errorLink, uploadLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getMe(_, { args, toReference }) {
            return toReference({
              __typename: 'User',
              id: args?._id,
            });
          },
        },
      },
    },
  }),
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
      <CookieConsent
        debug={false}
        buttonText='Jeg accepterer'
        buttonStyle={{
          color: '#fff',
          fontWeight: 600,
          fontSize: '13px',
          background: 'linear-gradient(350deg,rgba(22,99,253,1) 20%,rgba(104,225,254,1) 80%)',
          borderRadius: '6px',
        }}
        style={{ background: COLORS.Primary.darkblue }}
        customContainerAttributes={{}}
        customContentAttributes={{}}
      >
        <div className='cookie-container'>
          <img src={cookies} alt='cookies' />
          Websitet anvender "cookies", der er en tekstfil, som gemmes på din computer, mobil el. tilsvarende med det
          formål at genkende den, huske indstillinger, udføre statistik og målrette annoncer. Cookies kan ikke indeholde
          skadelig kode som f.eks. virus. Det er muligt at slette eller blokere for cookies. Se vejledning:
          http://minecookies.org/cookiehandtering Hvis du sletter eller blokerer cookies vil annoncer kunne blive mindre
          relevante for dig og optræde hyppigere. Du kan desuden risikere at websitet ikke fungerer optimalt samt at der
          er indhold, du ikke kan få adgang til.
        </div>
      </CookieConsent>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
