import { Link } from 'react-router-dom';
import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  padding-top: 48px;
`;

export const Card = styled.div`
  position: static;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 24px;
  margin: 12px;
  background: ${COLORS.Primary.blueWhite};
  border-radius: 6px;
  height: 200px;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
  transition: all 0.3s;
  cursor: pointer;
  z-index: 5;

  &:hover {
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const CardTitle = styled.div`
  margin: 48px 0 0 0;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: ${COLORS.Primary.darkblue};
`;

export const ProviderSpecialization = styled.div`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.0625em;
  margin-top: 3px;

  color: ${COLORS.Primary.blue};
`;

export const TopReview = styled.div`
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: italic;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.Primary.darkblue};
`;

export const ProviderImage = styled.picture`
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
  height: 98px;
  width: 98px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  /* filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2)); */

  img {
    height: auto;
    width: 100%;
  }
`;
