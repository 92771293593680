import styled from 'styled-components';

export const Nav = styled.nav`
  position: fixed;
  flex-flow: column;
  background-color: #262b40;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 260px;
  max-height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow-y: auto;

  .inner {
    padding: 18px 24px;
  }
`;
