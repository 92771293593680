import React, { ChangeEvent, RefObject } from 'react';
import { Container, Input } from './style';
import { IoSearchOutline } from 'react-icons/io5';

interface IProps {
  value: string;
  onChange(value: ChangeEvent<HTMLInputElement>): void;
  inputRef?: RefObject<HTMLInputElement>;
  name?: string;
  key?: string;
  placeholder?: string;
}

const SearchField: React.FC<IProps> = ({ value = '', onChange, inputRef, name, placeholder, key }): JSX.Element => {
  return (
    <Container>
      <IoSearchOutline size={20} />
      <Input
        id={name}
        name={name}
        key={key}
        value={value}
        ref={inputRef}
        placeholder={placeholder || ''}
        onChange={onChange}
      />
    </Container>
  );
};

export default SearchField;
