import React, { ChangeEvent, useState } from 'react';
import { Container } from './style';
import DropDownButton from 'components/ui/DropdownButton';
import RangeSlider from 'components/ui/RangeSlider';

interface IProps {
  distance: number; // distance in km
  onSaveDistance(selectedDistance: number): void;
}

const DistanceDropDown: React.FC<IProps> = ({ distance, onSaveDistance }): JSX.Element => {
  const [selectedDistance, setSelectedDistance] = useState<number>(distance || 1);

  const onClear = () => {
    setSelectedDistance(0);
  };

  const onSave = () => {
    onSaveDistance(selectedDistance);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedDistance(+e.target.value);
  };

  return (
    <DropDownButton
      onClear={onClear}
      onSave={onSave}
      label={distance > 1 ? `${distance} km` : 'Afstand'}
      isSet={distance > 1}
    >
      <Container>
        <RangeSlider value={selectedDistance} onChange={onChange} />
      </Container>
    </DropDownButton>
  );
};

export default DistanceDropDown;
