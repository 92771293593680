import { gql } from '@apollo/client';

export const GET_PROVIDER_REVIEWS = gql`
  query GetProviderReviews($providerId: String!) {
    reviews(providerId: $providerId) {
      _id
      providerId
      author {
        _id
        name
        lastName
        profileImage
      }
      body
      verifiedUser
      createdAt
      updatedAt
      upvotes {
        _id
      }
      rating {
        avgRating
        price
        service
        time
      }
      providerResponse {
        response
        createdAt
        updatedAt
      }
    }
  }
`;
