import React, { useState } from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { ToolTipContainer, Icon } from './style';

interface IProps {
  alignment: 'top' | 'bottom' | 'left' | 'right';
  margin?: number;
  children: React.ReactNode;
}

const Tooltip: React.FC<IProps> = ({ children, alignment, margin = 6 }): JSX.Element => {
  const [onHover, setOnHover] = useState<boolean>(false);

  return (
    <>
      <Icon margin={margin} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
        <IoInformationCircleOutline />
        {onHover && <ToolTipContainer alignment={alignment}>{children}</ToolTipContainer>}
      </Icon>
    </>
  );
};

export default Tooltip;
