import React from 'react';
import { Content } from './style';

const BillingTab: React.FC = () => {
  return (
    <Content>
      <div>this is the Billing tab</div>
    </Content>
  );
};

export default BillingTab;
