import { IPatient } from 'interfaces';
import React, { useCallback, useMemo, useState } from 'react';
import { IoCallOutline, IoMailOutline, IoSearchOutline } from 'react-icons/io5';
import { debounce } from 'lodash';
import { Container, Input, InputResults, Icon } from './style';
import { useLazyQuery } from '@apollo/client';
import { GET_PATIENTS } from 'graphql/queries';
import searchError from 'assets/images/no-results-2.jpeg';
import { ClipLoader } from 'react-spinners';
import { COLORS } from 'style';

interface IProps {
  //   value: string | Date;
  onSelectPatient(patient: IPatient): void;
}

interface IPatientsData {
  patients: IPatient[];
}

const PatientSearch: React.FC<IProps> = ({ onSelectPatient }) => {
  const [cursor, setCursor] = useState<number>(0);
  const [input, setInput] = useState<string>('');
  const [searchString, setSearchString] = useState<string>('');

  const [getPatients, { data, loading }] = useLazyQuery(GET_PATIENTS, {
    variables: { searchString },
  });
  const { patients = [] }: IPatientsData = data || {};

  const handleSelectPatient = (index: number) => {
    onSelectPatient(patients[index]);
    setSearchString('');
    setInput('');
  };

  const debouncedSearchString = useMemo(
    () =>
      debounce(
        (textInput) => {
          setSearchString(textInput);
          getPatients();
        },
        500,
        {
          trailing: true,
        }
      ),
    [getPatients]
  );

  const handleInput = useCallback(
    (textInput: string) => {
      setInput(textInput);
      debouncedSearchString(textInput);
    },
    [debouncedSearchString]
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // arrow up/down button should select next/previous list element
    if (e.key === 'ArrowUp' && cursor > 0) {
      setCursor(cursor - 1);
      e.preventDefault();
    } else if (e.key === 'ArrowDown' && cursor < patients.length - 1) {
      setCursor(cursor + 1);
      e.preventDefault();
    } else if (e.key === 'Enter') {
      handleSelectPatient(cursor);
    }
  };

  return (
    <Container>
      <Icon>
        <IoSearchOutline />
      </Icon>
      <Input
        id='patient-search'
        name='patient-search'
        value={input}
        placeholder='Søg på tlf, email, navn eller CPR'
        onChange={(e) => handleInput(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {patients.length === 0 && searchString && (
        <InputResults noResults={true}>
          {loading ? (
            <ClipLoader color={COLORS.Primary.darkblue} loading={loading} size={12} />
          ) : (
            <div className='no-results'>
              <img src={searchError} alt='no results found' />
              Kunne ikke finde patient
            </div>
          )}
        </InputResults>
      )}
      {patients.length > 0 && (
        <InputResults>
          {patients.map((patient: IPatient, i: number) => (
            <div
              key={i}
              onClick={() => handleSelectPatient(i)}
              onMouseEnter={() => setCursor(i)}
              className={`patient${cursor === i ? ' active' : ''}`}
            >
              <span>{`${patient.name} ${patient.lastName}`}</span>
              <span className='sub-text'>
                {patient.email && (
                  <>
                    <div className='icon'>
                      <IoMailOutline />
                    </div>
                    {`${patient.email}`}
                  </>
                )}
                {patient.email && patient.phone && <span>•</span>}
                {patient.phone && (
                  <>
                    <div className='icon'>
                      <IoCallOutline />
                    </div>
                    {`${patient.phone}`}
                  </>
                )}
              </span>
            </div>
          ))}
        </InputResults>
      )}
    </Container>
  );
};

export default PatientSearch;
