import { gql } from '@apollo/client';

export const UPDATE_PROVIDER_JOURNAL_TEMPLATE = gql`
  mutation AddProviderJournalTemplate($journalTemplate: [JournalTemplateInput!]!) {
    providerJournalTemplate(journalTemplate: $journalTemplate)
  }
`;

export const ADD_PRODUCT = gql`
  mutation AddProduct($productInput: CreateProductInput!) {
    addProduct(productInput: $productInput)
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($productInput: UpdateProductInput!) {
    updateProduct(productInput: $productInput)
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($productId: String!) {
    deleteProduct(productId: $productId)
  }
`;

export const UPDATE_PROVIDER_SETTINGS = gql`
  mutation UpdateProviderSettings($providerSettings: ProviderSettingsInput!) {
    providerSettings(providerSettings: $providerSettings)
  }
`;

export const ADD_PROVIDER_OPENINGHOURS = gql`
  mutation AddProviderOpeningHours($openingHours: [ProviderHoursInput!]!) {
    providerOpeningHours(providerOpeningHours: $openingHours)
  }
`;
