import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Item = styled.div`
  border: 1px solid transparent;
  margin-bottom: 0.2rem;
  .navitem-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const NavigationLink = styled(NavLink)`
  display: flex;
  color: #eaedf2;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px 10px 45px;
  border-radius: 6px;
  text-decoration: none;

  &.active {
    border-color: #4c5680;
  }

  &.active,
  &:hover {
    background-color: #2e3650;
  }

  .navitem-icon {
    margin-right: 12px;
    margin-left: -33px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navitem-text {
  }
`;
