import { gql } from '@apollo/client';

export const GET_INVITATIONS = gql`
  query GetInvitations {
    invitations {
      _id
      invitee {
        name
        lastName
      }
      email
      createdAt
      expiration
    }
  }
`;
