import Icon from 'components/ui/Icon';
import React from 'react';
import {
  IoPersonCircleOutline,
  IoOptionsOutline,
  IoCardOutline,
  IoCalendarOutline,
  IoNotificationsOutline,
  IoLockClosedOutline,
} from 'react-icons/io5';
import { Container } from './style';

interface IProps {
  activeTab: string;
  setActiveTab(tab: string): void;
}

const ProfileNavigation: React.FC<IProps> = ({ activeTab, setActiveTab }) => {
  return (
    <Container>
      <nav>
        <ul>
          <li>
            <a className={activeTab === 'tab1' ? 'active' : ''} onClick={() => setActiveTab('tab1')}>
              <Icon icon={<IoPersonCircleOutline size={22} />} />
              <span>Generelt</span>
            </a>
          </li>
          <li>
            <a className={activeTab === 'tab2' ? 'active' : ''} onClick={() => setActiveTab('tab2')}>
              <Icon icon={<IoLockClosedOutline size={22} />} />
              <span>Konto & Sikkerhed</span>
            </a>
          </li>
          <li>
            <a className={activeTab === 'tab3' ? 'active' : ''} onClick={() => setActiveTab('tab3')}>
              <Icon icon={<IoCalendarOutline size={22} />} />
              <span>Aftaler</span>
            </a>
          </li>
          <li>
            <a className={activeTab === 'tab4' ? 'active' : ''} onClick={() => setActiveTab('tab4')}>
              <Icon icon={<IoCardOutline size={22} />} />
              <span>Betalingskort</span>
            </a>
          </li>
          <li>
            <a className={activeTab === 'tab5' ? 'active' : ''} onClick={() => setActiveTab('tab5')}>
              <Icon icon={<IoNotificationsOutline size={22} />} />
              <span>Notifikationer</span>
            </a>
          </li>
          <li>
            <a className={activeTab === 'tab6' ? 'active' : ''} onClick={() => setActiveTab('tab6')}>
              <Icon icon={<IoOptionsOutline size={22} />} />
              <span>Indstillinger</span>
            </a>
          </li>
        </ul>
      </nav>
    </Container>
  );
};

export default ProfileNavigation;
