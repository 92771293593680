import { IFilter } from 'interfaces';
import react, { createContext, useReducer } from 'react';
import { Actions, ActionType } from './store.actions';

interface IStoreState {
  filter: IFilter;
}

interface IAppContext {
  state: IStoreState;
  dispatch: React.Dispatch<Actions>;
}

interface IProps {
  children: React.ReactNode;
}

const initialState: IStoreState = {
  filter: {
    date: new Date(),
    userLocation: {
      name: '',
      coordinates: [0, 0],
    },
    maxDistance: 10,
    specialty: {
      _id: '',
      name: '',
    },
    videoBooking: false,
    genders: [],
    languages: [],
    searchString: '',
  },
};

const initializeState = () => {
  const sessionFilter = sessionStorage.getItem('filter');

  if (sessionFilter) {
    const filter: IFilter = JSON.parse(sessionFilter);
    if (filter.date) filter.date = new Date(filter.date);
    return { ...initialState, filter };
  }
  return initialState;
};

const store = createContext<IAppContext>({
  state: initializeState(),
  dispatch: () => null,
});

const { Provider } = store;

const reducer = (state: IStoreState, action: Actions) => {
  switch (action.type) {
    case ActionType.SetFilter:
      // save updated filter in local storage
      sessionStorage.setItem('filter', JSON.stringify(action.payload));

      return {
        ...state,
        filter: action.payload,
      };
  }
};

const AppProvider: React.FC<IProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initializeState());

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, AppProvider };
