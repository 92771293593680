import React from 'react';
import { TableContainer } from 'components/Styled';
import { IUser } from 'interfaces';
import { Column, useTable } from 'react-table';
import { useEffect } from 'react';
import { useState } from 'react';
import defaultAvatar from 'assets/images/genderless-avatar.jpeg';
import { Email, ProfileImage, RoleBadge, UserDetails, UserInfo, UserName } from './style';
import { formatDistance, subDays } from 'date-fns';
import { da } from 'date-fns/locale';
import { TextColumn } from '../style';

interface IProps {
  users: IUser[];
  onClick: (user: IUser) => void;
}

enum ERoleName {
  'USER' = 'Bruger',
  'ADMIN' = 'Admin',
  'ARCHITECT' = 'Arkitekt',
}

const UserTable: React.FC<IProps> = ({ users, onClick }): JSX.Element => {
  const [data, setData] = useState<IUser[]>(users);
  //   const data = React.useMemo(() => journals, []);

  useEffect(() => {
    setData(users);
  }, [users]);

  const columns = React.useMemo<Column<IUser>[]>(
    () => [
      {
        Header: 'Navn',
        accessor: (user) => (
          <UserDetails>
            <ProfileImage>
              <img src={user.profileImage || defaultAvatar} alt='user avatar' />
            </ProfileImage>
            <UserInfo>
              <UserName>
                {`${user.name} ${user.lastName}`}
                <span>{user.roles.includes('ARCHITECT') && ' (ejer)'}</span>
              </UserName>
              <Email>{user.email}</Email>
            </UserInfo>
          </UserDetails>
        ),
      },
      {
        Header: 'Rolle',
        accessor: (user) => (
          <RoleBadge>{ERoleName[user.roles[user.roles.length - 1] as keyof typeof ERoleName]}</RoleBadge> // translate role to danish
        ),
      },
      {
        Header: 'Sidst aktiv',
        accessor: () => (
          <TextColumn>{formatDistance(subDays(new Date(), 3), new Date(), { addSuffix: true, locale: da })}</TextColumn>
        ),
      },
      // {
      //   Header: 'Action',
      //   accessor: () => (
      //     <MoreButton onClick={() => {}}>
      //       <IoEllipsisHorizontalOutline />
      //     </MoreButton>
      //   ),
      // },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <TableContainer hoverRows>
      <table {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()} style={{}}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()}>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()} onClick={() => onClick(users[row.index])}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </TableContainer>
  );
};

export default UserTable;
