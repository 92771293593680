import { COLORS } from 'style';
import styled from 'styled-components';

export const Section = styled.section`
  padding: 12px 0;
  border-bottom: 1px solid ${COLORS.Primary.blueWhite};

  &:first-of-type {
    padding-top: 0;
  }
`;

export const FilterTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.Primary.darkblue};
`;

export const FilterContent = styled.div`
  display: flex;
  flex-flow: wrap;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 50%;
  padding: 0 0 12px 0;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 24px;
`;
