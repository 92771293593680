import React, { ChangeEvent } from 'react';
// import { IoCheckmarkSharp } from 'react-icons/io5';
import { Container, SliderInput } from './style';

interface IProps {
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  value: number;
}

const Checkbox: React.FC<IProps> = ({ onChange, value }): JSX.Element => {
  return (
    <Container>
      <SliderInput>
        <input type='range' onChange={onChange} min={1} max={100} value={value} />
        <div>{`${value} km`}</div>
      </SliderInput>
    </Container>
  );
};

export default Checkbox;
