import { COLORS } from 'style';
import styled from 'styled-components';

export const PatientInfo = styled.div`
  margin: 12px 0;
`;

export const Patient = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

export const PatientImage = styled.picture`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const PatientName = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${COLORS.Primary.darkblue};
`;

export const PatientPhone = styled.div`
  max-height: 40.94px;
  .add-phone {
    display: inline-flex;
    padding: 12px 0;
    /* flex-flow: row; */
    /* align-items: center; */
    cursor: pointer;

    span.phone-number {
      color: ${COLORS.Primary.darkblue};
      /* font-weight: 500; */
    }
    &:hover {
      div,
      span {
        color: ${COLORS.Primary.blue};
      }
    }
  }

  .phone-input {
    display: flex;
    flex-flow: row;
    padding: 10px;
    border: 1px solid ${COLORS.lightgrey};
    border-radius: 6px;
    background: ${COLORS.Primary.blueWhite};

    input {
      border: none;
      width: 100%;
      background: transparent;
      outline: none;
      font-size: 14px;
    }

    .accept-input,
    .clear-input {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      cursor: pointer;
      margin-left: 6px;
    }

    .accept-input:hover {
      color: ${COLORS.Primary.blue};
    }
    .clear-input:hover {
      color: ${COLORS.Primary.red};
    }
  }
`;

export const PatientCPR = styled.div`
  max-height: 40.94px;

  .add-cpr {
    display: inline-flex;
    flex-flow: column;
    padding: 12px 0;
    /* flex-flow: row; */
    /* align-items: center; */

    .cpr-text {
      display: flex;
      flex-flow: row;
      cursor: pointer;

      &:hover {
        div,
        span {
          color: ${COLORS.Primary.blue};
        }
      }
    }

    span.cpr-number {
      color: ${COLORS.Primary.darkblue};
      /* font-weight: 500; */
    }

    &.error {
      color: ${COLORS.Primary.red};
      span {
        color: ${COLORS.Primary.red};
      }
    }
  }

  .cpr-input {
    display: flex;
    flex-flow: row;
    padding: 10px;
    border: 1px solid ${COLORS.lightgrey};
    border-radius: 6px;
    background: ${COLORS.Primary.blueWhite};

    input {
      border: none;
      width: 100%;
      background: transparent;
      outline: none;
      font-size: 14px;
    }

    .accept-input,
    .clear-input {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      cursor: pointer;
      margin-left: 6px;
    }

    .accept-input:hover {
      color: ${COLORS.Primary.blue};
    }
    .clear-input:hover {
      color: ${COLORS.Primary.red};
    }
  }
`;

export const BehandlerInfo = styled.div`
  margin: 12px 0;

  .provider-info {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-top: 12px;
  }
`;

export const ProviderImage = styled.picture`
  display: block;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 24px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const ProviderData = styled.div``;

export const ProviderName = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const ProviderAddress = styled.div`
  font-size: 14px;
  color: ${COLORS.darkgrey};
`;

export const SelectedPUser = styled.div`
  display: flex;
  flex-flow: column;
  margin: 12px 0;
`;

export const SelectedDate = styled.div`
  margin: 12px 0;

  div {
    font-size: 18px;
    font-weight: 600;
    color: ${COLORS.Primary.blue};
    margin-bottom: 12px;
  }
`;

export const SelectedIssue = styled.div`
  margin: 12px 0;

  div {
    font-size: 18px;
    font-weight: 600;
    color: ${COLORS.Primary.blue};
    margin-bottom: 12px;
  }
`;

export const OptionalText = styled.div`
  display: flex;
  flex-flow: column;

  textarea {
    display: block;
    padding: 12px;
    margin-bottom: 12px;
    /* line-height: 22px; */
    border: 1px solid ${COLORS.lightgrey};
    background: ${COLORS.Primary.blueWhite};
    border-radius: 6px;
    resize: none;
    outline: 0px;
  }
`;

export const CheckBoxTermsOfUse = styled.div`
  display: flex;
  padding: 24px 0;

  span {
    cursor: pointer;

    a {
      color: ${COLORS.Primary.blue};
      font-weight: 500;
      &:hover {
        color: ${COLORS.Primary.lightblue};
      }
    }
  }
`;

export const SecureBookingText = styled.div`
  display: flex;
  flex-flow: row;
  font-size: 14px;
  color: ${COLORS.grey};
  margin-top: 6px;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;

  span {
    font-size: 14px;
    margin-left: 6px;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;

  span.date {
    margin-left: 6px;
  }
`;

export const ErrorText = styled.span<{ margin?: string }>`
  color: ${COLORS.Primary.red};
  font-size: 12px;
  margin: ${({ margin }) => (margin ? margin : '0')};
`;
