import styled from 'styled-components';
import { Marker } from 'react-map-gl';
import { H2 } from 'components/Styled';
import { COLORS } from 'style';

export const ProviderMarker = styled(Marker)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 30px;
  width: 30px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
`;

export const ProviderPin = styled.div<{ isHovered?: boolean }>`
  border: none;
  display: flex;
  flex: 1;
  cursor: pointer;
  padding: 6px;
  outline: none;
  border-radius: 25px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  background: ${({ isHovered }) => (isHovered ? COLORS.Primary.lightblue : COLORS.Primary.darkblue)};
  color: ${({ isHovered }) => (isHovered ? COLORS.white : COLORS.white)};
  transition: transform 0.2s ease-in;
  z-index: ${({ isHovered }) => (isHovered ? 9999 : 1)};

  &:hover {
    transition: transform 0.2s ease-in;
    background-color: ${COLORS.Primary.lightblue};
    transform: scale(1.2);
    z-index: 30;
    color: ${COLORS.white};
  }
`;

export const PopupContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #fff;
  border-radius: 6px;

  img {
    height: 72px;
    width: 72px;
    border-radius: 50px;
  }
`;

export const PopupContent = styled.div`
  margin: 24px 0;
`;

export const Title = styled(H2)`
  font-size: 16px;
`;
