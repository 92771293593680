import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useOutsideClick } from 'helpers/hooks';
import { IoClose, IoLocationOutline, IoSearch } from 'react-icons/io5';
import { useNavigate } from 'react-router';
import {
  AutocompleteElement,
  ClearInput,
  Container,
  DateContainer,
  Icon,
  InputButton,
  InputContainer,
  LocationInput,
  SearchIcon,
  SpecialtyContainer,
  VerticalLine,
} from './style';
import { useEffect } from 'react';
import DateDropDown from './datedropdown';
import { format, isBefore, isToday } from 'date-fns';
import { da } from 'date-fns/locale';
import { useQuery } from '@apollo/client';
import { GET_SPECIALIZATIONS } from 'graphql/queries';
import { ISpecialization } from 'interfaces';
import { store } from 'store/store';
import { SetFilter } from 'store/store.actions';
import _ from 'lodash';
import { isPastDay } from 'helpers/utils';

const Search = () => {
  const navigate = useNavigate();
  const {
    state: { filter },
    dispatch,
  } = useContext(store);

  // Refs
  const specialtyInputRef = useRef<HTMLInputElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);
  const locationInputRef = useRef<HTMLInputElement>(null);

  // API
  const { data, loading } = useQuery<{ specializations: ISpecialization[] }>(GET_SPECIALIZATIONS);
  const { specializations = [] } = data || {};

  // dropdown active states
  const [isActiveLocation, setIsActiveLocation] = useOutsideClick(locationInputRef, false);
  const [isActiveDate, setIsActiveDate] = useOutsideClick(dateInputRef, false);
  const [isActiveSpecialty, setIsActiveSpecialty] = useOutsideClick(specialtyInputRef, false);

  // input strings
  const [locationSearchStr, setLocationSearchStr] = useState<string>(filter.userLocation.name);
  const [specialtySearchStr, setSpecialtySearchStr] = useState<string>(filter.specialty.name);

  const [autoComplete, setAutoComplete] = useState<any>([]);
  const [filteredSpecializations, setFilteredSpecializations] = useState<ISpecialization[]>([]);
  const [cursor, setCursor] = useState<number>(0);

  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  useEffect(() => {
    if (!loading && data) {
      const cloned = _.cloneDeep(specializations);
      setFilteredSpecializations(cloned);
    }
  }, [data, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isActiveLocation && locationSearchStr.length > 2) {
      getLocation()
        .then((res) => {
          if (res) {
            setAutoComplete(res.features);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [locationSearchStr]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // check if set date is from past dates, which is no longer relevant
    const date = new Date(filter.date);
    if (isPastDay(date)) {
      dispatch(SetFilter({ ...filter, date: new Date() }));
    }
  }, [isActiveDate]);

  const getLocation = async () => {
    const geoObjPromise = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${locationSearchStr}.json?country=dk&language=da&access_token=${MAPBOX_TOKEN}`
    );
    return await geoObjPromise.json();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'location') {
      setLocationSearchStr(e.target.value);

      // clear filter, when input has changed
      if (filter.userLocation.coordinates[0] !== 0 && filter.userLocation.coordinates[1] !== 0) {
        // dispatch(SetFilter({ ...filter, userLocation: [0, 0] }));
      } else {
        setIsActiveLocation(true);
      }
    }

    if (e.target.name === 'specialty') {
      setSpecialtySearchStr(e.target.value);
      const filtered = specializations.filter((specialty) =>
        specialty.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredSpecializations(filtered);
      // clear filter, when input has changed and set state to active
      if (filtered.some((specialty) => specialty.name.toLowerCase() === e.target.value.toLowerCase())) {
        setIsActiveSpecialty(false);
      } else {
        setIsActiveSpecialty(true);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const list = target.name === 'location' ? autoComplete : target.name === 'specialty' ? filteredSpecializations : [];

    // eject if list is empty (no point then)
    // if (list.length === 0) return;

    // arrow up/down button should select next/previous list element
    if (e.key === 'ArrowUp') {
      if (cursor > 0) {
        setCursor(cursor - 1);
      } else {
        setCursor(list.length - 1);
      }
      e.preventDefault();
    } else if (e.key === 'ArrowDown') {
      if (cursor < list.length - 1) {
        setCursor(cursor + 1);
      } else {
        setCursor(0);
      }
      e.preventDefault();
    } else if (e.key === 'Enter') {
      if (target.name === 'specialty') {
        handleOnSelectSpecialty(cursor);
      }

      if (target.name === 'location') {
        handleOnSelectLocation(cursor);
      }
    }
  };

  const handleOnSelectLocation = (index: number) => {
    setIsActiveLocation(false);
    if (locationSearchStr === '') {
      // get users geolocation, if browser supports navigator and intent on using current location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const location: [number, number] = [position.coords.latitude, position.coords.longitude];
          setLocationSearchStr('Min Placering');
          dispatch(SetFilter({ ...filter, userLocation: { name: 'Min Placering', coordinates: location } }));
        });
      } else {
        setLocationSearchStr('Danmark');
      }
    } else {
      // if (autoComplete.length === 0) return;
      setLocationSearchStr(autoComplete[index].place_name);
      const userLocation = {
        name: autoComplete[index].place_name,
        coordinates: autoComplete[index].geometry.coordinates.reverse(),
      };
      dispatch(SetFilter({ ...filter, userLocation })); // reversing coordinates from mapbox, as they for some reason do it wrong
    }
  };

  const handleOnSelectSpecialty = (index: number) => {
    setIsActiveSpecialty(false);
    if (filteredSpecializations.length === 0) return;
    const { _id, name } = filteredSpecializations[index];
    const specialty = { _id, name };

    setSpecialtySearchStr(filteredSpecializations[index].name);
    dispatch(SetFilter({ ...filter, specialty }));
  };

  const handleOnSelectDate = (date: Date) => {
    dispatch(SetFilter({ ...filter, date }));
    setIsActiveDate(false);
  };

  const handleOnClear = () => {
    setLocationSearchStr('');
    // setSelectedLocation(null);
    setAutoComplete([]);
  };

  return (
    <Container>
      <InputContainer ref={locationInputRef}>
        <InputButton onClick={() => setIsActiveLocation(true)}>
          <label className='location-input'>
            <div className='input-label'>Lokation</div>
            <input
              name='location'
              placeholder='Hvor i landet?'
              autoFocus={isActiveLocation}
              autoComplete='off'
              autoCorrect='off'
              spellCheck={false}
              value={locationSearchStr}
              onKeyDown={handleKeyDown}
              onChange={onChange} // add debounce on onchange
            />
          </label>
          {locationSearchStr && (
            <div className='clear'>
              <ClearInput>
                <div className='clear-input' onClick={handleOnClear}>
                  <IoClose />
                </div>
              </ClearInput>
            </div>
          )}
        </InputButton>
        {isActiveLocation && autoComplete.length > 0 && (
          <LocationInput>
            <ul>
              {autoComplete.map((place: any, i: number) => (
                <li
                  className={`city ${cursor === i ? 'active' : ''}`}
                  onMouseEnter={() => setCursor(i)}
                  onClick={() => handleOnSelectLocation(i)}
                  key={place.id}
                >
                  <AutocompleteElement>
                    <Icon>
                      <IoLocationOutline />
                    </Icon>
                    <div>{place.place_name.replace(', Danmark', '')}</div>
                  </AutocompleteElement>
                </li>
              ))}
            </ul>
          </LocationInput>
        )}
      </InputContainer>
      <VerticalLine />
      <InputContainer ref={dateInputRef}>
        <InputButton onClick={() => setIsActiveDate(true)}>
          <label className='date-input'>
            <div className='input-label'>Dato</div>
            <input
              readOnly
              placeholder='Hvornår?'
              value={isToday(filter.date) ? 'I dag' : format(filter.date, 'd. MMMM', { locale: da })}
            />
          </label>
        </InputButton>
        {isActiveDate && <DateDropDown date={filter.date} onSave={handleOnSelectDate} />}
      </InputContainer>
      <VerticalLine />
      <InputContainer ref={specialtyInputRef}>
        <InputButton onClick={() => setIsActiveSpecialty(true)}>
          <label className='specialty-input'>
            <div className='input-label'>Specialitet</div>
            <input
              name='specialty'
              placeholder='Hvad leder du efter?'
              autoFocus={isActiveSpecialty}
              autoComplete='off'
              autoCorrect='off'
              spellCheck={false}
              value={specialtySearchStr}
              onKeyDown={handleKeyDown}
              onChange={onChange} // add debounce on onchange
            />
          </label>
          {/* {locationSearchStr && (
            <div className='clear'>
              <ClearInput>
                <div className='clear-input' onClick={handleOnClear}>
                  <IoClose />
                </div>
              </ClearInput>
            </div>
          )} */}
        </InputButton>
        {isActiveSpecialty && !loading && filteredSpecializations.length > 0 && (
          <SpecialtyContainer>
            <ul>
              {filteredSpecializations
                .map((specialty, i: number) => (
                  <li
                    className={`specialty ${cursor === i ? 'active' : ''}`}
                    onMouseEnter={() => setCursor(i)}
                    onClick={() => handleOnSelectSpecialty(i)}
                    key={specialty._id}
                  >
                    {specialty.name}
                  </li>
                ))
                .slice(0, 5)}
            </ul>
          </SpecialtyContainer>
        )}
      </InputContainer>
      <button
        className='search'
        onClick={() => {
          if (isPastDay(new Date(filter.date))) {
            dispatch(SetFilter({ ...filter, date: new Date() }));
          }
          navigate('/search');
        }}
      >
        <SearchIcon>
          <IoSearch />
        </SearchIcon>
      </button>
      <AnimatePresence>
        {/* {locationOpen && (
          <LocationInput
            initial={'initial'}
            animate={'isOpen'}
            exit={'exit'}
            variants={menuVariant}
            ref={dropdownRef}
          ></LocationInput>
        )} */}
      </AnimatePresence>
    </Container>
  );
};

export default Search;
