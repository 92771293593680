import { Input } from 'components/ui/Input';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonFooter, FormContainer } from '../style';
import Button from 'components/ui/Button';
import { IEditProfileModalBase } from '../interfaces';

interface IProps extends IEditProfileModalBase {
  phone: IPhoneFormData;
}

interface IPhoneFormData {
  countryCode: string;
  number: string;
}

const PhoneModalForm: React.FC<IProps> = ({ phone: { countryCode, number }, onCancel, onSave }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      countryCode,
      number,
    },
  });

  const onSubmit = (data: IPhoneFormData) => onSave(data); // api call

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name='countryCode'
          label='Land'
          id='countrycode-input'
          type='text'
          inputType='form'
          error={errors.countryCode}
          register={register}
        />
        <Input
          name='number'
          label='Telefonnummer'
          id='number-input'
          type='text'
          inputType='form'
          error={errors.number}
          register={register}
        />
        <ButtonFooter>
          <Button label='Annuller' variant='hollow' flex onClick={onCancel} />
          <Button label='Gem' flex type='submit' disabled={!!errors.countryCode && !!errors.number} />
        </ButtonFooter>
      </form>
    </FormContainer>
  );
};

export default PhoneModalForm;
