import React, { useContext, useEffect, useState } from 'react';
import { ModalContent } from 'components/Modal/style';
import Modal from 'components/Modal';
import Button from 'components/ui/Button';
import { Buttons, CheckboxContainer, FilterContent, FilterTitle, Section } from './style';
import Checkbox from 'components/ui/Checkbox';
import { IProvider } from 'interfaces';
import { IFilterGender, IFilterLanguage } from 'interfaces';
import { store } from 'store/store';
import { SetFilter } from 'store/store.actions';
import { H2 } from 'components/Styled';

interface IProps {
  toggle(modal: string): void;
  isOpen: boolean;
  filtersApplied(amount: number): void;
  providers: IProvider[];
}

const FilterModal: React.FC<IProps> = ({ toggle, isOpen, filtersApplied, providers }): JSX.Element => {
  const {
    state: { filter },
    dispatch,
  } = useContext(store);
  const [genders, setGenders] = useState<IFilterGender[]>(filter.genders);
  const [languages, setLanguages] = useState<IFilterLanguage[]>(filter.languages);

  useEffect(() => {
    const checkedGenders: IFilterGender[] = genders.filter((gender) => gender.checked);
    const checkedLanguages: IFilterLanguage[] = languages.filter((lang) => lang.checked);
    const appliedFilters = [...checkedGenders, ...checkedLanguages].length;

    filtersApplied(appliedFilters);
  }, [filter]);

  useEffect(() => {
    if (providers && !filter) {
      const languages: string[] = providers
        .map((provider) => provider.users.map((user) => user.languages).flat())
        .flat();

      const languageList: IFilterLanguage[] = Array.from(new Set(languages)).map((language) => ({
        language,
        checked: false,
      }));

      const genders: IFilterGender[] = [
        {
          gender: 'male',
          checked: false,
        },
        {
          gender: 'female',
          checked: false,
        },
        {
          gender: 'non-binary',
          checked: false,
        },
      ];

      setLanguages(languageList);
      setGenders(genders);
    }
  }, [providers]);

  const onLanguageChange = (index: number, checked: boolean) => {
    languages[index].checked = !checked;
    setLanguages([...languages]);
  };

  const onGenderChange = (i: number, checked: boolean) => {
    genders[i].checked = !checked;
    setGenders([...genders]);
  };

  const handleOnClear = () => {
    genders.map((gender) => (gender.checked = false));
    languages.map((lang) => (lang.checked = false));

    setGenders([...genders]);
    setLanguages([...languages]);
  };

  const handleApplyFilter = () => {
    dispatch(SetFilter({ ...filter, genders, languages }));
    toggle('');
  };

  return (
    <Modal toggle={toggle} isOpen={isOpen} title='Vælg filter'>
      <ModalContent>
        <Section>
          <FilterTitle>
            <H2 marginBottom={12}>Køn</H2>
          </FilterTitle>
          <FilterContent>
            {genders.map(({ gender, checked }, i) => (
              <CheckboxContainer key={gender}>
                <Checkbox label={gender} value={checked} onChange={() => onGenderChange(i, checked)} />
              </CheckboxContainer>
            ))}
          </FilterContent>
        </Section>
        <Section>
          <FilterTitle>
            <H2 marginBottom={12}>Talte sprog</H2>
          </FilterTitle>
          <FilterContent>
            {languages.map(({ language, checked }, i) => (
              <CheckboxContainer key={i}>
                <Checkbox
                  label={new Intl.DisplayNames(['da'], { type: 'language' }).of(language)}
                  value={checked}
                  onChange={() => onLanguageChange(i, checked)}
                />
              </CheckboxContainer>
            ))}
          </FilterContent>
        </Section>
        <Buttons>
          <Button variant='hollow' label='Fjern alle filtre' onClick={handleOnClear} />
          <Button gradient label='Anvend filter' onClick={handleApplyFilter} />
        </Buttons>
      </ModalContent>
    </Modal>
  );
};

export default FilterModal;
