import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  row-gap: 12px;
  width: 200px;
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.white};
  padding: 12px;
  border-radius: 25px;
  column-gap: 6px;

  input {
    border: none;
    outline: none;
    margin: 0;
  }
`;

export const SearchResults = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  justify-content: center;
  border-radius: 4px;
  top: 50px;
  background-color: ${COLORS.white};
  width: 200%;
  z-index: 2;
  box-shadow: 0px 2px 4px #00000026;
  max-height: 500px;
  overflow: hidden;
  row-gap: 6px;
`;

export const ProviderResult = styled.div`
  display: flex;
  padding: 18px 24px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.Primary.blueTint};
    color: ${COLORS.Primary.darkblue};
  }
`;
