import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  input {
    margin-right: 3px;
  }
`;

export const Label = styled.label`
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxInput = styled.div`
  margin-right: 12px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 0px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`;

export const InputText = styled.div`
  color: ${COLORS.Primary.darkblue};
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
`;

export const CheckboxIcon = styled.span<{
  checked: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: 1px solid ${COLORS.Primary.darkblue};
  height: 18px;
  width: 18px;
  background: ${({ checked }) => (checked ? COLORS.Primary.darkblue : '#fff')};
  text-align: center;
  overflow: hidden;
  vertical-align: top;
  border-radius: 6px;
  color: #fff;
`;
