import React from 'react';
import { Container, Icon, Label } from './style';
import { IoLogoFacebook, IoLogoGoogle, IoLogoAmazon, IoMailOutline } from 'react-icons/io5';
import { COLORS } from 'style';

interface IProps {
  label?: string;
  color?: 'darkblue' | 'blue' | 'lightblue' | 'red' | 'offwhite';
  type?: 'button' | 'submit' | 'reset';
  variant?: 'regular' | 'hollow';
  fontSize?: number;
  icon?: JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
  some?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  fixedWidth?: boolean;
  flex?: boolean;
  gradient?: boolean;
  shadow?: boolean;
}

const Button: React.FC<IProps> = ({
  label,
  color = 'blue',
  type = 'button',
  variant = 'regular',
  fontSize,
  gradient = false,
  icon,
  some,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  fixedWidth = false,
  flex = false,
  shadow = false,
  disabled = false,
  onClick,
}): JSX.Element => {
  let bgColor =
    variant === 'hollow'
      ? 'transparent'
      : gradient
      ? 'linear-gradient(335deg, rgba(15,68,175,1) 0%, rgba(22,99,253,1) 100%)'
      : COLORS.Primary[color];
  let textColor = variant === 'hollow' ? COLORS.Primary[color] : COLORS.white;
  let border = variant === 'hollow' ? `2px solid ${COLORS.Primary[color]}` : 'none';
  const iconSize = 24;

  switch (some) {
    case 'facebook':
      icon = <IoLogoFacebook fontSize={iconSize} color={COLORS.white} />;
      label = 'Fortsæt med Facebook';
      bgColor = '#3B5998';
      break;
    case 'google':
      icon = <IoLogoGoogle fontSize={iconSize} color={COLORS.white} />;
      label = 'Fortsæt med  Google';
      bgColor = '#4285F4';
      break;
    case 'amazon':
      icon = <IoLogoAmazon fontSize={iconSize} color={COLORS.black} />;
      label = 'Fortsæt med  Amazon';
      bgColor = '#FF9900';
      textColor = COLORS.black;
      break;
    case 'email':
      icon = <IoMailOutline fontSize={iconSize} color={COLORS.white} />;
      label = 'Opret med Email';
      bgColor = COLORS.Primary.darkblue;
      textColor = COLORS.white;
      break;
    default:
      break;
  }

  return (
    <Container
      bgColor={bgColor}
      onClick={onClick}
      shadow={shadow}
      border={border}
      type={type}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginRight={marginRight}
      marginLeft={marginLeft}
      fixedWidth={fixedWidth}
      flex={flex}
      color={textColor}
      disabled={disabled}
    >
      {icon && <Icon>{icon}</Icon>}
      <Label fontSize={fontSize}>{label}</Label>
    </Container>
  );
};

export default Button;
