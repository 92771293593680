// Colors
export const COLORS = {
  Primary: {
    darkblue: '#1c2154',
    blue: '#1663fd',
    lightblue: '#68e1fe',
    red: '#da364c',
    offwhite: '#f9f9f9',
    blueWhite: '#f5f8fb',
    blueTint: '#f8fafc',
  },
  Secondary: {
    darkpurple: '#821b88',
    purple: '#805ae0',
    pink: '#ea1b88',
    teal: '#0feadf',
    blue: '#0f4ead',
  },
  white: '#fff',
  black: '#000',
  darkgrey: '#444',
  grey: '#999',
  lightgrey: '#ddd',
  bluegrey: 'rgb(100, 116, 139)',
  // dark: '#444',
  // primary: '#945aff',
  // secondary: 'lightblue',
  // tertiary: 'green'
};

export const ANIMATIONS = {
  success: 'https://assets9.lottiefiles.com/packages/lf20_uu0x8lqv.json',
  loading: 'https://assets9.lottiefiles.com/private_files/lf30_lndg7fhf.json',
  notFound: 'https://lottie.host/e899a83b-c481-4a56-8dab-5e5e383663f3/2Nqj27ZKvP.json',
};
