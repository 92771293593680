import { gql } from '@apollo/client';

export const CORE_PROVIDER_USER_FIELDS = gql`
  fragment CoreProviderUserFields on User {
    _id
    providerId
    name
    lastName
    languages
    email
    phone
    profileImage
    gender
    title
  }
`;

export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on User {
    _id
    providerId
    ghostProviderId
    name
    lastName
    profileImage
    gender
  }
`;

export const CORE_LOCATION_FIELDS = gql`
  fragment CoreLocationFields on ProviderLocation {
    _id
    street
    city
    postcode
    geocode
    primary
    rooms {
      _id
      name
    }
  }
`;

export const CORE_PRODUCT_FIELDS = gql`
  fragment CoreProductFields on ProviderProduct {
    _id
    name
    description
    reference
    price
    durationInMinutes
    isPrivate
    isActive
    isDefault
  }
`;
