import React, { useEffect, useState } from 'react';
import { Container, Img, Main, Logo } from './style';
import { Text, H2 } from 'components/Styled';
import logo from '../../assets/images/rivevo-logo-vertical.svg';
import { Input } from 'components/ui/Input';
import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading, NotFound, Success } from 'components/ui/Animiations';
import { GET_INVITATION, ADD_USER_CREDENTIALS } from 'graphql/mutations';

interface IPasswordFormData {
  password: string;
  confirmPassword: string;
}

const Invitation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [addUserCredentials, { data, error, loading }] = useMutation(ADD_USER_CREDENTIALS);
  const [getInvitationByToken, { data: invitationData, error: invitationError, loading: invitationLoading }] =
    useMutation(GET_INVITATION, {
      variables: { token: searchParams.get('token') || '' },
    });
  const navigate = useNavigate();
  // const [success, setSuccess] = useState

  useEffect(() => {
    getInvitationByToken();
  }, []);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit = ({ password }: IPasswordFormData) => {
    const token = searchParams.get('token');

    const credentialsInput = {
      password,
      token,
    };

    addUserCredentials({ variables: { credentialsInput } });
  };

  const getContent = () => {
    if (loading || invitationLoading) {
      return <Loading />;
    }

    if (error || invitationError) {
      return <NotFound />;
    }

    if (
      (invitationData && invitationData.invitation.invitee.accounts.length > 0) ||
      (data && data.addUserCredentials.invitee.accounts.length > 0)
    ) {
      if (data) {
        return (
          <>
            <Success />
            <H2>Velkommen</H2>
            <Text>Dit kodeord er nu opsat og du kan nu logge ind på din nye konto!</Text>
            <Button label='Tilbage til start' onClick={() => navigate('/')} />
          </>
        );
      }

      if (invitationData) {
        return (
          <>
            <Success />
            <H2>Tillykke</H2>
            <Text>{`Dit bruger er nu en del af ${invitationData.invitation.provider.name}!`}</Text>
            <Button label='Tilbage til start' onClick={() => navigate('/')} />
          </>
        );
      }
    } else {
      return (
        <>
          <Img src={logo} alt='Rievo logo' />
          <div className='header'>
            <H2>Nyt kodeord</H2>
            <Text>Indtast kodeord og bekræft</Text>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              name='password'
              label='Kodeord'
              id='password-input'
              type='password'
              inputType='form'
              error={errors.password}
              register={register}
            />
            <Input
              name='confirmPassword'
              label='Bekræft kodeord'
              id='confirm-password-input'
              type='password'
              inputType='form'
              error={errors.confirmPassword}
              register={register}
              regOptions={{
                validate: (val: string) => {
                  if (watch('password') !== val) {
                    return 'Dine kodeord passer ikke';
                  }
                },
              }}
            />
            <Button
              marginTop={12}
              label='Gem'
              flex
              type='submit'
              disabled={!!errors.confirmPassword || !!errors.password}
            />
          </form>
        </>
      );
    }
  };

  return (
    <Main>
      <Container>{getContent()}</Container>
    </Main>
  );
};

export default Invitation;
