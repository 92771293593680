import React, { useEffect, useState } from 'react';
import { H2 } from 'components/Styled';
import { Container, UserList, User, UserTitle, UserImage, UserName } from './style';
// import Accordion from 'components/ui/Accordion';
import { IUser } from 'interfaces';
import nonBinaryAvatar from 'assets/images/genderless-avatar.jpeg';
import maleAvatar from 'assets/images/male-avatar.png';
import femaleAvatar from 'assets/images/male-avatar.png';
import PUserModal from './PUserModal';
import _ from 'lodash';

interface IProps {
  providerUsers: IUser[];
}

const ProviderUsers: React.FC<IProps> = ({ providerUsers }): JSX.Element => {
  const [isOpen, toggle] = useState<string>('');
  const [selecteduser, setSelecteduser] = useState<IUser | null>(null);
  const [users, setusers] = useState<IUser[]>(providerUsers);

  useEffect(() => {
    // set default avatars, if no user image exists (TODO: move to user resolver)
    const deepCloneusers = _.cloneDeep(users);

    deepCloneusers.map((user) => {
      if (!user.profileImage) {
        if (user.gender === 'male') {
          user.profileImage = maleAvatar;
        } else if (user.gender === 'female') {
          user.profileImage = femaleAvatar;
        } else {
          user.profileImage = nonBinaryAvatar;
        }
      }
      return user;
    });
    setusers(deepCloneusers);
  }, [providerUsers]);

  const onSelectuser = (user: IUser) => {
    setSelecteduser(user);
    toggle('review');
  };

  const renderUserList = () => {
    return users.map((user) => {
      return (
        <User key={user._id} onClick={() => onSelectuser(user)}>
          <UserImage>{<img src={user.profileImage} alt={user.lastName} />}</UserImage>
          {/* <UserLanguages></UserLanguages> */}
          <UserName>
            <span>{user.name}</span>
            <span>{user.lastName}</span>
          </UserName>
          <UserTitle>{user.title}</UserTitle>
        </User>
      );
    });
  };
  return (
    <>
      <Container>
        <H2 noMargin>Behandlere</H2>
        <UserList>{renderUserList()}</UserList>
      </Container>
      {selecteduser && <PUserModal user={selecteduser} isOpen={isOpen === 'review'} toggle={toggle} />}
    </>
  );
};

export default ProviderUsers;
