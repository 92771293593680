import React, { useContext, useEffect, useRef, useState } from 'react';
import { AboutProvider, ProviderContainer, ProviderWrapper, Title } from './style';
import Header from './Header';
import ProviderBooking from './Booking';
import ProviderReviews from './Reviews';
import { useLazyQuery } from '@apollo/client';
import { GET_PROVIDER } from 'graphql/queries';
import { IAvailabilityArgs, IProvider } from 'interfaces';
import PageNotFound from 'containers/PageNotFound';
import ProviderFAQ from './FAQ';
import ProviderUsers from './ProviderUsers';
import SmartText from 'components/ui/SmartText';
import { COLORS } from 'style';
import { H2 } from 'components/Styled';
import { store } from 'store/store';

const Provider: React.FC = (): JSX.Element => {
  const {
    state: { filter },
  } = useContext(store);
  const scrollTo = (ref: React.RefObject<HTMLElement>) => ref.current!.scrollIntoView({ behavior: 'smooth' });
  const headerRef = useRef(null);
  const reviewsRef = useRef(null);
  const [getProvider, { loading, error, data }] = useLazyQuery<{ provider: IProvider }>(GET_PROVIDER);
  // const {provider = {}} = data || {};

  const [selectedDate, setSelectedDate] = useState<Date>(filter.date || new Date());
  const [selectedProduct, setSelectedProduct] = useState<string>(''); // ObjectId
  const [selectedLocation, setSelectedLocation] = useState<string>(''); // ObjectId
  const [selectedUser, setSelectedUser] = useState<string>(''); // ObjectId

  useEffect(() => {
    const availabilityArgs: IAvailabilityArgs = {
      startDate: selectedDate,
      puser: selectedUser, // objectID
      location: selectedLocation, // objectID
      product: selectedProduct,
      days: 7,
    };

    getProvider({
      variables: {
        providerId: window.location.pathname.split('/').pop(),
        availabilityArgs,
      },
    });
  }, [selectedProduct, selectedUser, selectedDate, getProvider, selectedLocation, data]);

  if (error) {
    console.log(error.graphQLErrors);
    return <PageNotFound />;
  }

  return (
    <ProviderContainer>
      {data?.provider && (
        <ProviderWrapper>
          <Header provider={data.provider} elRef={headerRef} onClick={() => scrollTo(reviewsRef)} />
          <AboutProvider>
            <H2 noMargin>{`Om ${data.provider.name}`} </H2>
            <div className='about'>
              <SmartText
                overlayColor={COLORS.white}
                text={`ABOUT: Dr Oleg Goncharov graduated with a Doctoral Degree from Kharkiv National Medical University,
              Dr. Goncharov continued his education at New York University College of Dentistry, where he received
              his Doctoral Degree in Dental Surgery. Dr. Goncharov completed AEGD program at Lutheran Medical Center,
              where he practiced all aspects of general dentistry. It was one of the Lutheran Medical Center dental
              clinics, where Dr. Goncharov served as an attending dentist. Having perpetually positive attitude and
              a long track record of successful patient’s diagnosing & treatment, Dr. Goncharov decided to practice
              privately in New York City, which he did for seven years. With that experience & desire to develop
              further in the area of dental medicine, Dr. Goncharov joined the team of fellow colleagues to launch
              Village Dental Medicine, where he provides all kinds of dental treatment, including certified Invisalign
              advanced treatment modality, which is an invisible clear alternative to wire braces. Dr. Goncharov
              constantly expands his foundations of knowledge, improves his professional skills and stays up-to-date
              on new developments in the dentistry by participating in continuing education programs and various seminars.`}
              ></SmartText>
            </div>
          </AboutProvider>
          <ProviderBooking
            provider={data.provider}
            setSelectedProduct={setSelectedProduct}
            selectedProduct={selectedProduct}
            setSelectedLocation={setSelectedLocation}
            selectedLocation={selectedLocation}
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            loadingAvailability={loading}
          />
          {data.provider.users.length > 0 && <ProviderUsers providerUsers={data.provider.users} />}
          {data.provider.faqs.length > 0 && <ProviderFAQ faqs={data.provider.faqs} />}
          <ProviderReviews provider={data.provider} elRef={reviewsRef} />
        </ProviderWrapper>
      )}
    </ProviderContainer>
  );
};

export default Provider;
