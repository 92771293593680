import React, { useContext, useEffect, useState } from 'react';
import { IoFilter, IoOptionsSharp, IoVideocamOutline } from 'react-icons/io5';
import FilterModal from './FilterModal';
import { Container, FilterButton, Filters } from './style';
import { DateDropDown, LocationDropDown, DistanceDropDown, SpecialtyDropDown } from './DropDownButtons';
import { IProvider, IFilter } from 'interfaces';
import { store } from 'store/store';
import { SetFilter } from 'store/store.actions';

interface IProps {
  providers: IProvider[];
}

const SearchFilter: React.FC<IProps> = ({ providers }): JSX.Element => {
  const {
    state: { filter },
    dispatch,
  } = useContext(store);
  const [isOpen, toggle] = useState('');
  const [amountFiltersApplied, setAmountFiltersApplied] = useState<number>(0);

  const onSetMaxDistance = (maxDistance: number) => {
    dispatch(SetFilter({ ...filter, maxDistance }));
  };

  return (
    <Container>
      <div className='filter-title'>
        <IoFilter />
        <span>Filtrér din søgning</span>
      </div>
      <Filters>
        <DistanceDropDown distance={filter.maxDistance} onSaveDistance={onSetMaxDistance} />
        <FilterButton type='button'>
          <span className='button-icon'>
            <IoVideocamOutline />
          </span>
          Video besøg
        </FilterButton>
        <FilterButton type='button' onClick={() => toggle('filter')}>
          {amountFiltersApplied > 0 && <span className='filters-active'>{amountFiltersApplied}</span>}
          <span className='button-icon'>
            <IoOptionsSharp />
          </span>
          Flere filtre
        </FilterButton>
      </Filters>
      <FilterModal
        filtersApplied={setAmountFiltersApplied}
        providers={providers}
        isOpen={isOpen === 'filter'}
        toggle={toggle}
      />
    </Container>
  );
};

export default SearchFilter;
