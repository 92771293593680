import { Input } from 'components/ui/Input';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer } from '../style';
import Button from 'components/ui/Button';
import { IEditProfileModalBase } from '../interfaces';

interface IPasswordFormData {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const PasswordModalForm: React.FC<IEditProfileModalBase> = ({ onCancel, onSave }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit = (data: IPasswordFormData) => onSave(data); // api call

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name='currentPassword'
          label='Nuværende kodeord'
          id='oldpassword-input'
          type='password'
          inputType='form'
          error={errors.currentPassword}
          register={register}
        />
        <Input
          name='password'
          label='Kodeord'
          id='password-input'
          type='password'
          inputType='form'
          error={errors.password}
          register={register}
        />
        <Input
          name='confirmPassword'
          label='Bekræft kodeord'
          id='confirm-password-input'
          type='password'
          inputType='form'
          error={errors.confirmPassword}
          register={register}
          regOptions={{
            validate: (val: string) => {
              if (watch('password') !== val) {
                return 'Dine kodeord passer ikke';
              }
            },
          }}
        />
        <Button label='Gem' flex type='submit' disabled={!!errors.currentPassword || !!errors.password} />
      </form>
    </FormContainer>
  );
};

export default PasswordModalForm;
