import Modal from 'components/Modal';
import React from 'react';
import { IUser } from '../../../interfaces';
import { Title } from '../style';
import { ProfileImage, ProfileName, Container, ProfileHeader, ProfileUserTitle, ProfileContent, Text } from './style';

interface IProps {
  user: IUser;
  toggle(modal: string): void;
  isOpen: boolean;
}

const userModal: React.FC<IProps> = ({ toggle, isOpen, user }): JSX.Element => {
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <Container>
        <ProfileImage>
          <img src={user.profileImage} alt={`${user.name} ${user.lastName}`} />
        </ProfileImage>
        <ProfileHeader>
          <ProfileName>{`${user.name} ${user.lastName}`}</ProfileName>
          <ProfileUserTitle>{user.title}</ProfileUserTitle>
        </ProfileHeader>
        <ProfileContent>
          <Title>{`Om ${user.name} ${user.lastName}`}</Title>
          <Text margin={24}>
            Ruth C. Johnson, M.D. is a Board Certified physician in Internal Medicine. She earned her Bachelors of
            Science in Biochemistry from La Sierra University, California. She started her medical training at the
            Autonomous University of Guadalajara and completed her medical degree at New York Medical College, Valhalla,
            New York through the Fifth Pathway Program. She completed he residency at the Mount Sinai School of Medicine
            – North General in Manhattan in July, 2007. She joined Hercules Medical in October 2007. Dr. Johnson is a
            member of the American Medical Association and American College of Physicians.
          </Text>
          <Title>Uddannelse</Title>
        </ProfileContent>
      </Container>
    </Modal>
  );
};

export default userModal;
