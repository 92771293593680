import React from 'react';
import { FullStar, EmptyStar } from './style';

interface IProps {
  index: number;
  rating: number;
  hoverRating: number;
  onMouseEnter(index: number): void;
  onMouseLeave(): void;
  onSaveRating(index: number): void;
}

const StarIcon: React.FC<IProps> = ({
  index,
  hoverRating,
  rating,
  onMouseEnter,
  onMouseLeave,
  onSaveRating,
}): JSX.Element => {
  //   const fill: string = useMemo(() => {
  //   }, [rating, hoverRating, index]);

  const renderStar = () => {
    if (hoverRating >= index) {
      return <FullStar />;
    } else if (!hoverRating && rating >= index) {
      return <FullStar />;
    }
    return <EmptyStar />;
  };
  return (
    <div
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={() => onMouseLeave()}
      onClick={() => onSaveRating(index)}
    >
      {renderStar()}
    </div>
  );
};

export default StarIcon;
