// import { IReviewRating } from 'interfaces';
import React, { useState } from 'react';
import { IoStar } from 'react-icons/io5';
import { Container, Icon, ExtendedInfo, RatingTitle, RatingContent } from './style';

interface IProps {
  rating: number;
  reviewCount: number;
}

const Rating: React.FC<IProps> = ({ rating, reviewCount }): JSX.Element => {
  // const { price, service, time } = rating || {};
  const [onHover, setOnHover] = useState<boolean>(false);
  return (
    <Container onMouseOver={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
      <Icon>
        <IoStar />
      </Icon>
      {rating > 0 && <div className='rating'>{rating}</div>}
      {reviewCount ? (
        <span className='review-count'>{reviewCount ? `(${reviewCount} anmeldelser)` : ''}</span>
      ) : (
        <span className='no-reviews'>Ingen anmeldelser</span>
      )}
      {/* {onHover && (
        <ExtendedInfo>
          <RatingTitle>Rating info</RatingTitle>
          <RatingContent>
            <div>{`Price: ${price}`}</div>
            <div>{`Serivce: ${service}`}</div>
            <div>{`Time: ${time}`}</div>
          </RatingContent>
        </ExtendedInfo>
      )} */}
    </Container>
  );
};

export default Rating;
