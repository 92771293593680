import { COLORS } from 'style';
import styled from 'styled-components';

export const LottieWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  /* justify-content: space-around; */
`;

export const LottieCard = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  /* background-color: #f9f9f9; */
  border-radius: 6px;
  padding: 24px;
  margin-right: 48px;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }

  &:last-child {
    margin: 0;
  }
`;

export const CardTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-top: 24px;
  color: ${COLORS.Primary.darkblue};
`;

export const Title = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 48px 0;
  font-weight: 700;
  font-size: 36px;
  color: ${COLORS.Primary.darkblue};

  h2 {
    font-size: inherit;
    margin: 0;
  }
`;
