import React from 'react';
import { Container } from './style';
import { H1 } from 'components/Styled';
import { IReviewTextProps } from './interface';

const ReviewText: React.FC<IReviewTextProps> = ({ reviewText, setReviewText }): JSX.Element => {
  return (
    <Container>
      <textarea rows={10} value={reviewText} onChange={(event) => setReviewText(event.target.value)} />
    </Container>
  );
};

export default ReviewText;
