import { useOutsideClick } from 'helpers/hooks';
import React, { useRef } from 'react';
import Button from '../Button';
import { Container, Icon, DropdownContent, DropButton } from './style';

interface IProps {
  label: string;
  icon?: JSX.Element;
  children: React.ReactNode;
  isSet: boolean;
  onClear(): void;
  onSave(): void;
  onReset?(): void;
}

const DropdownButton: React.FC<IProps> = ({ children, label, icon, onClear, onSave, onReset, isSet }): JSX.Element => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useOutsideClick(dropdownRef, false);

  const handleOnSave = () => {
    onSave();
    setIsActive(false);
  };

  const handleIsActive = () => {
    setIsActive(!isActive);

    if (!isActive) {
      onReset?.();
    }
  };

  return (
    <Container ref={dropdownRef}>
      <DropButton focused={isActive || isSet} onClick={handleIsActive}>
        {icon && <Icon>{icon}</Icon>}
        {label}
      </DropButton>
      {isActive && (
        <DropdownContent>
          {children}
          <div className='buttons'>
            <button className='clear-button' onClick={() => onClear()}>
              <label>Nulstil</label>
            </button>
            <Button gradient label='Gem' onClick={handleOnSave} />
          </div>
        </DropdownContent>
      )}
    </Container>
  );
};

export default DropdownButton;
