import styled from 'styled-components';

export const AddInputButton = styled.button`
  background-color: lightblue;
  border: 1px solid #ddd;
  border-radius: 6px;
  height: 40px;
  padding: 12px 24px;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    background-color: #ddd;
  }
`;

export const InputContainer = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-auto-flow: row;
  gap: 6px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .remove-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ddd;
    font-size: 20px;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  flex: 1;
  height: 38px;
  outline: none;
  padding: 0 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-weight: 600;
  margin-right: 6px;
`;

export const ProductList = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

export const Controls = styled.div`
  display: flex;
  margin-top: 24px;
  column-gap: 24px;
  justify-content: space-between;
`;

export const SearchBar = styled.div`
  display: flex;
`;
