import React from 'react';
import { Block, Container, Row } from '../style';
import Calendar from './Calendar';

const Bookings = (): JSX.Element => {
  return (
    <Container>
      <Block>
        <Row>
          <Calendar />
        </Row>
      </Block>
    </Container>
  );
};

export default Bookings;
