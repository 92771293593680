import { COLORS } from 'style';
import styled from 'styled-components';
import { size } from '../../components/Styled';

export const ProviderContainer = styled.main`
  display: block;
  position: relative;
`;

export const ProviderWrapper = styled.div`
  display: flex;
  flex-flow: column;
  @media only screen and (min-width: ${size.sm}) {
    max-width: 62.5%;
  }
`;

export const AboutProvider = styled.section`
  padding: 24px;

  div.about {
    padding: 24px;
    margin-top: 12px;
    background: #fff;
    border-radius: 6px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${COLORS.Primary.darkblue};
  margin-bottom: 6px;
`;
