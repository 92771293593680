import styled from 'styled-components';
import { motion } from 'framer-motion';

export const MenuContainer = styled.div`
  position: relative;
`;

export const MenuTrigger = styled.button`
  height: 40px;
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  outline: none;

  div {
    display: flex;
    align-items: center;
  }

  :hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }

  img {
    border-radius: 25px;
    height: 32px;
    width: 32px;
  }

  span {
    font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0 10px;
    color: #444;
  }
`;

export const Menu = styled(motion.div)`
  background: #ffffff;
  border-radius: 6px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  z-index: 99;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 6px;

    li {
      border-bottom: 1px solid #dddddd;

      span {
        text-decoration: none;
        color: #333333;
        padding: 15px 20px;
        display: block;
        cursor: pointer;
        :hover {
          background-color: #ededf5;
        }

        &:first-of-type {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        &:last-of-type {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

// window animation
export const menuVariant = {
  initial: { opacity: 0 },
  isOpen: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
};
