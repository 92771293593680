import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS } from 'style';

export const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  gap: 12px;
`;

export const ModalContainer = styled(motion.div)<{ stretch: string }>`
  display: flex;
  position: relative;
  flex-flow: column;
  width: 100% !important;
  max-width: 568px !important;
  max-height: 75vh;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);

  ${(props) =>
    props.stretch === 'stretch'
      ? css`
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        `
      : css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}

  form {
    textarea {
      outline: none;
      resize: none;
    }
  }
`;

export const Header = styled.div<{ hasTitle: boolean; marginLeft?: boolean }>`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ hasTitle }) => (hasTitle ? '18px' : '0')};
  justify-content: center;
  align-items: center;

  h2 {
    flex: 1;
    text-align: center;
    margin-left: ${({ marginLeft }) => (marginLeft ? '48px' : '2px')};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  height: 32px;
`;

export const Text = styled.p`
  margin: 0 0 24px 0;

  span {
    font-size: 18px;
    font-weight: 500;
    color: ${COLORS.Primary.darkblue};
  }
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  font-size: 26px;

  &:hover {
    border-radius: 50%;
    background-color: ${COLORS.Primary.blueTint};
  }
`;

export const BackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  font-size: 22px;

  &:hover {
    border-radius: 50%;
    background-color: ${COLORS.Primary.blueTint};
  }
`;

export const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};

export const containerVariant = {
  initial: { top: '-50%', transition: { type: 'spring' } },
  isOpen: { top: '50%' },
  exit: { top: '-50%' },
};

export const ModalContent = styled(motion.div)<{ align?: string }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-top: 1px solid ${COLORS.Primary.blueWhite};
  overflow: auto;
  margin-bottom: 3px;

  ${({ align }) =>
    align === 'center' &&
    css`
      align-items: center;
    `};

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-color: #ddd;
    border-radius: 6px;
  }

  &::-webkit-scrollbar {
    border: none;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background: #fff;
    overflow: hidden;
  }
`;

export const CTAFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-right: 6px;
  }

  button.ctaButton {
    /* font-size: inherit !important;
    font-family: inherit !important;
    font-style: inherit !important;
    font-variant: inherit !important;
    line-height: inherit; */
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
    user-select: auto;
    color: ${COLORS.Primary.darkblue};
    text-decoration: underline;
    font-weight: 600;
    outline: none;
  }
`;

export const Seperator = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ddd;
  line-height: 0.1em;
  margin: 24px 0 24px;

  span {
    background: #fff;
    padding: 0 10px;
    font-size: 12px;
  }
`;

export const OTPContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  button {
    background: none;
    border: none;
    font-size: 18px;
    font-weight: 500;
    color ${COLORS.Primary.blue};
    cursor: pointer;

    &:hover {
      filter: brightness(150%);
    }
  }
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

export const SmallLink = styled.div`
  display: block;
  text-align: center;
  padding: 12px;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  a {
    text-decoration: none;
    color: ${COLORS.bluegrey};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  row-gap: 36px;

  p {
    max-width: 400px;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  padding: 24px;
  border: 1px solid ${COLORS.Primary.red}80;
  border-radius: 6px;
  margin-bottom: 24px;
  background-color: ${COLORS.Primary.red}50;
`;
