import { SmallText } from 'components/Styled';
import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div<{ marginBottom?: boolean }>`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 3px;

  .container {
    display: flex;
    flex-flow: row;
  }
`;

export const Description = styled(SmallText)`
  color: ${COLORS.darkgrey};
  font-size: 14px;
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  color: ${COLORS.Primary.darkblue};
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
`;

export const Switch = styled.div<{ $disabled: boolean }>`
  display: flex;
  align-items: center;

  .switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;
    background-color: ${({ $disabled }) => ($disabled ? COLORS.Primary.blue + '50' : COLORS.lightgrey)};
    border-radius: 2rem;
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
    transition: background-color 0.2s ease-out;

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
      transition: all 0.2s ease-out;
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked + .switch:after {
    left: 26px;
  }

  input[type='checkbox']:not(:disabled):checked + .switch {
    background-color: ${COLORS.Primary.blue};
  }
`;
