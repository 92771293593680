import React, { useState } from 'react';
// import { useOutsideClick } from 'helpers/hooks';
import { H2, H3 } from '../../../components/Styled';
import { Container, ReviewsHeader, ReviewsList } from './style';
import Review from './Review';
import { useQuery } from '@apollo/client';
import { IReview, IProvider, IUser } from 'interfaces';
import Button from 'components/ui/Button';
import ReviewModal from './ReviewModal';
import { GET_ME, GET_PROVIDER_REVIEWS } from 'graphql/queries';

interface IProps {
  provider: IProvider;
  elRef: React.RefObject<HTMLElement>;
}

const Reviews: React.FC<IProps> = ({ provider, elRef }): JSX.Element => {
  // const { auth }: IAuthContext = useAuth();
  const { data: authData } = useQuery<{ me: IUser }>(GET_ME);
  const { loading, error, data } = useQuery(GET_PROVIDER_REVIEWS, {
    variables: { providerId: provider._id },
  });
  const [isOpen, toggle] = useState('');

  if (loading) return <p>Loading...</p>;
  if (error) {
    console.log(error.graphQLErrors);
    return <p>Could not get reviews!</p>;
  }

  return (
    <>
      <Container ref={elRef}>
        <H2 noMargin>Bruger anmeldelser</H2>
        <ReviewsHeader>
          <H3 noMargin>
            {data.reviews.length > 0 ? `${data.reviews.length} anmeldelser` : 'Ingen anmeldelser (endnu)'}
          </H3>
        </ReviewsHeader>
        {data.reviews.length > 0 && (
          <ReviewsList>
            {data.reviews.map((review: IReview) => (
              <Review provider={provider} review={review} authUser={authData?.me} key={review._id} />
            ))}
          </ReviewsList>
        )}
        {/* {authData?.me && !authData?.me.providerId && ( */}
        <Button label='Anmeld behandleren' onClick={() => toggle('review')} />
        {/* )} */}
      </Container>
      <ReviewModal providerId={provider._id} isOpen={isOpen === 'review'} toggle={toggle} />
    </>
  );
};

export default Reviews;
