import { gql } from '@apollo/client';
import { CORE_PROVIDER_USER_FIELDS, CORE_LOCATION_FIELDS, CORE_PRODUCT_FIELDS } from '../fragments';

export const GET_PROVIDERS = gql`
  ${CORE_PROVIDER_USER_FIELDS}
  ${CORE_LOCATION_FIELDS}
  query GetProviders {
    providers {
      _id
      img
      name
      specialization {
        _id
        name
      }
      availability(availabilityArgs: $availabilityArgs) {
        date
        timeslots {
          timeslot
          product
          availablePUsers
          availableLocations
        }
      }
      locations {
        ...CoreLocationFields
        distanceToProvider
      }
      rating
      reviewCount
      users {
        ...CoreProviderUserFields
      }
    }
  }
`;

export const GET_TOP_PROVIDERS = gql`
  ${CORE_LOCATION_FIELDS}
  query GetTopProviders {
    topProviders {
      _id
      img
      name
      specialization {
        _id
        name
      }
      locations {
        ...CoreLocationFields
        distanceToProvider
      }
      rating
      topReview {
        _id
        providerId
        body
        createdAt
        updatedAt
        rating {
          avgRating
          price
          service
          time
        }
      }
    }
  }
`;

export const GET_FILTERED_PROVIDERS = gql`
  ${CORE_PROVIDER_USER_FIELDS}
  ${CORE_LOCATION_FIELDS}
  ${CORE_PRODUCT_FIELDS}
  query FilteredProviders($filter: ProviderFilterArgs!, $availabilityArgs: ProviderAvailabilityArgs!) {
    providers(filter: $filter) {
      _id
      img
      name
      specialization {
        _id
        name
      }
      availability(availabilityArgs: $availabilityArgs) {
        date
        timeslots {
          availableLocations {
            ...CoreLocationFields
          }
          availablePUsers {
            ...CoreProviderUserFields
          }
          timeslot
          product {
            ...CoreProductFields
          }
        }
      }
      locations {
        ...CoreLocationFields
        distanceToProvider
      }
      products {
        ...CoreProductFields
      }
      rating
      reviewCount
      users {
        ...CoreProviderUserFields
      }
    }
  }
`;

export const GET_STRINGFILTERED_PROVIDERS = gql`
  query LightProviders($searchString: String!) {
    lightProviders(searchString: $searchString) {
      _id
      img
      name
      specialization {
        _id
        name
      }
    }
  }
`;

export const GET_PROVIDER = gql`
  ${CORE_PROVIDER_USER_FIELDS}
  ${CORE_LOCATION_FIELDS}
  ${CORE_PRODUCT_FIELDS}
  query GetProvider($providerId: String!, $availabilityArgs: ProviderAvailabilityArgs!) {
    provider(providerId: $providerId) {
      _id
      img
      name
      specialization {
        _id
        name
      }
      availability(availabilityArgs: $availabilityArgs) {
        date
        timeslots {
          availableLocations {
            ...CoreLocationFields
          }
          availablePUsers {
            ...CoreProviderUserFields
          }
          timeslot
          product {
            ...CoreProductFields
          }
        }
      }
      locations {
        ...CoreLocationFields
        distanceToProvider
      }
      rating
      reviewCount
      openingHours {
        day
        from
        to
        isOpen
      }
      faqs {
        _id
        question
        answer
      }
      products {
        ...CoreProductFields
      }
      users {
        ...CoreProviderUserFields
      }
    }
  }
`;

export const GET_PROVIDER_ADMIN = gql`
  ${CORE_PROVIDER_USER_FIELDS}
  ${CORE_LOCATION_FIELDS}
  ${CORE_PRODUCT_FIELDS}
  query GetProviderAdmin {
    providerAdmin {
      _id
      img
      name
      specialization {
        _id
        name
      }
      locations {
        ...CoreLocationFields
      }
      # rating {
      #   price
      #   service
      #   time
      # }
      faqs {
        _id
        question
        answer
      }
      openingHours {
        day
        from
        to
        isOpen
      }
      settings {
        journalTemplate {
          label
          type
        }
      }
      products {
        ...CoreProductFields
      }
      users {
        ...CoreProviderUserFields
      }
    }
  }
`;

export const GET_PROVIDER_NAME = gql`
  query GetProviderAdmin {
    providerAdmin {
      _id
      img
      name
    }
  }
`;

export const GET_PROVIDER_JOURNAL_TEMPLATE = gql`
  query GetProviderAdmin {
    providerAdmin {
      _id
      settings {
        journalTemplate {
          label
          type
        }
      }
    }
  }
`;

export const GET_PROVIDER_PRODUCTS = gql`
  ${CORE_PRODUCT_FIELDS}
  query GetProviderProducts {
    providerAdmin {
      _id
      product {
        ...CoreProductFields
      }
    }
  }
`;

export const GET_PROVIDER_AVAILABILITY = gql`
  ${CORE_PROVIDER_USER_FIELDS}
  ${CORE_LOCATION_FIELDS}
  ${CORE_PRODUCT_FIELDS}
  query GetProviderAvailability($providerId: String!, $availabilityArgs: ProviderAvailabilityArgs!) {
    provider(providerId: $providerId) {
      availability(availabilityArgs: $availabilityArgs) {
        date
        timeslots {
          availableLocations {
            ...CoreLocationFields
          }
          availablePUsers {
            ...CoreProviderUserFields
          }
          timeslot
        }
      }
    }
  }
`;
