import styled from 'styled-components'


export const Img = styled.img`
  height: 40px;
  width: auto;
`

export const LogoContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`
export const LogoText = styled.p`
  color: #fff;
  font-size: 34px;
  font-weight: 700;
  margin: 0 0 0 10px;
  text-shadow: 1px 2px 6px rgba(0, 0, 0, .1)
`
