import React, { useEffect, useState } from 'react';
import { ModalContent } from 'components/Modal/style';
import Modal from 'components/Modal';
import Button from 'components/ui/Button';
import PriceRating from './priceRating';
import TimeRating from './timeRating';
import { AnimatePresence } from 'framer-motion';
import { ButtonContainer } from './style';
import ServiceRating from './serviceRating';
import ReviewText from './reviewText';
import Success from './success';
import { IReviewInput, IReviewModalProps } from './interface';
import { useMutation } from '@apollo/client';
import { ADD_REVIEW } from 'graphql/mutations';
import { GET_PROVIDER_REVIEWS } from 'graphql/queries';

const ReviewModal: React.FC<IReviewModalProps> = ({ toggle, isOpen, providerId }): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const [priceRating, setPriceRating] = useState<number>(0);
  const [serviceRating, setServiceRating] = useState<number>(0);
  const [timeRating, setTimeRating] = useState<number>(0);
  const [reviewText, setReviewText] = useState<string>('');

  const [addReview, { data, loading: mutationLoading, error: mutationError }] = useMutation(ADD_REVIEW, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PROVIDER_REVIEWS, variables: { providerId } }],
  });

  // reset step state
  useEffect(() => setStep(1), [isOpen]);

  const renderStep = (number: number) => {
    switch (number) {
      case 1:
        return <PriceRating rating={priceRating} setRating={setPriceRating} />;
      case 2:
        return <TimeRating rating={timeRating} setRating={setTimeRating} />;
      case 3:
        return <ServiceRating rating={serviceRating} setRating={setServiceRating} />;
      case 4:
        return <ReviewText reviewText={reviewText} setReviewText={setReviewText} />;
      case 5:
        return <Success loading={mutationLoading} data={data} error={mutationError} closeModal={toggle} />;
      default:
        console.log('This is a multi-step form built with React.');
    }
  };

  // Proceed to next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  const submitHandler = () => {
    const review: IReviewInput = {
      providerId,
      body: reviewText,
      rating: {
        price: priceRating,
        service: serviceRating,
        time: timeRating,
      },
    };

    addReview({ variables: { review } });
    setStep(5);
  };

  // const { handleSubmit, register, errors, control } = useForm<ISignUpForm>();
  // const onSubmit = (): void => {
  //   // TODO: handle create review api
  // };
  return (
    <Modal toggle={toggle} isOpen={isOpen} title='Skriv en anmeldelse'>
      <AnimatePresence>
        <ModalContent initial={'initial'} animate={'step'} exit={'exit'}>
          {renderStep(step)}
          <ButtonContainer>
            {step > 1 && step !== 5 && (
              <Button onClick={prevStep} variant='hollow' label='Tilbage' fixedWidth marginRight={6} />
            )}
            {step < 4 && <Button onClick={nextStep} label='Næste' fixedWidth />}
            {step === 4 && <Button onClick={submitHandler} label='Send' fixedWidth />}
          </ButtonContainer>
        </ModalContent>
      </AnimatePresence>
    </Modal>
  );
};

export default ReviewModal;
