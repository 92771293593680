import React from 'react';
import { Content, Rows } from './style';
import { H1 } from 'components/Styled';
import InputRow from './InputRow';
import { IProfileContentProps } from './interfaces';
import Modal from 'components/Modal';

const AccountAndSecurity: React.FC<IProfileContentProps> = ({ user, openModal }) => {
  return (
    <Content>
      <H1 noMargin>Konto & Sikkerhed</H1>
      <Rows>
        <InputRow label='Password' value={`Skift Kodeord`} onClick={() => openModal('Skift Kodeord')} />
      </Rows>
    </Content>
  );
};

export default AccountAndSecurity;
