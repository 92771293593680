import { gql } from '@apollo/client';

export const GET_SPECIALIZATIONS = gql`
  query GetSpecializations {
    specializations {
      _id
      name
    }
  }
`;
