import { gql } from '@apollo/client';

export const GET_PATIENTS = gql`
  query GetPatients($searchString: String!) {
    patients(searchString: $searchString) {
      _id
      name
      lastName
      email
      phone
    }
  }
`;
