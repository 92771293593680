import { Input } from 'components/ui/Input';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ButtonFooter, FormContainer } from '../style';
import { IEditProfileModalBase } from '../interfaces';
import Button from 'components/ui/Button';

interface IProps extends IEditProfileModalBase {
  firstName: string;
  lastName: string;
}

interface INameFormData {
  firstName: string;
  lastName: string;
}

const NameModalForm: React.FC<IProps> = ({ firstName, lastName, onSave, onCancel }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName,
      lastName,
    },
  });
  // const {field} = useController({name: 'first'});
  const onSubmit = (data: INameFormData) => onSave(data); // api call

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name='firstName'
          label='Fornavn'
          id='firstname-input'
          type='text'
          inputType='form'
          error={errors.firstName}
          register={register}
        />
        <Input
          name='lastName'
          label='Efternavn'
          id='lastname-input'
          type='text'
          inputType='form'
          error={errors.lastName}
          register={register}
        />
        <ButtonFooter>
          <Button label='Annuller' variant='hollow' flex onClick={onCancel} />
          <Button label='Gem' flex type='submit' />
        </ButtonFooter>
      </form>
    </FormContainer>
  );
};

export default NameModalForm;
