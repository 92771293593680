import React, { useState } from 'react';
import { Container, Img, Main, Logo } from './style';
import { Text, H2 } from 'components/Styled';
import logo from '../../assets/images/rivevo-logo-vertical.svg';
import { Input } from 'components/ui/Input';
import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_PASSWORD } from 'graphql/mutations/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading, Success } from 'components/ui/Animiations';

interface IPasswordFormData {
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const [updateUserPassword, { data, error, loading }] = useMutation(UPDATE_USER_PASSWORD);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // const [success, setSuccess] = useState

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit = ({ password }: IPasswordFormData) => {
    const userId = searchParams.get('id');
    const token = searchParams.get('token');

    const updateUserPasswordInput = {
      password,
      userId,
      token,
    };

    updateUserPassword({ variables: { updateUserPasswordInput } });
  };

  return (
    <Main>
      <Container>
        {loading && <Loading />}
        {error && <div>Error!</div>}
        {!data && !loading && !error && (
          <>
            <Img src={logo} alt='Rievo logo' />
            <div className='header'>
              <H2>Gendan dit kodeord</H2>
              <Text>Indtast dit nye kodeord og tryk gem</Text>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                name='password'
                label='Kodeord'
                id='password-input'
                type='password'
                inputType='form'
                error={errors.password}
                register={register}
              />
              <Input
                name='confirmPassword'
                label='Bekræft kodeord'
                id='confirm-password-input'
                type='password'
                inputType='form'
                error={errors.confirmPassword}
                register={register}
                regOptions={{
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return 'Dine kodeord passer ikke';
                    }
                  },
                }}
              />
              <Button
                marginTop={12}
                label='Gem'
                flex
                type='submit'
                disabled={!!errors.confirmPassword || !!errors.password}
              />
            </form>
          </>
        )}
        {data && !loading && !error && (
          <>
            <Success />
            <Text>Dit kodeord er nu ændret!</Text>
            <Button label='Tilbage til start' onClick={() => navigate('/')} />
          </>
        )}
      </Container>
    </Main>
  );
};

export default ResetPassword;
