import { COLORS } from 'style';
import styled, { css } from 'styled-components';

export const UserDetails = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const ProfileImage = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 12px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-flow: column;
`;

export const UserName = styled.div`
  font-weight: 600;
  color: ${COLORS.darkgrey} span {
    font-weight: 400;
    font-size: 12px;
  }
`;

export const Email = styled.span`
  font-size: 14px;
  color: ${COLORS.grey};
`;

export const MoreButton = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 28px;
  cursor: pointer;
`;

export const RoleBadge = styled.div`
  display: inline;
  border-radius: 4px;
  border: 1px solid ${COLORS.Primary.darkblue + '10'};
  padding: 3px 6px;
  font-size: 14px;
  background-color: ${COLORS.Primary.blueTint};
`;
