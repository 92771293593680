import { H2 } from 'components/Styled';
import React from 'react';
import { Block, Container } from '../style';

const Overview = (): JSX.Element => {
  return (
    <Container>
      <Block>
        <H2>Overview title</H2>
      </Block>
      <Block>
        <H2>Overview title</H2>
      </Block>
    </Container>
  );
};

export default Overview;
