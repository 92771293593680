import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Container, ProviderResult, Search, SearchResults } from './style';
import { IoSearchOutline } from 'react-icons/io5';
import { IProvider } from 'interfaces';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_PROVIDER_ADMIN, GET_PROVIDER_USERS, GET_STRINGFILTERED_PROVIDERS } from 'graphql/queries';
import { Loading } from 'components/ui/Animiations';
import { UPDATE_GHOSTPROVIDERID } from 'graphql/mutations';
import { useOutsideClick } from 'helpers/hooks';

interface IProps {
  provider: IProvider;
}

const ProviderSearch: React.FC<IProps> = ({ provider }): JSX.Element => {
  // const [selectedProviderId, setSelectedProviderId] = useState<string>(providerId);
  const dropdownRef = useRef(null);
  const [searchString, setSearchString] = useState(provider.name);
  const [showResults, setShowResults] = useState(false);
  const [isActive, setIsActive] = useOutsideClick(dropdownRef, false);
  const [updateGhostProviderId] = useMutation(UPDATE_GHOSTPROVIDERID, {
    refetchQueries: [GET_PROVIDER_USERS, GET_PROVIDER_ADMIN],
  });
  const [getProviders, { data, loading, error }] = useLazyQuery<{ lightProviders: IProvider[] }>(
    GET_STRINGFILTERED_PROVIDERS
  );

  useEffect(() => {
    // only query, if searchstring is at least 3 chars
    if (searchString.length > 0) {
      getProviders({ variables: { searchString } });
    }
  }, [searchString]);

  const handleSelectProvider = (provider: IProvider) => {
    // setSelectedProviderId(provider._id);
    setShowResults(false);
    setSearchString(provider.name);
    updateGhostProviderId({ variables: { id: provider._id } });
  };

  return (
    <Container>
      <Search>
        <IoSearchOutline />
        <input
          value={searchString}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)}
          onFocus={() => {
            setIsActive(true);
            setShowResults(true);
          }}
        />
      </Search>
      {data?.lightProviders && searchString !== '' && showResults && isActive && (
        <SearchResults ref={dropdownRef}>
          {loading ? (
            <Loading width={50} height={50} />
          ) : data.lightProviders.length === 0 ? (
            <div>No results found</div>
          ) : (
            data.lightProviders.map((provider) => (
              <ProviderResult key={provider._id} onClick={() => handleSelectProvider(provider)}>
                {provider?.name}
              </ProviderResult>
            ))
          )}
        </SearchResults>
      )}
    </Container>
  );
};

export default ProviderSearch;
