import { COLORS } from 'style';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  max-height: 100vh;

  // root table styles
  table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    tbody {
      display: table-row-group;
      tr {
        display: table-row;
        color: inherit;
        outline: 0;
        vertical-align: middle;
        td {
          display: table-cell;
          border-bottom: 1px solid rgba(224, 224, 224, 1);
          vertical-align: inherit;
        }
      }
    }
  }
`;

export const Header = styled.div`
  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
`;

export const Control = styled.div`
  display: flex;
  padding: 6px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    cursor: pointer;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  overflow-y: auto;
  table-layout: fixed;
`;

export const Content = styled.div`
  display: inline-flex;
`;

export const Days = styled.div`
  display: inline-flex;
  overflow: visible;
  position: sticky;
  background: #fff;
  table-layout: fixed;
  border-bottom: 1px solid rgba(224, 224, 224, 1);

  .filler {
    width: 81px;
    display: flex;
    min-width: 81px;
    align-items: flex-end;
  }

  table {
    width: 100%;
    min-width: 700px;
    table-layout: fixed;
    tbody {
      tr {
        td {
          box-sizing: border-box;
          text-align: center;
          padding-top: 4px;
          user-select: none;
          padding-left: 0;
          border-bottom: none;
          padding-right: 0;
          padding-bottom: 4px;

          div {
            display: flex;
            flex-flow: column;
            text-align: center;
            justify-content: center;
            align-items: center;

            p {
              font-size: 14px;
              font-weight: 300;
              letter-spacing: 2px;
              margin-bottom: 3px;
              text-transform: uppercase;
            }

            span {
              font-size: 32px;
              font-weight: 800;
              margin-bottom: 6px;
            }
          }
        }
      }
    }
  }
`;

export const TimeslotTable = styled.div`
  table {
    width: 100%;
    table-layout: fixed;
    min-width: 700px;

    .bookings {
      top: 0;
      left: 0;
      width: 100%;
      position: absolute;
    }
  }
`;

export const Cell = styled.td<{
  isDraggingOver: boolean;
  active: boolean;
}>`
  height: 48px;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  border-right: 1px solid #ececec;
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  cursor: ${({ active }) => (active ? 'pointer' : 'none')};
  background-color: ${(props) => (props.isDraggingOver ? '#77d17720' : '#fff')};

  ${({ active }) =>
    active
      ? css`
          &:empty:hover {
            background-color: #fafafa;
          }
        `
      : css`
          background: repeating-linear-gradient(45deg, #fff, #fff 10px, #bc606c20 10px, #bc606c20 20px);
        `}

  &:hover {
    background-color: ${COLORS.Primary.blueTint};
  }
`;

export const Timeline = styled.div`
  display: flex;
  flex-direction: row;
  border-right: 1px solid rgba(224, 224, 224, 1);
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  overflow: visible;
  position: sticky;
  background: #fff;
  table-layout: fixed;

  .timeline-wrapper {
    display: flex;
    flex-direction: row;
  }

  table {
    display: table;
    width: 72px;
    td {
      padding: 0;
      box-sizing: border-box;
      border-bottom: none;
      .half {
        height: 24px;
      }

      div {
        border: 0;
        height: 48px;
        padding: 0;
        overflow: hidden;
        text-align: right;
        line-height: 48px;
        user-select: none;
        padding-left: 2px;
        padding-right: 8px;
        text-overflow: ellipsis;
      }
    }
  }

  table + table {
    width: 8px;
    box-sizing: border-box;
    table-layout: fixed;
    td {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      height: 48px;
      padding: 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  }
`;
