import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-flow: column;
  margin-left: 260px;
  background-color: #f5f8fb;
  overflow: visible;
  padding: 24px;
  transition: all 0.3s;
  row-gap: 24px;
`;
