import styled from 'styled-components';
import { COLORS } from 'style';

export const Container = styled.aside`
  display: block;
  width: 250px;
  padding-right: 48px;

  nav {
    ul {
      display: flex;
      flex-flow: column;
      list-style: none;
      row-gap: 12px;
      padding: 0;
      margin: 0;

      li {
        font-size: 16px;
        font-weight: 500;

        a {
          display: flex;
          padding: 6px;
          flex-flow: row;
          align-items: center;
          column-gap: 6px;
          cursor: pointer;

          &:hover,
          &.active {
            background-color: rgb(100, 116, 139, 0.05);
            color: ${COLORS.Primary.blue};
            border-radius: 3px;
          }
        }
      }
    }
  }
`;
