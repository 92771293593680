import { motion } from 'framer-motion';
import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-flow: column;

  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: ${COLORS.grey};
    padding: 6px;
    font-weight: 500;

    &:hover {
      color: ${COLORS.Primary.blue};
    }
  }
`;

export const Text = styled(motion.div)`
  position: relative;
`;

export const Overlay = styled.div<{
  color: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ color }) => color};
  background: ${({ color }) => `linear-gradient(0deg, ${color} 5%, rgba(255, 255, 255, 0) 70%);`};
`;
