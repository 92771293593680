import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.header<{
  isLanding: boolean;
}>`
  position: ${(props) => (props.isLanding ? 'absolute' : 'relative')};
  top: 0;
  height: 84px;
  z-index: 100;
`;

export const Header = styled.div<{
  scrolled: boolean;
  isLanding: boolean;
}>`
  position: ${({ isLanding }) => (isLanding ? 'fixed' : 'relative')};
  width: 100%;
  height: 84px;
  z-index: 100;
  top: 0;
  transition: 0.1s ease-in-out;
  background: ${({ scrolled, isLanding }) => (scrolled && isLanding ? COLORS.white : 'transparent')};
  box-shadow: ${({ scrolled, isLanding }) => (scrolled && isLanding ? '0 2px 2px rgba(0, 0, 0, 0.1)' : 'none')};
`;

export const HeaderContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0 48px;
  justify-content: space-between;

  .logo {
    cursor: pointer;
    flex: 1;
  }

  .right {
    display: flex;
    flex: 1;
    flex-flow: row;
    justify-content: flex-end;
  }
`;

export const Logo = styled.img`
  width: 100px;
  height: auto;
`;
