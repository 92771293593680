import Button from 'components/ui/Button';
import {
  addDays,
  addMonths,
  subMonths,
  startOfMonth,
  isSameMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  format,
  isSameDay,
  differenceInCalendarWeeks,
  isToday,
  isBefore,
} from 'date-fns';
import { da } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { DateContainer, DateControls, DateTitle, CalendarContent, Calendar } from './style';

interface IProps {
  date: Date;
  onSave(selectedDate: Date): void;
}

const DateDropDown: React.FC<IProps> = ({ date, onSave }): JSX.Element => {
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(date));
  const monthStart = startOfMonth(selectedMonth);
  const monthEnd = endOfMonth(monthStart);
  const weekStart = startOfWeek(monthStart, { locale: da });
  const weekEnd = endOfWeek(monthEnd, { locale: da });
  const weeks = differenceInCalendarWeeks(weekEnd, weekStart);
  const shortWeekDay = Array.from(Array(7)).map((e, i) =>
    format(addDays(startOfWeek(new Date()), ++i), 'eee', { locale: da }).replace('.', '')
  );

  useEffect(() => {
    if (date) {
      setSelectedDate(date);
    }
  }, [date]);

  const renderMonth = () => {
    let day = weekStart;
    let week: Date[] = [];
    const month: Date[][] = [];
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    for (let idx = 0; idx < weeks; idx++) {
      for (let i = 0; i < 7; i++) {
        week.push(day);
        day = addDays(day, 1);
      }
      month.push(week);
      week = [];
    }

    return month.map((_week, i) => (
      <tr key={i}>
        {_week.map((day, i) => (
          <td
            key={i}
            onClick={() => isSameMonth(day, selectedMonth) && setSelectedDate(day)}
            className={`cell ${
              !isSameMonth(day, selectedMonth) || isBefore(day, today)
                ? 'disabled'
                : isSameDay(day, selectedDate)
                ? 'selected'
                : ''
            }`}
          >
            {format(day, 'd')}
          </td>
        ))}
      </tr>
    ));
  };

  const onClear = () => {
    setSelectedDate(new Date());
  };

  return (
    <DateContainer>
      <Calendar>
        <DateTitle>
          <div>{format(selectedMonth, 'MMMM Y', { locale: da })}</div>
          <DateControls>
            <div className='date-control' onClick={() => setSelectedMonth(subMonths(selectedMonth, 1))}>
              <IoChevronBack />
            </div>
            <div className='date-control' onClick={() => setSelectedMonth(addMonths(selectedMonth, 1))}>
              <IoChevronForward />
            </div>
          </DateControls>
        </DateTitle>
        <CalendarContent>
          <table>
            <thead className='week-days'>
              <tr>
                {shortWeekDay.map((weekDay, i) => (
                  <th key={i} className='week-day'>
                    {weekDay}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{renderMonth()}</tbody>
          </table>
        </CalendarContent>
      </Calendar>
      <div className='buttons'>
        <button className='clear-button' onClick={() => onClear()}>
          <label>Nulstil</label>
        </button>
        <Button gradient label='Gem' onClick={() => onSave(selectedDate)} />
      </div>
    </DateContainer>
  );
};

export default DateDropDown;
