import { COLORS } from 'style';
import styled, { css } from 'styled-components';

export const NoUsersText = styled.div`
  flex: 1;
  text-align: center;
  font-style: italic;
  font-size: 12;
  color: #444;
`;

export const Header = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`;

export const Tabs = styled.div`
  display: flex;
  flex-flow: row;
  padding: 4px;
  border-radius: 6px;
  background-color: ${COLORS.Primary.blueWhite};
`;

export const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${({ isActive }) => (isActive ? COLORS.white : 'transparent')};
  color: ${({ isActive }) => (isActive ? COLORS.Primary.darkblue : COLORS.bluegrey)};
`;

export const TextColumn = styled.div<{ exp?: boolean }>`
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: ${({ exp }) => (exp ? COLORS.Primary.red : COLORS.darkgrey)};
`;
