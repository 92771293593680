import React from 'react';
import { H1 } from '../../../components/Styled';
import {
  Container,
  HeaderContent,
  ProviderInfo,
  ProviderImgWrapper,
  Subtitle,
  ProfileImg,
  ProviderNoticeMessage,
  NoticeTitle,
  // RatingContainer,
  // Rating,
  Icon,
  NoticeMessageContent,
  NoticeSubtitle,
} from './style';
// import Badge from '../../../components/ui/Badge';
import { IProvider } from 'interfaces';
import { IoMegaphoneOutline, IoStar } from 'react-icons/io5';
import SmartText from 'components/ui/SmartText';

interface IProps {
  provider: IProvider;
  onClick(): void;
  elRef: React.RefObject<HTMLElement>;
}
const Header: React.FC<IProps> = ({ provider, onClick, elRef }): JSX.Element => {
  const { locations, name, img, specialization, rating, reviewCount } = provider;
  const { city } = locations[0];
  return (
    <Container ref={elRef}>
      <HeaderContent>
        <div className='headerInfo'>
          <ProviderImgWrapper>
            <picture>
              <ProfileImg src={`/__MOCKDATA__/profileImages/${img}`} />
            </picture>
          </ProviderImgWrapper>
          <ProviderInfo>
            <H1 noMargin>{name}</H1>
            <Subtitle>
              {rating && (
                <>
                  <span className='rating'>
                    <span className='icon'>
                      <IoStar />
                    </span>
                    <span>{`${rating && rating} • `}</span>
                    <span className='review-count' onClick={onClick}>{`${reviewCount} reviews `}</span>
                  </span>
                  <span className='dot'>•</span>
                </>
              )}
              <span className='specialization'>{`${specialization.name}`}</span>
              <span className='dot'>•</span>
              <span>{`${city}`}</span>
            </Subtitle>
            {/* <Badge type='favorit' /> */}
          </ProviderInfo>
        </div>
        <ProviderNoticeMessage>
          <Icon>
            <IoMegaphoneOutline />
          </Icon>
          <NoticeMessageContent>
            <NoticeSubtitle>Besked fra behandleren</NoticeSubtitle>
            <NoticeTitle>Information vedrøende nye patienter!</NoticeTitle>
            <SmartText
              text='Kiropraktorer og det øvrige autoriserede sundhedsvæsen er IKKE omfattet af nedlukningen og må fortsat gerne
            modtage patienter. Det betyder, at man gerne må gå til kiropraktor, hvis man har smerter i muskler og led.'
            />
          </NoticeMessageContent>
        </ProviderNoticeMessage>
      </HeaderContent>
    </Container>
  );
};

export default Header;
