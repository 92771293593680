import { H3 } from 'components/Styled';
import { AnimatePresence } from 'framer-motion';
import { useOutsideClick } from 'helpers/hooks';
import React, { useRef } from 'react';
import NotificationList from './NotificationList';
import {
  Footer,
  Header,
  Icon,
  NotificationContainer,
  NotificationContent,
  NotificationTrigger,
  notificationVariant,
} from './style';

export interface INotification {
  id: number;
  notificationType: string;
  createdAt: Date;
  sender: string;
  receiver: string;
  read: boolean;
}

const Notifications = () => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useOutsideClick(dropdownRef, false);
  const toggleMenu = () => setIsActive(!isActive);
  const notifications: INotification[] = [
    {
      id: 1,
      notificationType: 'test',
      read: false,
      createdAt: new Date(),
      sender: 'Christian da Silva',
      receiver: '',
    },
    {
      id: 2,
      notificationType: 'test',
      read: false,
      createdAt: new Date(),
      sender: 'Christian da Silva',
      receiver: '',
    },
    {
      id: 3,
      notificationType: 'test',
      read: true,
      createdAt: new Date(),
      sender: 'Christian da Silva',
      receiver: '',
    },
  ];

  return (
    <NotificationContainer>
      <NotificationTrigger isActive={isActive} onClick={toggleMenu}>
        <Icon />
      </NotificationTrigger>
      <AnimatePresence>
        {isActive && (
          <NotificationContent
            initial={'initial'}
            animate={'isActive'}
            exit={'exit'}
            variants={notificationVariant}
            ref={dropdownRef}
          >
            <Header>
              <H3>Notifikationer</H3>
            </Header>
            <NotificationList notifications={notifications} />
            <Footer>Vis alle</Footer>
          </NotificationContent>
        )}
      </AnimatePresence>
    </NotificationContainer>
  );
};

export default Notifications;
