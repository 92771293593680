/* global document */

import React, { useState, useCallback, useEffect, useMemo, useRef, MouseEvent } from 'react';
import { IProvider } from 'interfaces';
import { COLORS } from 'style';
import { IoPin } from 'react-icons/io5';
import { PopupContainer, PopupContent, ProviderMarker, ProviderPin, Title } from './style';
import Button from 'components/ui/Button';
import { useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import Map, { ViewState, MapRef, Marker, Popup, useMap, ScaleControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
// need to transpile worker seperately. See https://docs.mapbox.com/mapbox-gl-js/guides/install/#transpiling for info.
/* eslint-disable @typescript-eslint/no-var-requires */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

interface IProps {
  providers: IProvider[];
  hoveredId: string;
}

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const MapV7: React.FC<IProps> = ({ providers = [], hoveredId }) => {
  // const mapContainer = useRef(null);
  const navigate = useNavigate();
  const mapRef = useRef<MapRef>(null);
  const [lat, setLat] = useState(55.78529);
  const [lng, setLng] = useState(12.32133);
  const [zoom, setZoom] = useState(6);
  const [bounds, setBounds] = useState<number[] | null>(null);
  const [popupInfo, setPopupInfo] = useState<IProvider | null>(null);

  const mapStyle = {
    height: '100vh',
    position: 'sticky',
    top: 0,
    backgroundColor: COLORS.Primary.blueWhite,
  } as React.CSSProperties;

  const initialViewState = {
    latitude: 55.78529,
    longitude: 12.32133,
    zoom: 6,
  };

  // useEffect(() => {
  //   if (map.current) return; // initialize map only once
  //   map.current = new mapboxgl.Map({
  //     container: mapContainer.current,
  //     style: 'mapbox://styles/mapbox/streets-v11',
  //     center: [lng, lat],
  //     zoom: zoom,
  //   });
  // });

  const fitBounds = () => {
    const newBounds: number[] = [];

    if (providers.length === 0) return;

    // setting max/min bounds
    providers.forEach((provider, index) => {
      const [lat, lng] = provider.locations[0].geocode;

      // init bounds with first provider location
      if (index === 0) newBounds.push(lng, lat, lng, lat);

      if (lng < newBounds[0]) {
        newBounds.splice(0, 1, lng);
      }
      if (lat < newBounds[1]) {
        newBounds.splice(1, 1, lat);
      }
      if (lng > newBounds[2]) {
        newBounds.splice(2, 1, lng);
      }
      if (lat > newBounds[3]) {
        newBounds.splice(3, 1, lat);
      }
    });

    const [minLng, minLat, maxLng, maxLat] = newBounds;

    mapRef.current?.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 100, duration: 1000, maxZoom: 17 }
    );
  };

  // fitting bounds on map load
  const onMapLoad = useCallback(() => {
    fitBounds();
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      fitBounds();
    }
  }, [providers]);

  const infoHandler = (e: any, provider: IProvider) => {
    const [latitude, longitude] = provider.locations[0].geocode;
    e.stopPropagation();
    e.preventDefault();
    mapRef.current!.flyTo({ center: [longitude, latitude], duration: 1000 });
    setPopupInfo(provider);
  };

  const pins = providers.map((provider, index) => {
    const [latitude, longitude] = provider.locations[0].geocode; // TODO: render a marker for each location.
    return (
      <Marker
        key={`marker-${index}`}
        style={{ height: 30, width: 30 }}
        longitude={longitude}
        latitude={latitude}
        anchor='bottom'
      >
        <ProviderPin
          className={`provider-pin${hoveredId === provider._id ? ' hover' : ''}`}
          isHovered={hoveredId === provider._id}
          onClick={(e) => infoHandler(e, provider)}
        >
          <IoPin size='100%' />
        </ProviderPin>
      </Marker>
    );
  });

  return (
    <>
      <Map
        // {...viewState}
        // reuseMaps
        ref={mapRef}
        // onMove={(e) => setViewState(e.viewState)}
        style={mapStyle}
        onLoad={onMapLoad}
        mapStyle='mapbox://styles/saluex/cl2ym0geg000m14n55u7o7qzw'
        mapboxAccessToken={MAPBOX_TOKEN}
        initialViewState={initialViewState}
        // doubleClickZoom={false}
      >
        {/* <ScaleControl /> */}
        {pins}

        {popupInfo && (
          <Popup
            anchor='bottom'
            offset={40}
            latitude={popupInfo.locations[0].geocode[0]}
            longitude={popupInfo.locations[0].geocode[1]}
            onClose={() => setPopupInfo(null)}
            // style={{ boxShadow: '0 3px 12px rgba(0, 0, 0, .2)' }}
          >
            <PopupContainer>
              <img width={240} src={`/__MOCKDATA__/profileImages/${popupInfo.img}`} alt='Provider profile' />
              <PopupContent>
                <Title>{popupInfo.name} </Title>
              </PopupContent>
              <Button
                label='Se profil'
                onClick={() => navigate(`/${popupInfo.specialization.name.toLowerCase()}/${popupInfo._id}`)}
              />
            </PopupContainer>
          </Popup>
        )}
      </Map>
      {/* <ControlPanel /> */}
    </>
  );
};
