import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  /* display: flex; */
  padding: 48px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 900px;
  margin: auto;

  a {
    color: ${COLORS.Primary.blue};
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    &:hover {
      color: ${COLORS.Primary.lightblue};
    }
  }
`;

export const Aside = styled.aside``;
