import { Input } from 'components/ui/Input';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonFooter, FormContainer } from '../style';
import Button from 'components/ui/Button';
import { IEditProfileModalBase } from '../interfaces';

interface IProps extends IEditProfileModalBase {
  email: string;
}

interface IEmailFormData {
  email: string;
}

const EmailModalForm: React.FC<IProps> = ({ email, onCancel, onSave }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email,
    },
  });

  const onSubmit = (data: IEmailFormData) => onSave(data); // api call

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name='email'
          label='Email'
          id='email-input'
          type='text'
          inputType='form'
          error={errors.email}
          register={register}
        />
        <ButtonFooter>
          <Button label='Annuller' variant='hollow' flex onClick={onCancel} />
          <Button label='Gem' flex type='submit' disabled={!!errors.email} />
        </ButtonFooter>
      </form>
    </FormContainer>
  );
};

export default EmailModalForm;
