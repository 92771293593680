import React, { ChangeEvent, useEffect, useState } from 'react';
import { ModalContent } from 'components/Modal/style';
import Modal from 'components/Modal';
import { ButtonsContainer, FormContent } from './style';
import Button from 'components/ui/Button';
import { Loading, NotFound, Success } from 'components/ui/Animiations';
import { Input } from 'components/ui/Input';
import { IoMailOutline } from 'react-icons/io5';
import Select, { ISelectOption } from 'components/ui/Select';
import { useMutation } from '@apollo/client';
import { GET_INVITATIONS } from 'graphql/queries';
import { ADD_INVITATION } from 'graphql/mutations';

interface IProps {
  toggle(modal: string): void;
  onCancel(): void;
  isOpen: boolean;
}

const fakeRoles: ISelectOption[] = [
  { label: 'Role 1', value: 'ROLE_1' },
  { label: 'Role 2', value: 'ROLE_2' },
  { label: 'Role 3', value: 'ROLE_3' },
];

interface IInviteInput {
  email: string;
  name: string;
  lastName: string;
  providerRole: string;
}

const InviteUserModal: React.FC<IProps> = ({ toggle, onCancel, isOpen }): JSX.Element => {
  const [success, setSuccess] = useState(false);
  const [invitationInput, setInvitationInput] = useState<IInviteInput>({
    email: '',
    providerRole: '',
    name: '',
    lastName: '',
  });
  const [addInvitation, { loading, error }] = useMutation(ADD_INVITATION, {
    refetchQueries: [{ query: GET_INVITATIONS }],
    onCompleted: () => setSuccess(true),
  });

  // reset form on modal close
  useEffect(() => {
    if (!isOpen) {
      setInvitationInput({ email: '', providerRole: '', name: '', lastName: '' });
    }
  }, [isOpen]);

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <NotFound />;
    }

    if (success) {
      setTimeout(() => {
        setSuccess(false);
        onCancel();
      }, 2500);

      return <Success />;
    }

    return renderForm();
  };

  const handleOnInviteSend = () => {
    console.log(invitationInput);

    addInvitation({ variables: { invitationInput } });
  };

  const renderForm = () => {
    return (
      <FormContent>
        <Input
          label='Email'
          name='email'
          id='email-input'
          value={invitationInput.email}
          flex
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInvitationInput({ ...invitationInput, email: e.target.value })
          }
        />
        <Input
          label='Fornavn'
          name='name'
          id='name-input'
          value={invitationInput.name}
          flex
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInvitationInput({ ...invitationInput, name: e.target.value })
          }
        />
        <Input
          label='Efternavn'
          name='lastName'
          id='lastname-input'
          value={invitationInput.lastName}
          flex
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInvitationInput({ ...invitationInput, lastName: e.target.value })
          }
        />
        <Select
          label='Rolle'
          id='role-input'
          value={invitationInput.providerRole}
          options={fakeRoles}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setInvitationInput({ ...invitationInput, providerRole: e.target.value })
          }
        />
        <ButtonsContainer>
          <Button icon={<IoMailOutline />} label='Send invitation' onClick={handleOnInviteSend} />
        </ButtonsContainer>
      </FormContent>
    );
  };

  return (
    <Modal toggle={toggle} isOpen={isOpen} title={'Invitér ny bruger'}>
      <ModalContent>{renderContent()}</ModalContent>
    </Modal>
  );
};

export default InviteUserModal;
