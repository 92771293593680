import styled from 'styled-components';

export const Container = styled.section`
  padding: 24px;
  display: flex;
  flex-flow: column;
`;

export const ReviewsHeader = styled.div`
  padding: 12px 0;
  display: inline-flex;
`;

export const ReviewsList = styled.div`
  display: flex;
  flex-flow: column;
`;
