import React from 'react';
import { SectionContent } from 'containers/Home/style';
import { SContainer, StyledSlider } from '../../style';
import { Title } from './style';
import ProviderCard from './ProviderCard';
import { PreArrow, NextArrow } from './arrows';
import { useQuery } from '@apollo/client';
import { IProvider } from 'interfaces';
import { GET_TOP_PROVIDERS } from 'graphql/queries';

const slickSliderSettings = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  nextArrow: <NextArrow />,
  prevArrow: <PreArrow />,
};

const TopProviders = () => {
  const { data } = useQuery<{ topProviders: IProvider[] }>(GET_TOP_PROVIDERS);

  return (
    <SContainer bgColor='#ffffff'>
      <SectionContent>
        <Title>De mest efterspurgte behandlere</Title>
        <StyledSlider {...slickSliderSettings}>
          {data?.topProviders.map((provider) => (
            <ProviderCard key={provider._id} provider={provider} />
          ))}
        </StyledSlider>
      </SectionContent>
    </SContainer>
  );
};

export default TopProviders;
