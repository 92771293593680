import styled from 'styled-components';

export const Templates = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;

  div {
    display: flex;
    flex: 1;
    flex-flow: column;

    margin-top: 12px;
    margin-bottom: 12px;

    label {
      font-weight: 600;
      margin-bottom: 6px;
      font-size: 20px;
    }

    textarea {
      flex: 1;
      padding: 6px;
      border: 1px solid #ddd;
      border-radius: 6px;
    }
  }
`;

export const Input = styled.div`
  display: flex;
  flex-flow: column;

  label {
    font-weight: 600;
    margin-bottom: 3px;
    margin-top: 6px;
  }

  input {
    height: 26px;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 6px;
  }
`;
