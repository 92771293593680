import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  margin: 3px 0;
  font-size: 14px;
  align-items: center;
  justify-content: flex-start;

  /* padding: 24px 0; */

  .rating {
    font-weight: 500;
    margin-right: 3px;
    color: ${COLORS.Primary.darkblue};
  }

  .review-count {
    color: ${COLORS.Primary.blue};
  }

  .no-reviews {
    font-style: italic;
    color: ${COLORS.grey};
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: orange;
  font-size: 16px;
  margin-right: 3px;
`;

export const ExtendedInfo = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 12px;
  border-radius: 6px;
  top: 24px;
  left: 0;
  min-width: 200px;
`;

export const RatingTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.Primary.darkblue};
`;

export const RatingContent = styled.div`
  display: flex;
  flex-flow: column;
  color: ${COLORS.Primary.darkblue};
`;
