import Button from 'components/ui/Button';
import { Input } from 'components/ui/Input';
import { H2, Text } from 'components/Styled';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Block, Container, Row, BlockTitle } from '../../style';
import TextArea from 'components/ui/TextArea';
import { OpeningHours } from './style';
import DayInput from './DayInput';
import { useQuery, useMutation } from '@apollo/client';
import { GET_PROVIDER_ADMIN } from 'graphql/queries';
import { ADD_PROVIDER_OPENINGHOURS } from 'graphql/mutations';
import { IoSaveOutline, IoCheckmark } from 'react-icons/io5';
import { ClipLoader } from 'react-spinners';
import { IOpeningHour } from 'interfaces';
import QuillInput from 'components/ui/Quill';
import ReactQuill from 'react-quill';

interface IAboutForm {
  aboutText: string;
}

const Profile = (): JSX.Element => {
  const [hours, setHours] = useState<IOpeningHour[]>([]);
  const [saved, setSaved] = useState<boolean>(false);
  // const [aboutText, setAboutText] = useState('');
  const { data: { providerAdmin } = {} } = useQuery(GET_PROVIDER_ADMIN);
  const [addProviderOpeningHours, { loading }] = useMutation(ADD_PROVIDER_OPENINGHOURS, {
    variables: { openingHours: hours },
    onCompleted: () => setSaved(true),
  });

  useEffect(() => {
    if (providerAdmin) {
      setHours(providerAdmin.openingHours);
      // setAboutText(''); // set text from provider
    }
  }, [providerAdmin]);

  useEffect(() => {
    setSaved(false);
  }, [hours]);

  // useEffect(() => {
  //   console.log(aboutText);
  // }, [aboutText]);

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm<IAboutForm>();

  const onSubmit = async (form: IAboutForm) => {
    console.log('about text: ', form);
    // try {
    //   // send to db
    // } catch (err: any) {}
  };

  const dayInputOnChangeHandler = (day: IOpeningHour) => {
    const copyHours = [...hours];
    const index = copyHours.findIndex((el) => el.day === day.day);
    copyHours[index] = day;
    setHours([...copyHours]);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <Block>
          <BlockTitle>
            <H2>General Information</H2>
          </BlockTitle>
          <Row>
          
            <Controller
              control={control}
              name='name'
              defaultValue=''
              render={({ field: { onChange, value, name, ref } }) => (
                // { invalid, isTouched, isDirty }
                <Input
                  id={name}
                  label='Virksomhedsnavn'
                  value={value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onChange(e.target.value);
                  }}
                  type='text'
                  inputRef={ref}
                  // error={errors}
                />
              )}
            />
            <Controller
              control={control}
              name='email'
              defaultValue=''
              render={({ field: { onChange, value, name, ref } }) => (
                // { invalid, isTouched, isDirty }
                <Input
                  id={name}
                  label='Email'
                  value={value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onChange(e.target.value);
                  }}
                  type='email'
                  inputRef={ref}
                  // error={errors.email}
                />
              )}
            />
          </Row>
          <Row>
            <Controller
              control={control}
              name='telephone'
              defaultValue=''
              render={({ field: { onChange, value, name, ref } }) => (
                // { invalid, isTouched, isDirty }
                <Input
                  id={name}
                  label='Telefon'
                  value={value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onChange(e.target.value);
                  }}
                  type='phone'
                  inputRef={ref}
                  // error={errors.telephone}
                />
              )}
            />
          </Row>
        </Block> */}
        <Block>
          <BlockTitle>
            <H2 noMargin>Åbningstider</H2>
            <Text>Åbningstiderne vil blive vist på siden.</Text>
          </BlockTitle>
          <Row>
            <OpeningHours>
              {hours.map((day, dayIdx) => (
                <DayInput key={dayIdx} value={day} onChange={(day: IOpeningHour) => dayInputOnChangeHandler(day)} />
              ))}
            </OpeningHours>
          </Row>
        </Block>
        <Block>
          <H2>About</H2>
          <QuillInput
            onChange={(value) => setValue('aboutText', value)}
            // inputName='about'
            // label='About'
            // id='quill-about-input'
            // error={errors.aboutText}
            // register={register}
            // regOptions={{
            //   validate: {
            //     matchPattern: (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email er ikke korrekt',
            //   },
            // }}
            // value={value}
            // setValue={onChange}
          />
          {/* <QuillInput value={aboutText} setValue={setAboutText} /> */}
        </Block>
        <Button
          type='submit'
          marginTop={12}
          icon={
            loading ? (
              <ClipLoader color={'white'} loading={loading} size={12} />
            ) : saved ? (
              <IoCheckmark />
            ) : (
              <IoSaveOutline />
            )
          }
          label={saved ? 'Profilen er Gemt' : 'Gem'}
          // onClick={addProviderOpeningHours}
        />
      </form>
    </Container>
  );
};

export default Profile;
