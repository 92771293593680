import { COLORS } from 'style';
import styled, { css } from 'styled-components';

export const Icon = styled.div<{ margin: number }>`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: ${({ margin }) => margin}px;
  padding: 3px;
  font-size: inherit;
`;

export const ToolTipContainer = styled.div<{
  alignment?: string;
}>`
  position: absolute;
  padding: 12px;
  border: 1px solid ${COLORS.Primary.darkblue};
  border-radius: 6px;
  background-color: ${COLORS.Primary.darkblue};
  left: 50%;
  max-width: 200px;
  width: max-content;
  /* height: 100%; */
  word-wrap: break-word;
  color: #fff;
  transform: translateX(-50%);
  line-height: 1;
  z-index: 100;
  font-size: 14px;
  font-weight: 400;
  filter: drop-shadow(0px 1.5px 1.5px rgba(0, 0, 0, 0.2));
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */

  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 9px;
    margin-left: calc(9px * -1);
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
  }
  ${({ alignment }) => {
    switch (alignment) {
      case 'top':
        return css`
          bottom: calc(100% + 12px);

          &::before {
            top: 100%;
            border-top-color: ${COLORS.Primary.darkblue};
          }
        `;
      case 'bottom':
        return css`
          top: calc(100% + 12px);

          &::before {
            bottom: 100%;
            border-bottom-color: ${COLORS.Primary.darkblue};
          }
        `;
      case 'left':
        return css`
          left: auto;
          right: calc(100% + 12px);
          top: 50%;
          transform: translateX(0) translateY(-50%);

          &::before {
            left: auto;
            right: calc(9px * -2);
            top: 50%;
            transform: translateX(0) translateY(-50%);
            border-left-color: ${COLORS.Primary.darkblue};
          }
        `;
      case 'right':
        return css`
          left: calc(100% + 12px);
          top: 50%;
          transform: translateX(0) translateY(-50%);

          &::before {
            left: calc(9px * -1);
            top: 50%;
            transform: translateX(0) translateY(-50%);
            border-right-color: ${COLORS.Primary.darkblue};
          }
        `;
    }
  }}
`;
