import React, { useState, ChangeEvent } from 'react';
import { Container } from './style';
import { FieldError, RefCallBack, RegisterOptions } from 'react-hook-form';

interface IProps {
  icon: JSX.Element;
  size?: number;
  color?: string;
}

const Icon: React.FC<IProps> = ({ icon, size = 16, color = 'inherit' }): JSX.Element => {
  return (
    <Container size={size} color={color}>
      {icon}
    </Container>
  );
};

export default Icon;
