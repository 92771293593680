import { COLORS } from 'style';
import styled, { css } from 'styled-components';

export const UserDetails = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-flow: column;
`;

export const UserName = styled.div`
  font-weight: 600;
  color: ${COLORS.darkgrey} span {
    font-weight: 400;
    font-size: 12px;
  }
`;

export const Email = styled.span`
  font-size: 14px;
  color: ${COLORS.grey};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
  column-gap: 6px;
`;

export const Button = styled.button<{ isRed?: boolean }>`
  outline: 0;
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid ${COLORS.lightgrey};
  background-color: ${({ isRed }) => (isRed ? COLORS.Primary.blueTint : COLORS.Primary.blueTint)};
  cursor: pointer;
  color: ${({ isRed }) => (isRed ? COLORS.Primary.red : COLORS.Primary.darkblue)};

  &:hover {
    filter: brightness(102%);
  }
`;
