import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
// import { Container } from './style';
import { ANIMATIONS } from 'style';

interface IProps {
  width?: number;
  height?: number;
}

const Loading: React.FC<IProps> = ({ width = 150, height = 150 }): JSX.Element => {
  return (
    // <Container size={size} color={color}>
    <Player style={{ width, height }} loop autoplay src={ANIMATIONS.loading} />
    // </Container>
  );
};

export default Loading;
