import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate } from 'react-router-dom';
import { AppProvider } from './store/store';
// import { Mailto, TemporarySite, Loader } from 'app-style';
// import { H1Light } from 'components/Styled';
// import { GlobalVars } from 'helpers/globalVars';
import 'mapbox-gl/dist/mapbox-gl.css';

// auth
// import { IUser } from 'interfaces';
import AllRoutes from 'hoc/Routes';
import { TemporarySite } from 'app-style';

const App = () => {
  // tempporary block screen for developing. Will be removed in prod.
  const [access, setAccess] = useState(false);
  const [keyStr, setKeyStr] = useState('');

  const k = 'lcs2023';

  // if (window.location.hash == '#_=_') window.location.hash = '';
  const history = window.history;
  // Test for the ugliness.
  if (window.location.hash === '#_=_') {
    // Check if the browser supports history.replaceState.
    if (window.history && history.replaceState) {
      // Keep the exact URL up to the hash.
      var cleanHref = window.location.href.split('#')[0];

      // Replace the URL in the address bar without messing with the back button.
      history.replaceState(null, '', cleanHref);
    } else {
      // Well, you're on an old browser, we can get rid of the _=_ but not the #.
      window.location.hash = '';
    }
  }

  useEffect(() => {
    const hasAccess = sessionStorage.getItem('siteAccess') || '';

    if (hasAccess && JSON.parse(hasAccess)) {
      setAccess(true);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setKeyStr('');
    }, 1000);

    const handleKeyPress = (e: KeyboardEvent) => {
      const str = keyStr + e.key;

      setKeyStr(str);

      if (str === k) {
        setAccess(true);
        sessionStorage.setItem('siteAccess', 'true');
      }
    };

    document.addEventListener('keyup', handleKeyPress);

    return () => {
      document.removeEventListener('keyup', handleKeyPress);
      clearTimeout(timer);
    };
  }, [keyStr]);

  return (
    <AppProvider>
      {access ? (
        <BrowserRouter>
          <AllRoutes />
        </BrowserRouter>
      ) : (
        <TemporarySite>under udviking</TemporarySite>
      )}
    </AppProvider>
  );
};

export default App;
