import { COLORS } from 'style';
import styled from 'styled-components';

export const TemporarySite = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: ${COLORS.Primary.blue};
`;

export const Mailto = styled.a`
  color: #fff;
`;

export const Loader = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  /* position: absolute; */
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
