import { gql } from '@apollo/client';

export const ADD_USER = gql`
  mutation AddPUser($puserInput: PUserRegisterInput!) {
    registerPUser(puserInput: $puserInput)
  }
`;

export const REMOVE_PROVIDER_USER = gql`
  mutation RemoveProviderUser($userId: String!) {
    removeProviderUser(userId: $userId)
  }
`;

export const UPDATE_PUSER = gql`
  mutation UpdatePUser($puserInput: PUserUpdateInput!) {
    updatePUser(puserInput: $puserInput)
  }
`;

export const UPDATE_GHOSTPROVIDERID = gql`
  mutation UpdateGhostProviderId($id: String!) {
    updateGhostProviderId(id: $id)
  }
`;

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    fileUpload(file: $file)
  }
`;
