import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS } from 'style';

export const SearchContainer = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  position: relative;
`;

export const Providers = styled.div`
  padding: 24px;
  display: flex;
  flex: 2;
  flex-flow: column;
  background-color: #fff;
  min-height: 100%;

  .providerlist-header {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    z-index: 2;
    padding: 12px 0;
    background-color: #fff;
    border-bottom: 1px solid #ddd;

    span {
      flex: 1;
      min-width: 400px;
      font-size: 24px;
      font-weight: 600;
    }
  }
`;

export const TimeSlotControl = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;

  .date-controller {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    color: ${COLORS.Primary.darkblue};
    padding: 6px;
    height: 44px;
    width: 44px;
    cursor: pointer;
    border: none;
    border-radius: 6px;
    background: none;

    &.disabled {
      color: ${COLORS.grey};
      cursor: default;
    }

    &:not(.disabled):hover {
      background-color: ${COLORS.Primary.blueTint};
    }
  }
  .current-week {
    display: flex;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
  }
`;
export const ProvidersList = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;

  .notfound-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    color: ${COLORS.grey};
    text-align: center;

    h3 {
      color: ${COLORS.Primary.darkblue};
    }
    p,
    h3 {
      max-width: 250px;
    }
  }
`;

export const Provider = styled.article`
  border-bottom: 2px solid ${COLORS.Primary.offwhite};
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-flow: column;

  &:last-of-type {
    border-bottom: none;
  }

  .provider-content {
    display: flex;
    flex-flow: row;
  }

  .view-all-availability {
    display: flex;
    justify-content: flex-end;
    padding-top: 6px;

    button {
      background: none;
      border: none;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const ProviderImg = styled.img`
  width: 72px;
  height: 72px;
  margin-right: 24px;
  border-radius: 50%;
`;

export const ProviderInfo = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Title = styled.h3`
  color: ${COLORS.Primary.darkblue};
  font-size: 20px;
  margin: 0 0 3px 0;

  &:hover {
    color: ${COLORS.Primary.blue};
  }
`;

export const Profession = styled.h4`
  color: ${COLORS.Primary.darkblue};
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 6px 0;
  text-transform: capitalize;
`;

export const Address = styled.h5`
  display: flex;
  align-items: center;
  color: ${COLORS.grey};
  font-size: 14px;
  font-weight: 400;
  margin: 3px 0;

  .icon {
    display: flex;
    margin-right: 3px;
    justify-content: center;
    align-items: center;
    color: ${COLORS.Primary.red};
    font-size: 16px;
    font-weight: 600;
  }
`;

export const MapContainer = styled.aside`
  /* flex-basis: 33.33%; */
  display: block;
  flex: 1;
  background-color: ${COLORS.Primary.blueWhite};
`;

export const LinkWrapper = styled(Link)`
  display: flex;
  flex: 1;
  min-width: 400px;
  text-decoration: none;

  &:visited {
    text-decoration: none;
    color: inherit;
  }
`;

export const Loader = styled.div`
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
`;
