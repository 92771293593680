import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Footer from '../../../components/Footer';
import Toolbar from '../../../components/Toolbar';
import { Main } from './style';

const MainLayout: React.FC = (): JSX.Element => {
  const { pathname } = useLocation();
  return (
    <>
      <Toolbar />
      <Main isLanding={pathname === '/'}>
        <Outlet />
      </Main>
      <Footer />
    </>
  );
};

export default MainLayout;
