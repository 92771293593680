import { COLORS } from 'style';
import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: ${COLORS.Primary.darkblue};
`;

export const Container = styled.div`
  background: #fff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin-top: -5%;
  padding: 48px;
  border: 1px solid ${COLORS.lightgrey};
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  row-gap: 48px;

  .header {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  form {
    display: flex;
    width: 100%;
    flex-flow: column;
    row-gap: 12px;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
`;

export const Img = styled.img`
  height: 98px;
  width: auto;
`;
