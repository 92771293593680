import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Content, Text } from '../style';
import Button from 'components/ui/Button';
import { Input } from 'components/ui/Input';
import ResetPasswordIcon from '../../../assets/images/reset-password-icon.svg';
import { REQUEST_PASSWORDRESET } from 'graphql/mutations/auth';
import { useMutation } from '@apollo/client';
import { Loading } from 'components/ui/Animiations';

interface IProps {
  onSuccess(): void;
  // setLoading(loading: boolean): void;
  // setError(error: string): void;
}

const ResetPasswordForm: React.FC<IProps> = ({ onSuccess }): JSX.Element => {
  const [requestPasswordReset, { loading, error }] = useMutation(REQUEST_PASSWORDRESET, {
    onCompleted: () => {
      onSuccess();
    },
  });

  // useEffect(() => {
  //   if (loading) {
  //     setLoading(true);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [loading]);

  // useEffect(() => {
  //   if (error) {
  //     setLoading(false);
  //     setError(error.message);
  //   } else {
  //     setError('');
  //   }
  // }, [error]);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const onResetPassword = (data: { email: String }) => {
    // setLoginState('login');
    requestPasswordReset({ variables: data });
  };

  if (error) {
    // return success regardless if user exists or not.
    onSuccess();
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Content>
        <img src={ResetPasswordIcon} height={96} width={96} />
        <Text>Tag det roligt! Indtast din email. Vi sender dig et link, så du kan få et nyt kodeord.</Text>
      </Content>
      <form onSubmit={handleSubmit(onResetPassword)}>
        <Input
          name='email'
          label='Email'
          id='email-reset-input'
          type='email'
          inputType='form'
          error={errors.email}
          register={register}
          regOptions={{
            validate: {
              matchPattern: (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email er ikke korrekt',
            },
          }}
          marginBottom
        />
        <Button label='Send' type='submit' marginTop={24} flex />
      </form>
    </>
  );
};

export default ResetPasswordForm;
