import React from 'react';
import { SContainer, SectionContent } from 'containers/Home/style';
import { Title, Subtitle, ContentWrapper, WavesSVG } from './style';
import landingLottie from 'assets/lotties/landingLottie.json';
import { Player } from '@lottiefiles/react-lottie-player';
import Button from 'components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { ISpecialization } from 'interfaces';
import AnimateWords from './animateWords';
import { COLORS } from 'style';

interface IProps {
  specializations?: ISpecialization[];
}

const Landing: React.FC<IProps> = ({ specializations = [] }) => {
  const navigate = useNavigate();

  return (
    <SContainer bgColor='transparent'>
      <SectionContent>
        <ContentWrapper>
          <div className='cta-content'>
            <Title>
              Find din lokale
              {specializations.length > 0 ? (
                <AnimateWords words={specializations.map((el) => el.name.toLowerCase())} />
              ) : (
                <span style={{ color: COLORS.Primary.blue }}> kiropraktor</span>
              )}
            </Title>
            <Subtitle>
              Rivevo gør det nemt for dig og dine nærmeste, at finde lige præcis den behandler der passer dig!
            </Subtitle>
            <Button gradient marginTop={6} label='Find behandler' onClick={() => navigate('/search')} />
          </div>
          <div className='illustration'>
            <Player autoplay loop src={landingLottie} style={{ height: '500px', width: '500px' }}></Player>
          </div>
        </ContentWrapper>
      </SectionContent>
      <WavesSVG xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
        <path
          fill='#ffffff'
          fillOpacity='1'
          d='M0,64L24,64C48,64,96,64,144,101.3C192,139,240,213,288,213.3C336,213,384,139,432,122.7C480,107,528,149,576,176C624,203,672,213,720,192C768,171,816,117,864,133.3C912,149,960,235,1008,240C1056,245,1104,171,1152,154.7C1200,139,1248,181,1296,202.7C1344,224,1392,224,1416,224L1440,224L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z'
        ></path>
      </WavesSVG>
    </SContainer>
  );
};

export default Landing;
