import React, { ChangeEvent, useEffect, useState } from 'react';
import { ModalContent } from 'components/Modal/style';
import Modal from 'components/Modal';
import { IBooking, IBookingInput, IPatient, IProduct, IProviderAdmin, IProviderLocation, IUser } from 'interfaces';
import { H3 } from 'components/Styled';
import { format, addMinutes } from 'date-fns';
import { ButtonsContainer, Datarow, Icon, Select } from './style';
import { IoCalendarOutline, IoLocationOutline, IoPersonOutline, IoCubeOutline } from 'react-icons/io5';
import Button from 'components/ui/Button';
import PatientSearch from 'components/admin/PatientSearch';
import { ClipLoader } from 'react-spinners';
import { ADD_BOOKING } from 'graphql/mutations';
import { GET_BOOKINGS, GET_ME } from 'graphql/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { COLORS } from 'style';

interface IProps {
  toggle(modal: string): void;
  onConfirm(booking: IBooking): void;
  isOpen: boolean;
  booking: IBooking;
  provider?: IProviderAdmin;
}

const BookingModal: React.FC<IProps> = ({ toggle, onConfirm, isOpen, booking, provider }): JSX.Element => {
  // const newBooking = { ...booking };
  const [newBooking, setNewBooking] = useState<IBooking | null>(null);
  const [addBooking] = useMutation(ADD_BOOKING, {
    refetchQueries: [{ query: GET_BOOKINGS }],
    onCompleted: () => toggle(''),
  });
  const [getMe, { data, loading }] = useLazyQuery<{ me: IUser }>(GET_ME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (provider && newBooking) {
        const user = provider.users.find((el) => el._id === data.me._id);
        if (user) {
          newBooking.user = user;

          setNewBooking({ ...newBooking });
        }
      }
    },
  });

  useEffect(() => {
    setNewBooking(!booking._id ? { ...booking } : null);

    // only fetch me, if newBooking is set
    getMe();
  }, [booking]);

  const onChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    if (newBooking && provider) {
      const { products, locations, users } = provider;

      if (products.length > 0 && e.target.name === 'products') {
        newBooking.product = products.find((el) => el._id === e.target.value) as IProduct;
      }

      if (locations.length > 0 && e.target.name === 'locations') {
        const location = locations.find((el) => el._id === e.target.value) as IProviderLocation;
        const { _id, street, city, postcode } = location;

        newBooking.location = {
          _id,
          street,
          city,
          postcode,
        };
      }

      if (users.length > 0 && e.target.name === 'users') {
        newBooking.user = users.find((el) => el._id === e.target.value) as IUser;
      }

      setNewBooking({ ...newBooking });
    }
  };

  const handleSelectPatient = (patient: IPatient | null) => {
    setNewBooking({ ...newBooking, patient: patient } as IBooking);
  };

  const onCreateBooking = () => {
    if (newBooking && provider) {
      newBooking.providerId = provider._id;
      newBooking.toDate = addMinutes(newBooking.fromDate, newBooking.product.durationInMinutes);

      const bookingInput: IBookingInput = {
        providerId: newBooking.providerId,
        patient: newBooking.patient._id,
        user: newBooking.user._id,
        product: newBooking.product._id,
        location: newBooking.location._id,
        room: newBooking.room._id,
        fromDate: newBooking.fromDate,
        toDate: newBooking.toDate,
      };

      addBooking({ variables: { bookingInput } });
      // onConfirm(newBooking);
    }
  };

  if (!booking._id && loading) return <ClipLoader color={COLORS.Primary.darkblue} loading={loading} size={48} />;

  return (
    <Modal toggle={toggle} isOpen={isOpen} title={booking._id ? 'Booking' : 'Opret ny booking'}>
      {booking._id && (
        <ModalContent>
          <Datarow>
            <Icon>
              <IoPersonOutline />
            </Icon>
            <div>
              <H3>{`${booking.patient.name} ${booking.patient.lastName}`}</H3>
            </div>
          </Datarow>
          <Datarow>
            <Icon>
              <IoCalendarOutline />
            </Icon>
            <div>
              <H3>{`${format(new Date(booking.fromDate), 'HH:mm')} - ${format(new Date(booking.toDate), 'HH:mm')}`}</H3>
              <span>{format(new Date(booking.fromDate), 'dd MMMM, yyyy')}</span>
            </div>
          </Datarow>
          <Datarow>
            <Icon>
              <IoLocationOutline />
            </Icon>
            <div>
              <H3>{`${booking.location.street}, ${booking.location.city}`}</H3>
              <span>{booking.room.name}</span>
            </div>
          </Datarow>
          <ButtonsContainer>
            <Button
              label={booking.patient.journal ? 'Se journal' : 'Opret journal'}
              onClick={() => onConfirm(booking)}
            />
          </ButtonsContainer>
        </ModalContent>
      )}
      {newBooking && newBooking.fromDate && provider && (
        <ModalContent>
          <Datarow>
            <Icon>
              <IoPersonOutline />
            </Icon>
            {!newBooking.patient ? (
              <PatientSearch onSelectPatient={handleSelectPatient} />
            ) : (
              <div className='patient-name'>
                <H3>{`${newBooking.patient.name} ${newBooking.patient.lastName}`}</H3>
                <span onClick={() => handleSelectPatient(null)}>Fjern patient</span>
              </div>
            )}
          </Datarow>
          <Datarow>
            <Icon>
              <IoPersonOutline />
            </Icon>
            <Select>
              <select
                name='users'
                onChange={onChangeHandler}
                defaultValue={(newBooking.user && newBooking.user._id) || provider.users[0]._id}
              >
                {provider.users.map((user: IUser, i: number) => (
                  <option key={i} value={user._id}>
                    {user.name} {user.lastName}
                  </option>
                ))}
              </select>
            </Select>
          </Datarow>
          <Datarow>
            <Icon>
              <IoCubeOutline />
            </Icon>
            <Select>
              <select name='products' onChange={onChangeHandler} defaultValue='default'>
                <option key='default' value='default' hidden>
                  Vælg produkt...
                </option>
                {provider.products.map((product: IProduct, i: number) => (
                  <option key={i} value={product._id}>
                    {product.name}
                  </option>
                ))}
              </select>
            </Select>
          </Datarow>
          <Datarow>
            <Icon>
              <IoLocationOutline />
            </Icon>
            <Select>
              <select name='locations' onChange={onChangeHandler} defaultValue='default'>
                <option key='default' value='default' hidden>
                  Vælg adresse...
                </option>
                {provider.locations.map((location: IProviderLocation, i: number) => (
                  <option key={i} value={location._id}>
                    {location.street}
                  </option>
                ))}
              </select>
            </Select>
          </Datarow>
          <Datarow>
            <Icon>
              <IoCalendarOutline />
            </Icon>
            <div>
              <H3>
                {`${format(new Date(newBooking.fromDate), 'HH:mm')} - ${
                  newBooking.product
                    ? format(addMinutes(newBooking.fromDate, newBooking.product.durationInMinutes), 'HH:mm')
                    : 'Vælg produkt'
                }`}
              </H3>
              <span>{format(new Date(newBooking.fromDate), 'dd MMMM, yyyy')}</span>
            </div>
          </Datarow>
          <ButtonsContainer>
            <Button label='Opret booking' onClick={onCreateBooking} />
          </ButtonsContainer>
        </ModalContent>
      )}
    </Modal>
  );
};

export default BookingModal;
