import React, { ChangeEvent, useEffect, useState } from 'react';
import { ModalContent, CTAFooter, Text, OTPContainer, SuccessContainer, Row } from './style';
import Modal from '.';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'components/ui/Input';
import Button from 'components/ui/Button';
import { IOTPInput, ISignUpForm, IUser } from 'interfaces';
import { socialLogin } from './social-helpers';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_ME } from 'graphql/queries';
import { Loader } from 'containers/Search/style';
import { SIGNUP, VERIFY_OTP } from 'graphql/mutations';
import OTP from 'components/ui/OTP';
import { H2 } from 'components/Styled';
import { ANIMATIONS } from 'style';
import { Loading, Success } from 'components/ui/Animiations';

interface IProps {
  toggle(modal: string): void;
  isOpen: boolean;
}

const NewUserModal: React.FC<IProps> = ({ toggle, isOpen }): JSX.Element => {
  const [signUpWithEmail, setSignUpWithEmail] = useState(false);
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [isVerifiedOTP, setIsVerifiedOTP] = useState<boolean>(false);
  const [OTPCode, setOTPCode] = useState<string>('');
  const [getMe, { data: userData }] = useLazyQuery<{ me: IUser }>(GET_ME);
  const [signup, { data: signupData, loading }] = useMutation(SIGNUP);
  const [verifyOTP, { data: verifyData, loading: verifying, error: verifyError }] = useMutation(VERIFY_OTP, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    getValues,
    reset,
  } = useForm<ISignUpForm>({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      name: '',
      lastName: '',
      password: '',
      confirmPassword: '',
    },
  });

  const facebookLoginHandler = async () => {
    setLoggingIn(true);
    socialLogin('facebook').then(async () => {
      await getMe();
      if (userData) {
        setLoggingIn(true);
        toggle('');
        navigate(-2);
      }
    });
  };

  const onSubmit = ({ email, password, confirmPassword, name, lastName }: ISignUpForm): void => {
    if (confirmPassword === password) {
      const createUserInput = {
        email,
        name,
        lastName,
        password,
      };
      signup({ variables: { createUserInput } });
    } else {
      alert('Password do not match');
    }
  };

  useEffect(() => {
    if (OTPCode.length === 6) {
      if (userId) {
        const otpinput: IOTPInput = {
          userId,
          code: OTPCode,
        };
        verifyOTP({ variables: { otpinput } });
      }
    }
  }, [OTPCode]);

  useEffect(() => {
    if (!isOpen) {
      setSignUpWithEmail(false);
      reset({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (signupData) {
      const userId: string = signupData.signup._id;
      if (userId) setUserId(userId);
    }

    if (verifyData) {
      setIsVerifiedOTP(true);
      // close modal and reset on otp input accept
      setTimeout(() => {
        toggle('');
        setUserId('');
        setOTPCode('');
        setSignUpWithEmail(false);
        setIsVerifiedOTP(false);
      }, 10000);
    }
  }, [signupData, verifyData]);

  const onOTPChange = (otp: string) => {
    setOTPCode(otp);
  };

  const renderModalContent = () => {
    if (signUpWithEmail) {
      if (userId) {
        if (isVerifiedOTP && !verifyError) {
          return (
            <SuccessContainer>
              <Success />
              <H2>SUCCESS!</H2>
              <p>Din konto er aktiv og du kan nu logge ind.</p>
            </SuccessContainer>
          );
        }
        return (
          <OTPContainer>
            <Text>
              Indtast koden vi har sendt til{' '}
              <span>{signupData ? signupData.signup.email : 'cnps.bjjfan@gmail.com'}</span>.
            </Text>
            <OTP numInputs={6} onChange={onOTPChange} value={OTPCode} hasError={verifyError} />
            <Text>Har du ikke fået en kode?</Text>
            <button>Gensend</button>
          </OTPContainer>
        );
      }

      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name='email'
            label='Email'
            id='email-input'
            type='text'
            inputType='form'
            error={errors.email}
            register={register}
            regOptions={{
              validate: {
                matchPattern: (v) => {
                  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) return true;

                  return 'Email er ikke korrekt';
                },
              },
              required: 'Dette felt er påkrævet',
            }}
            marginBottom
          />
          <Row>
            <Input
              name='name'
              label='Navn'
              id='name-input'
              type='text'
              flex
              inputType='form'
              error={errors.name}
              register={register}
              regOptions={{
                validate: {
                  matchPattern: (v) => {
                    if (/^[a-z ,.'-]+$/i.test(v)) return true;

                    return 'Navn indeholder forkerte tegn';
                  },
                },
                required: 'Dette felt er påkrævet',
              }}
              marginBottom
            />
            <Input
              name='lastName'
              label='Efternavn'
              id='lastname-input'
              type='text'
              flex
              inputType='form'
              error={errors.lastName}
              register={register}
              regOptions={{
                validate: {
                  matchPattern: (v) => {
                    if (/^[a-z ,.'-]+$/i.test(v)) return true;

                    return 'Efternavn indeholder forkerte tegn';
                  },
                },
                required: 'Dette felt er påkrævet',
              }}
              marginBottom
            />
          </Row>
          <Input
            name='password'
            label='Kodeord'
            id='lastname-input'
            type='password'
            inputType='form'
            error={errors.password}
            register={register}
            regOptions={{
              validate: (v) => v.length >= 6 || 'Kodeord skal være minimum 6 karakterer',
              required: 'Dette felt er påkrævet',
            }}
            marginBottom
          />
          <Input
            name='confirmPassword'
            label='Bekræft Kodeord'
            id='confirm-password-input'
            type='password'
            inputType='form'
            error={errors.confirmPassword}
            register={register}
            regOptions={{
              validate: (v) => v === watch('password') || 'Kodeord matcher ikke',
              required: 'Dette felt er påkrævet',
            }}
            marginBottom
          />
          <Button label='Opret' type='submit' flex marginBottom={6} />
        </form>

        //   <Controller
        //     control={control}
        //     name='name'
        //     defaultValue=''
        //     render={({ field: { onChange, value, name, ref } }) => (
        //       // { invalid, isTouched, isDirty }
        //       <Input
        //         id={name}
        //         label='Name'
        //         value={value}
        //         onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        //         type='text'
        //         inputRef={ref}
        //       />
        //     )}
        //   />

        //   <Controller
        //     control={control}
        //     name='lastName'
        //     defaultValue=''
        //     render={({ field: { onChange, value, name, ref } }) => (
        //       // { invalid, isTouched, isDirty }
        //       <Input
        //         id={name}
        //         label='Last Name'
        //         value={value}
        //         onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        //         type='text'
        //         inputRef={ref}
        //       />
        //     )}
        //   />

        //   <Controller
        //     control={control}
        //     name='password'
        //     defaultValue=''
        //     render={({ field: { onChange, value, name, ref } }) => (
        //       // { invalid, isTouched, isDirty }
        //       <Input
        //         id={name}
        //         label='Password'
        //         value={value}
        //         onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        //         type='password'
        //         inputRef={ref}
        //       />
        //     )}
        //   />

        //   <Controller
        //     control={control}
        //     name='confirmPassword'
        //     defaultValue=''
        //     render={({ field: { onChange, value, name, ref } }) => (
        //       <Input
        //         id={name}
        //         label='Confirm Password'
        //         value={value}
        //         onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        //         type='password'
        //         inputRef={ref}
        //       />
        //     )}
        //   />

        //   <Button label='Opret' type='submit' flex marginBottom={6} />
      );
    } else {
      return (
        <>
          <div>
            <Button some='facebook' onClick={facebookLoginHandler} flex marginBottom={6} />
            <Button some='google' onClick={() => {}} flex marginBottom={6} />
            <Button some='amazon' onClick={() => {}} flex marginBottom={6} />
            <Button some='email' onClick={() => setSignUpWithEmail(true)} flex marginBottom={6} />
          </div>
          <CTAFooter>
            <span>Har du allerede en konto?</span>
            <button className='ctaButton' type='button' onClick={() => toggle('login')}>
              Log ind
            </button>
          </CTAFooter>
        </>
      );
    }
  };

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      title={userId && signUpWithEmail ? 'Bekræft din emailadresse' : 'Opret bruger'}
    >
      <ModalContent>{loggingIn || loading || verifying ? <Loading /> : renderModalContent()}</ModalContent>
    </Modal>
  );
};

export default NewUserModal;
