import { SSubtitle } from 'containers/Home/style';
import { motion } from 'framer-motion';
import { COLORS } from 'style';
import styled from 'styled-components';

export const Title = styled.h1`
  color: ${COLORS.Primary.darkblue};
  font-size: 48px;
  font-weight: 800;
  margin: 0;
  letter-spacing: -0.02em;
`;

export const Subtitle = styled.p`
  color: ${COLORS.bluegrey};
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  .cta-content {
    max-width: 600px;
  }
`;

export const WavesSVG = styled.svg`
  margin-bottom: -90px;
  margin-top: -90px;
`;

export const WordSpan = styled(motion.span)`
  position: absolute;
  color: ${COLORS.Primary.blue};
  padding-left: 11px;
`;
