import { gql } from '@apollo/client';

export const ADD_REVIEW = gql`
  mutation AddReview($review: CreateReviewInput!) {
    addReview(review: $review) {
      _id
    }
  }
`;

export const DELETE_REVIEW = gql`
  mutation DeleteReview($reviewId: String!) {
    deleteReview(reviewId: $reviewId)
  }
`;

export const TOGGLE_VOTE = gql`
  mutation ToggleVote($reviewId: String!) {
    upvote(reviewId: $reviewId)
  }
`;
