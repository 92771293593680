import styled from 'styled-components';
import { IoStar, IoStarHalfOutline, IoStarOutline } from 'react-icons/io5';

export const FullStar = styled(IoStar)`
  color: orange;
  font-size: 48px;
`;

export const HalfStar = styled(IoStarHalfOutline)`
  color: orange;
  font-size: 48px;
`;

export const EmptyStar = styled(IoStarOutline)`
  color: orange;
  font-size: 48px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  justify-content: center;
  margin: 24px 0;
`;
