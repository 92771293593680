import React from 'react';
import { Item, NavigationLink } from './style';

interface IProps {
  link: string;
  onClick?(): void;
  children: React.ReactNode;
  icon?: JSX.Element;
  exact?: boolean;
}

const NavItem: React.FC<IProps> = ({ link, onClick, children, icon, exact }): JSX.Element => {
  return (
    <Item>
      <NavigationLink to={link} end={exact}>
        <span className='navitem-wrapper'>
          {icon && <span className='navitem-icon'>{icon}</span>}
          <span className='navitem-text'>{children}</span>
        </span>
      </NavigationLink>
    </Item>
  );
};

export default NavItem;
