import { gql } from '@apollo/client';
import { CORE_PRODUCT_FIELDS, CORE_PROVIDER_USER_FIELDS } from 'graphql/fragments';

export const GET_BOOKINGS = gql`
  ${CORE_PROVIDER_USER_FIELDS}
  ${CORE_PRODUCT_FIELDS}
  query GetBookings {
    bookings {
      _id
      providerId
      fromDate
      toDate
      hasAcceptedTerms
      notesToProvider
      patient {
        _id
        name
        lastName
        email
        phone
        # journal {
        # 	_id
        # }
      }
      providerUser {
        ...CoreProviderUserFields
      }
      location {
        _id
        street
        city
        postcode
      }
      room {
        _id
        name
      }
      product {
        ...CoreProductFields
      }
    }
  }
`;
