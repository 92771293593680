import React from 'react';
import { TableContainer } from 'components/Styled';
import { IProduct, IUser } from 'interfaces';
import { Column, useTable } from 'react-table';
import { useEffect } from 'react';
import { useState } from 'react';
import { IoEllipsisHorizontalOutline, IoPencil, IoTrashOutline } from 'react-icons/io5';
import { Badge, ProductName, Buttons, Button } from './style';

interface IProps {
  products: IProduct[];
  handleOnSelectProduct(product: IProduct, action: string): void;
}

const ProductsTable: React.FC<IProps> = ({ products, handleOnSelectProduct }): JSX.Element => {
  const [data, setData] = useState<IProduct[]>(products);
  //   const data = React.useMemo(() => journals, []);

  useEffect(() => {
    setData(products);
  }, [products]);

  const columns = React.useMemo<Column<IProduct>[]>(
    () => [
      {
        Header: 'Navn',
        accessor: (product) => (
          <ProductName>
            <span>{product.name}</span>
            {product.isDefault && <Badge>Default</Badge>}
          </ProductName>
        ),
      },
      {
        Header: 'Varighed',
        accessor: (product) => `${product.durationInMinutes} minutter`,
      },
      {
        Header: 'Pris',
        accessor: (product) => `${product.price} DKK`,
      },
      {
        Header: 'Status',
        accessor: (product) => (product.isActive ? 'Aktiv' : 'Suspenderet'),
      },
      {
        Header: 'Actions',
        accessor: (product) => (
          <Buttons>
            <Button onClick={() => handleOnSelectProduct(product, 'edit')}>
              <IoPencil />
            </Button>
            <Button onClick={() => handleOnSelectProduct(product, 'delete')}>
              <IoTrashOutline />
            </Button>
          </Buttons>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <TableContainer>
      <table {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()}>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </TableContainer>
  );
};

export default ProductsTable;
