import React, { ChangeEvent, useEffect, useRef, KeyboardEvent, ClipboardEvent, FocusEvent } from 'react';
import { Container } from './style';

interface IProps {
  value: string;
  focus: boolean;
  index: number;
  shouldAutoFocus: boolean;
  isDisabled: boolean;
  hasError: boolean;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  onInput(e: ChangeEvent<HTMLInputElement>): void;
  onKeyDown(e: KeyboardEvent<HTMLInputElement>): void;
  onPaste(e: ClipboardEvent<HTMLInputElement>): void;
  onFocus(e: FocusEvent<HTMLInputElement>): void;
  onBlur(): void;
}

const SingleOTPInput: React.FC<IProps> = ({
  value,
  index,
  focus,
  isDisabled = false,
  shouldAutoFocus = false,
  hasError,
  ...rest
}): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Focus on first render
    // Only when shouldAutoFocus is true
    if (inputRef.current && focus && shouldAutoFocus) {
      inputRef.current.focus();
    }

    // Check if focusedInput changed
    // Prevent calling function if input already in focus
    if (inputRef && inputRef.current && focus) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [focus, shouldAutoFocus]);

  return (
    <Container isFocused={focus} hasError={hasError}>
      <input
        maxLength={1}
        type={'tel'}
        ref={inputRef}
        value={value ? value : ''}
        autoComplete='off'
        disabled={isDisabled}
        {...rest}

        // aria-label={`${index === 0 ? 'Please enter verification code. ' : ''}${isInputNum ? 'Digit' : 'Character'} ${
        //   index + 1
        // }`}
      ></input>
    </Container>
  );
};

export default SingleOTPInput;
