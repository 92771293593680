import { COLORS } from 'style';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  background-color: ${COLORS.Primary.darkblue};
  border-top: 1px solid ${COLORS.Primary.blue};
`;

export const TopSection = styled.section`
  display: flex;
  flex-flow: row;
  padding: 24px 48px;
`;

export const FooterColumn = styled.div`
  margin-right: 48px;
`;

export const ColumnTitle = styled.h3`
  font-size: 18px;
  margin: 6px 0;
  color: ${COLORS.Primary.lightblue};
  font-weight: 700;
`;

export const ColumnLinks = styled.div`
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      a {
        display: inline-block;
        color: ${COLORS.white};
        font-weight: 500;
        text-decoration: none;
        margin: 6px 0;
      }
    }
  }
`;

export const BottomSection = styled.section`
  padding: 24px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLORS.white};

  .footer-links {
    .dot {
      margin: 0 6px;
    }
    span {
      a {
        text-decoration: none;
        color: ${COLORS.Primary.lightblue};

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-links {
    display: flex;
  }
`;

export const SocialIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-left: 3px;
  color: ${COLORS.Primary.lightblue};
  cursor: pointer;
  padding: 6px;
`;
