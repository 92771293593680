import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { IoArrowBackOutline, IoCloseOutline } from 'react-icons/io5';
import {
  Overlay,
  ModalContainer,
  modalVariant,
  containerVariant,
  CloseButton,
  Header,
  Footer,
  BackButton,
} from './style';
import { H2 } from 'components/Styled';

interface IProps {
  toggle(modal: string): void;
  isOpen: boolean;
  backButton?: () => void | null;
  title?: string;
  children: React.ReactNode;
  stretch?: boolean;
}

const Modal: React.FC<IProps> = ({
  toggle,
  backButton,
  title,
  children,
  isOpen,
  stretch = false,
}): React.ReactPortal => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    isOpen && (document.body.style.overflow = 'hidden');
    !isOpen && (document.body.style.overflow = 'unset');
    setShowModal(isOpen);
  }, [isOpen]);

  return createPortal(
    <AnimatePresence mode='wait'>
      {showModal && (
        <Overlay
          initial={'initial'}
          animate={'isOpen'}
          exit={'exit'}
          variants={modalVariant}
          onMouseDown={(e) => {
            // a little hacky fix, should probably make it cleaner
            e.stopPropagation();
            if (e.target === e.currentTarget) {
              toggle('');
            }
          }}
        >
          <ModalContainer
            variants={{
              initial: { top: '-50%', transition: { type: 'spring' } },
              isOpen: { top: stretch ? '0' : '40%' },
              exit: { top: '-50%' },
            }}
            stretch={stretch ? 'stretch' : ''}
          >
            <Header hasTitle={Boolean(title)} marginLeft={backButton ? false : true}>
              {backButton && (
                <BackButton onClick={backButton}>
                  <IoArrowBackOutline />
                </BackButton>
              )}
              {title && <H2 noMargin>{title}</H2>}
              <CloseButton onClick={() => toggle('')}>
                <IoCloseOutline />
              </CloseButton>
            </Header>
            {children}
            <Footer />
          </ModalContainer>
        </Overlay>
      )}
    </AnimatePresence>,
    document.querySelector('#modal') as Element
  );
};

export default Modal;
