import React, { ChangeEvent, LegacyRef, MutableRefObject, RefObject, forwardRef, useRef, useState } from 'react';
import { FieldError, RegisterOptions, UseFormRegisterReturn } from 'react-hook-form';
import ReactQuill, { ReactQuillProps, Value } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IProps {
  // label: string;
  // inputName: string;
  // id: string;
  // error?: FieldError | undefined;
  // value?: Value;
  // setValue?: (value: string) => void;
  onChange?: (value: string) => void;
  // register?: (name: any, options?: RegisterOptions) => UseFormRegisterReturn; // even name is a string, UseFormRegisterReturn expects the infered string literal.
  // regOptions?: RegisterOptions;
  // ref: LegacyRef<ReactQuill> | undefined;
  // ref: RefObject<any>;
}
export type QuillRefType = ReactQuillProps;

const QuillInput: React.FC<IProps> = ({ onChange }) => {
  // const quill = register && register(inputName);
  //   // Editor state
  // const [value, setValue] = useState("");

  // // Editor ref
  // const quill = useRef();  $

  // const defaultValueRef = useRef(defaultValue);
  // const onTextChangeRef = useRef(onTextChange);
  // const onSelectionChangeRef = useRef(onSelectionChange);

  return (
    <ReactQuill
      theme='snow'
      // value={value}
      // {...(setValue && { onChange: setValue, value })}
      // {...(register && register(quill?.name, regOptions ? regOptions : { required: 'Dette input kræver tekst' }))}
      // onChange={setValue ? setValue : quill?.onChange}
      // onBlur={value || quill?.onBlur}
      onChange={onChange}
      // style={{ maxWidth: 800 }}
    />
  );
};

export default QuillInput;
