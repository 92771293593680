import styled from 'styled-components';
// import { motion } from 'framer-motion';
import { COLORS } from 'style';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  textarea {
    width: 100%;
    resize: none;
    border: 1px solid ${COLORS.lightgrey};
    border-radius: 6px;
    outline: none;
    background: ${COLORS.Primary.blueWhite};
    padding: 24px;
    box-sizing: border-box;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-flow: row;
`;
