import React, { ChangeEvent, useState } from 'react';
import { IProvider, IProviderAvailability } from 'interfaces';
import { InfoContainer, Info, SelectOptions, ProviderAddress, ProviderName, ProviderImage, InfoHeader } from './style';
import { H2, H3 } from 'components/Styled';
import Select from 'components/ui/Select';
import { IBookingOptions } from '.';

interface IProps {
  provider: IProvider;
  showOptions: boolean;
  setSelectedOptions: (opts: IBookingOptions) => void;
  selectedOptions: IBookingOptions;
  isSticky?: boolean;
}

const CalendarInfo: React.FC<IProps> = ({
  provider,
  showOptions,
  setSelectedOptions,
  selectedOptions,
  isSticky = false,
}): JSX.Element => {
  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.id;
    const value = e.target.value;

    const newOpts = { ...selectedOptions };

    if (id === 'select-user') {
      newOpts.puser = value;
    }

    if (id === 'select-product') {
      newOpts.product = value;
    }

    if (id === 'select-location') {
      newOpts.location = value;
    }

    setSelectedOptions({
      ...newOpts,
    });
  };

  return (
    <InfoContainer isSticky={isSticky}>
      <Info>
        <InfoHeader>
          <ProviderImage>
            <img src={`/__MOCKDATA__/profileImages/${provider.img}`} alt='provider image' />
          </ProviderImage>
          <div className='info-text'>
            <H3 noMargin>
              <ProviderName href={`/${provider.specialization.name}/${provider._id}`}>{provider.name}</ProviderName>
              <ProviderAddress>{`${provider.locations[0].street}, ${provider.locations[0].city}`}</ProviderAddress>
            </H3>
          </div>
        </InfoHeader>
      </Info>
      {showOptions && (
        <SelectOptions>
          <Select
            label='Behandler'
            id='select-user'
            onChange={onSelectChange}
            value={selectedOptions.puser || ''}
            options={provider.users.map((user) => ({ value: user._id, label: `${user.name} ${user.lastName}` }))}
          />

          <Select
            label='Behandling'
            id='select-product'
            onChange={onSelectChange}
            value={selectedOptions.product || ''}
            options={provider.products
              .filter((opt) => !opt.isPrivate)
              .map((opt) => ({ value: opt._id, label: `${opt.name}` }))}
          />
        </SelectOptions>
      )}
    </InfoContainer>
  );
};

export default CalendarInfo;
