import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/ui/Button';
import { Container, Block, Row } from 'containers/admin/style';
import { ADD_JOURNAL } from 'graphql/mutations';
import { GET_PROVIDER_JOURNAL_TEMPLATE } from 'graphql/queries';
import { IJournalEntry, IPatient, IProviderAdmin } from 'interfaces';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Templates } from './style';

interface IProps {
  cancel(): void;
}

interface INewJournal {
  patient: Partial<IPatient>;
  journalEntries: IJournalEntry[];
}

interface IProviderAdminData {
  providerAdmin: IProviderAdmin;
}

const NewJournal: React.FC<IProps> = ({ cancel }) => {
  const navigate = useNavigate();
  const [newJournal, setNewJournal] = useState<INewJournal>({
    patient: {
      name: '',
      lastName: '',
      phone: '',
      email: '',
      cpr: '',
    },
    journalEntries: [],
  });

  const { data } = useQuery<IProviderAdminData>(GET_PROVIDER_JOURNAL_TEMPLATE);
  const [addJournal] = useMutation(ADD_JOURNAL, {
    variables: { journal: newJournal },
    onCompleted: (journalData) => {
      navigate(`/admin/journals/${journalData.addJournal._id}`);
      cancel();
    },
  });

  const { journalTemplate = [] } = data?.providerAdmin?.settings || {};

  useEffect(() => {
    if (data) {
      newJournal.journalEntries = journalTemplate.map((input) => ({
        label: input.label,
        type: input.type,
        value: '',
      })) as IJournalEntry[];
      setNewJournal((newJournal) => ({ ...newJournal }));
    }
  }, [data, journalTemplate]);

  const onChangeTextareaHandler = (e: ChangeEvent<HTMLTextAreaElement>, index: number) => {
    if (newJournal) {
      newJournal.journalEntries[index].value = e.target.value;
      setNewJournal({ ...newJournal });
    }
  };

  const onChangeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (newJournal) {
      const { name, value } = e.target;

      setNewJournal({
        ...newJournal,
        patient: {
          ...newJournal.patient,
          [name]: value,
        },
      });
    }
  };

  return (
    <Container>
      {newJournal && (
        <Block>
          <Row>
            <div>
              <Input>
                <label>Navn</label>
                <input name='name' type='text' onChange={onChangeInputHandler} value={newJournal.patient.name} />
              </Input>
              <Input>
                <label>Efternavn</label>
                <input
                  name='lastName'
                  type='text'
                  onChange={onChangeInputHandler}
                  value={newJournal.patient.lastName || ''}
                />
              </Input>
            </div>
            <div>
              <Input>
                <label>Email</label>
                <input
                  name='email'
                  type='text'
                  onChange={onChangeInputHandler}
                  value={newJournal.patient.email || ''}
                />
              </Input>
              <Input>
                <label>Telefon</label>
                <input
                  name='phone'
                  type='text'
                  onChange={onChangeInputHandler}
                  value={newJournal.patient.phone || ''}
                />
              </Input>
            </div>
            <div>
              <Input>
                <label>CPR Nummer</label>
                <input name='cpr' type='text' onChange={onChangeInputHandler} value={newJournal.patient.cpr || ''} />
              </Input>
            </div>
          </Row>
          <Row>
            <Templates>
              {newJournal.journalEntries.map((input, inputIdx) => (
                <div key={inputIdx}>
                  <label>{input.label}</label>
                  <textarea
                    name={input.label}
                    value={input.value}
                    onChange={(e) => onChangeTextareaHandler(e, inputIdx)}
                    rows={10}
                  ></textarea>
                </div>
              ))}
            </Templates>
          </Row>
        </Block>
      )}
      <Row>
        <Button onClick={cancel} label='Annullér' variant='hollow' />
        <Button onClick={addJournal} label='Opret' />
      </Row>
    </Container>
  );
};

export default NewJournal;
