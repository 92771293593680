import React, { ChangeEvent, useEffect, useState } from 'react';
import { ModalContent } from 'components/Modal/style';
import Modal from 'components/Modal';
import { IProduct, IUser } from 'interfaces';
import { ButtonsContainer, FormContent, Row } from './style';
import Button from 'components/ui/Button';
import { Loading, Success } from 'components/ui/Animiations';
import { COLORS } from 'style';
import { Text } from 'components/Styled';
import { IoPersonRemoveOutline } from 'react-icons/io5';
import { Input } from 'components/ui/Input';
import { useMutation } from '@apollo/client';
import { REMOVE_PROVIDER_USER } from 'graphql/mutations/users';
import { GET_PROVIDER_USERS } from 'graphql/queries';

interface IProps {
  toggle(modal: string): void;
  onCancel(): void;
  isOpen: boolean;
  user: IUser;
}

const UserInfoModal: React.FC<IProps> = ({ toggle, onCancel, isOpen, user }): JSX.Element => {
  const [success, setSuccess] = useState(false);
  const [userInput, setUserInput] = useState<IUser>();
  // const [loading, setLoading] = useState(false);
  const [removeProviderUser, { loading, error }] = useMutation(REMOVE_PROVIDER_USER, {
    refetchQueries: [{ query: GET_PROVIDER_USERS }],
    onCompleted: () => setSuccess(true),
  });

  useEffect(() => {
    if (user) {
      setUserInput(user);
    }
  }, [user]);

  const handleOnDeleteUser = () => {
    removeProviderUser({ variables: { userId: user._id } });
  };

  const renderContent = () => {
    if (loading) return <Loading />;
    if (success) {
      setTimeout(() => {
        setSuccess(false);
        onCancel();
      }, 2500);

      return <Success />;
    }

    if (!userInput) return null;

    return (
      <FormContent>
        <Row>
          <Input
            label='Fornavn'
            name={'user-name'}
            id='user-name-input'
            value={userInput.name}
            flex
            onChange={(e: ChangeEvent<HTMLInputElement>) => setUserInput({ ...userInput, name: e.target.value })}
          />
          <Input
            label='Efternavn'
            name={'user-lastname'}
            id='user-lastname-input'
            value={userInput.lastName}
            flex
            onChange={(e: ChangeEvent<HTMLInputElement>) => setUserInput({ ...userInput, lastName: e.target.value })}
          />
        </Row>
        <ButtonsContainer>
          <Button label='Fjern bruger' icon={<IoPersonRemoveOutline />} onClick={handleOnDeleteUser} color='red' />
          <Button label='Annuller' variant='hollow' onClick={onCancel} />
        </ButtonsContainer>
      </FormContent>
    );
  };

  return (
    <Modal toggle={toggle} isOpen={isOpen} title={'Bruger information'}>
      <ModalContent>{renderContent()}</ModalContent>
    </Modal>
  );
};

export default UserInfoModal;
