import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div<{ marginBottom?: boolean; flex?: boolean }>`
  display: flex;
  flex-flow: column;

  ${({ marginBottom }) => marginBottom && ` margin-bottom: 12px;`}
  ${({ flex }) => flex && `flex: 1;`}

  .input-label {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const InputContainer = styled.div<{ error?: boolean }>`
  display: flex;
  flex-flow: row;
  flex: 1;
  border-radius: 6px;
  border: 1px solid ${({ error }) => (error ? COLORS.Primary.red : COLORS.lightgrey)};
  position: relative;
  background-color: ${COLORS.Primary.blueWhite};
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
  padding: 9px 12px 9px 0;
  margin-bottom: 3px;

  :hover {
    border-color: ${({ error }) => (error ? COLORS.Primary.red : COLORS.Primary.blue)};
  }

  :focus-within {
    border-color: ${({ error }) => (error ? COLORS.Primary.red : COLORS.Primary.blue)};
  }

  &.active {
    /* background-color: #000; */
    /* box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2); */
    /* border-color: ${COLORS.Primary.darkblue}; */
  }

  input {
    padding: 24px 12px 8px 12px;

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
      color: ${COLORS.Primary.darkblue};
      font-weight: 400;
    }
  }

  &.locked {
    pointer-events: none;
  }

  input {
    flex: 1;
    /* height: 56px; */
    position: relative;
    padding: 0px 12px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: ${COLORS.Primary.darkblue};
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
  }
`;

export const Icon = styled.div`
  margin-right: 6px;
  display: flex;
`;

export const Label = styled.label`
  color: ${COLORS.Primary.darkblue};
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 6px;
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: ${COLORS.Primary.red};
`;

// export const Input = styled.div`
//   display: flex;
//   flex-flow: column;

//   label {
//     font-size: 12px;
//   }

//   input {
//     padding: 12px 24px;
//     border: 1px solid #444;
//     border-radius: 12px;
//     outline: none !important;
//     &:focus {
//       border: 2px solid #333;
//       inset: -1px;
//     }
//   }
// `;
