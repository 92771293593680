import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { IProvider, IProviderAvailability, IProviderTimeSLot } from 'interfaces';
import { Loading, Success } from 'components/ui/Animiations';
import { ModalContent } from 'components/Modal/style';
import AvailabilityList from './availabilityList';
import CalendarInfo from './calendarInfo';
import { useLazyQuery } from '@apollo/client';
import { GET_PROVIDER_AVAILABILITY } from 'graphql/queries/providers';
import { GetMoreTimeslots } from './style';
import BookingContent from 'containers/Provider/Booking/BookingModal/bookingContent';

interface IProps {
  onCancel(): void;
  isOpen: boolean;
  provider: IProvider;
  selectedDatelot: IProviderAvailability;
}

export interface IBookingOptions {
  puser: string | undefined;
  product: string | undefined;
  location: string | undefined;
}

interface IProviderData {
  provider: IProvider;
}

const TimeSlotsModal: React.FC<IProps> = ({ onCancel, isOpen, provider, selectedDatelot }): JSX.Element => {
  // TODO: fetch provider availability (again), the chosen date. This
  const [getProviderAvailability, { loading, error, data, previousData }] = useLazyQuery<IProviderData>(
    GET_PROVIDER_AVAILABILITY,
    { fetchPolicy: 'network-only' }
  );
  const [availabilityList, setAvailabilityList] = useState<IProviderAvailability[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<IProviderTimeSLot | null>(null);
  // const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState(1);
  const [totalDays, setTotalDays] = useState(1);

  const [selectedOptions, setSelectedOptions] = useState<IBookingOptions>({
    puser: undefined,
    product: undefined,
    location: undefined,
  });

  const getAvailability = (days: number) => {
    getProviderAvailability({
      variables: {
        providerId: provider._id,
        availabilityArgs: {
          startDate: selectedDatelot.date,
          days: days,
          ...selectedOptions,
        },
      },
    });
    setTotalDays(days);
  };

  useEffect(() => {
    setAvailabilityList([selectedDatelot]);
  }, []);

  useEffect(() => {
    getAvailability(totalDays);
  }, [selectedOptions]);

  useEffect(() => {
    console.log(selectedSlot);

    if (selectedSlot) {
      setModalState(2);
    }
  }, [selectedSlot]);

  // reset selected slot, if returning from booking
  useEffect(() => {
    if (modalState === 1) {
      setSelectedSlot(null);
    }
  }, [modalState]);

  // reset modal state, when closed
  useEffect(() => {
    if (!isOpen) {
      setModalState(1);
    }
  }, [isOpen]);

  const renderTimeslots = () => {
    return loading ? (
      <Loading />
    ) : (
      <>
        <AvailabilityList
          availabilityList={data?.provider.availability || previousData?.provider.availability || availabilityList}
          provider={provider}
          setSelectedSlot={setSelectedSlot}
        />
        <GetMoreTimeslots>
          {loading ? (
            <Loading height={50} width={50} />
          ) : (
            <a onClick={() => getAvailability(totalDays + 7)}>Vis mig flere tider</a>
          )}
        </GetMoreTimeslots>
      </>
    );
  };

  const renderBooking = () => {
    if (selectedSlot) {
      return <BookingContent provider={provider} onCloseModal={onCancel} slot={selectedSlot} />;
    }
  };

  return (
    <Modal
      toggle={onCancel}
      stretch={true}
      isOpen={isOpen}
      title={provider.name}
      backButton={modalState === 2 ? () => setModalState(1) : undefined}
    >
      <ModalContent>
        <CalendarInfo
          provider={provider}
          showOptions={modalState !== 2}
          isSticky={modalState === 1}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
        {modalState === 1 && renderTimeslots()}
        {modalState === 2 && renderBooking()}
      </ModalContent>
    </Modal>
  );
};

export default TimeSlotsModal;
