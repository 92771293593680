import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 3px 0;
  font-size: 14px;
  align-items: center;
  justify-content: flex-start;
  min-height: 212px;
  /* background-color: ${COLORS.Primary.blueWhite}; */
`;

export const Calendar = styled.div<{
  noPadding: boolean;
}>`
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 3px;
  padding: ${({ noPadding }) => (noPadding ? '0' : '0 32px')};
  overflow: auto;
  height: 100%;

  .timeslot-placeholder {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    flex: 1;

    div {
      height: 3px;
      width: 12px;
      border-radius: 3px;
      background-color: ${COLORS.lightgrey};
      animation: blink 2s infinite;
    }
  }

  @keyframes blink {
    0% {
      background: ${COLORS.lightgrey};
    }

    50% {
      background: ${COLORS.Primary.offwhite};
    }

    100% {
      background: ${COLORS.lightgrey};
    }
  }

  a.datelot {
    border-radius: 6px;
    background-color: ${COLORS.Primary.lightblue};
    cursor: pointer;

    .datelot-container {
      display: flex;
      height: 100%;
      flex-flow: column;
      justify-content: space-between;

      .datelot-date {
        display: flex;
        flex-flow: column;
        text-transform: capitalize;
        font-weight: 500;
        padding: 6px;
      }

      .available-appts {
        display: flex;
        flex-flow: column;
        padding: 6px;
      }
    }

    &:hover:not(.no-timeslots) {
      background-color: ${COLORS.Primary.darkblue};
      color: ${COLORS.white};
    }
  }

  a.no-timeslots {
    background-color: ${COLORS.lightgrey};
    cursor: default;
  }
`;
