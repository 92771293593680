import { useState, useContext, useEffect, useRef, RefObject } from 'react';
import { store } from 'store/store';

/**
 * Hook for handling open/closed state of modals
 * @return {[boolean, () => void]}
 */
export const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  const toggle = () => {
    setIsShowing(!isShowing);
  };

  return [isShowing, toggle];
};

/**
 * Hook for handling authentication and make sure context is set
 * @return {IAuthContext}
 */
export const useFilter = () => {
  const context = useContext(store);

  if (context === undefined) {
    throw new Error('`useFilter` hook context was undefined');
  }
  return context;
};

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.RefObject<any>} ref
 * @param {boolean} initialState
 */
export const useOutsideClick = (
  ref: React.RefObject<any>,
  initialState: boolean
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isActive, setIsActive] = useState<boolean>(initialState);

  useEffect(() => {
    const onClick = (e: any) => {
      // If the active element exists and is clicked outside of
      if (!ref?.current?.contains(e.target)) setIsActive(!isActive);
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) window.addEventListener('mousedown', onClick);

    return () => {
      window.removeEventListener('mousedown', onClick);
    };
  }, [isActive, ref]);

  // Returning with a tuple to prevent TS from automatically inferring
  return [isActive, setIsActive];
};

/**
 * Hook for handling scroll to div on click
 */
export const useScroll = (): [() => void, RefObject<HTMLDivElement>] => {
  const elRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => elRef.current!.scrollIntoView();

  return [executeScroll, elRef];
};
