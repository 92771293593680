import { COLORS } from 'style';
import styled, { css } from 'styled-components';

/* INTERFACS */

interface ITextProps {
  align?: 'left' | 'right' | 'center';
  noMargin?: boolean;
  marginBottom?: number;
  italic?: boolean;
}

/* SIZE BREAKPOINTS */

export const size = {
  xs: '320px',
  sm: '768px',
  lg: '1200px',
};
export const device = {
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  lg: `(max-width: ${size.lg})`,
};

/* TYPOGRAPHY */
const baseStyle = styled.div`
  font-family: Averta Standard, proxima-nova;
  color: ${COLORS.Primary.darkblue};
  margin: 0;
`;

export const H1 = styled.h1<ITextProps>`
  font-size: 28px;
  font-weight: 700;
  text-align: ${({ align }) => align || 'left'};
  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
  @media only screen and (max-width: ${size.sm}) {
    font-size: 20px;
  }
`;

export const H1Light = styled(H1)`
  color: #fff;
`;

export const H2 = styled.h2<ITextProps>`
  /* font-size: 22px;
  font-weight: 700; */
  text-align: ${({ align }) => align || 'left'};

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}

    ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `}

  &:not(:only-child) {
    margin-bottom: ${({ noMargin }) => (noMargin ? '0px' : '12px')};
  }
`;

export const H3 = styled.h3<ITextProps>`
  text-align: ${({ align }) => align || 'left'};

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}

    ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `}
`;

export const H4 = styled.h4<ITextProps>`
  /* font-size: 16px;
  font-weight: 600; */
  text-align: ${({ align }) => align || 'left'};

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}

    ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `}
`;

export const SectionTitle = styled(baseStyle)<ITextProps>`
  font-size: 20px;
  text-align: ${({ align }) => align || 'left'};

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `}
`;

export const Subtitle = styled(baseStyle)<ITextProps>`
  font-size: 18px;
  font-weight: 500;
  text-align: ${({ align }) => align || 'left'};

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `}
`;

export const SubtitleLight = styled(Subtitle)`
  color: #aaa;
`;

export const Label = styled(baseStyle)`
  font-size: 16px;
  font-weight: 600;
`;

export const Text = styled.p<ITextProps>`
  font-size: 16px;
  text-align: ${({ align }) => align || 'left'};

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `}
`;

export const SmallText = styled(baseStyle)<ITextProps>`
  font-size: 12px;
  text-align: ${({ align }) => align || 'left'};

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
`;

/* TABLE */

export const TableContainer = styled.div<{ hoverRows?: boolean }>`
  display: block;
  flex-flow: column;
  width: 100%;
  color: ${COLORS.darkgrey};

  table {
    width: 100%;
    border-spacing: unset;

    thead {
      th {
        text-align: left;
        background-color: ${COLORS.Primary.darkblue};
        color: ${COLORS.white};
        padding: 18px 24px;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
    tbody {
      tr {
        ${(props) =>
          props.hoverRows &&
          css`
            cursor: pointer;

            &:hover {
              background-color: ${COLORS.Primary.blueTint};
            }
          `}

        td {
          padding: 18px 24px;
          // border-bottom: 1px solid #dce3eb;
        }
      }

      tr:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
`;

// base styles

export const Icon = styled.div<{
  marginRight?: number;
  size: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ size }) => (size ? `${size}px` : 'inherti')};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : '3px')};
`;
