import React from 'react';
import Stars from 'components/ui/Stars';
import { IProvider } from 'interfaces';
import { IoMdQuote } from 'react-icons/io';
import {
  Container,
  Card,
  StyledLink,
  ProviderImage,
  CardTitle,
  TopReview,
  ProviderSpecialization,
  CardHeader,
} from './style';

interface IProps {
  provider: IProvider;
}

const ProviderCard: React.FC<IProps> = ({ provider }) => {
  return (
    <Container>
      <StyledLink
        to={{
          pathname: `/${provider.specialization.name.toLowerCase()}/${provider._id}`,
        }}
      >
        <Card>
          <CardHeader>
            <ProviderImage>
              <img src={`/__MOCKDATA__/profileImages/${provider.img}`} alt={provider.name} />
            </ProviderImage>
            <CardTitle>{provider.name}</CardTitle>
            <ProviderSpecialization>{provider.specialization.name}</ProviderSpecialization>
          </CardHeader>
          <Stars rating={provider.rating} />
          {provider.topReview != null && (
            <TopReview>
              <IoMdQuote />
              {`${provider.topReview.body}`}
              <IoMdQuote />
            </TopReview>
          )}
        </Card>
      </StyledLink>
    </Container>
  );
};

export default ProviderCard;
