import React from 'react';
import { H2 } from 'components/Styled';
import { Container, FAQList } from './style';
import Accordion from 'components/ui/Accordion';
import { IFAQ } from 'interfaces';

interface IProps {
  faqs: IFAQ[];
}

const ProviderFAQ: React.FC<IProps> = ({ faqs }): JSX.Element => {
  const renderFAQ = () => {
    return faqs.map((faq) => {
      return (
        <Accordion key={faq._id} question={faq.question}>
          {faq.answer}
        </Accordion>
      );
    });
  };
  return (
    <Container>
      <H2 noMargin>FAQ - Ofte stillede spørgsmål</H2>
      <FAQList>{renderFAQ()}</FAQList>
    </Container>
  );
};

export default ProviderFAQ;
