import { isBefore, isToday } from 'date-fns';

export type DayTypes = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export const translateDay = (day: DayTypes, numberOfChars?: number) => {
  let weekDay: string;

  switch (day) {
    case 'mon':
      weekDay = 'mandag';
      break;
    case 'tue':
      weekDay = 'tirsdag';
      break;
    case 'wed':
      weekDay = 'onsdag';
      break;
    case 'thu':
      weekDay = 'torsdag';
      break;
    case 'fri':
      weekDay = 'fredag';
      break;
    case 'sat':
      weekDay = 'lørdag';
      break;
    case 'sun':
      weekDay = 'søndag';
      break;
    default:
      throw new Error('translateDay argument not valid');
  }

  return numberOfChars ? weekDay.slice(0, numberOfChars) : weekDay;
};

export const getUserPosition = () => {};

export const isPastDay = (date: Date): boolean => {
  return isBefore(date, new Date()) && !isToday(date);
};
