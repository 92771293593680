import styled from 'styled-components';
import { COLORS } from 'style';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 50px;
  /* border: 1px solid #eee; */
  /* padding: 6px 6px; */
  background-color: ${COLORS.white};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  button.search {
    height: 40px;
    width: 40px;
    margin: 7px;
    background: linear-gradient(315deg, rgba(22, 99, 253, 1) 20%, rgba(104, 225, 254, 1) 80%);
    border-radius: 50%;
    border: none;
    color: ${COLORS.white};
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    /* height: 24px;
    width: 24px; */

    :hover {
      filter: brightness(120%);
      transition: all 0.3s ease 0s;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: center;
  padding: 10px 0;

  &:last-of-type > input {
    border-right: none;
  }
  div.input-label {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05em;
    padding: 0 18px 1px 24px;
    cursor: pointer;
  }

  input {
    padding: 0 6px 0 24px;
    height: 18px;
    border: none;
    outline: none;
    /* border-right: 1px solid #eee; */
    background-color: transparent;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #666;
    }
  }

  .location-input {
    input {
      padding-right: 36px;
    }
  }

  .date-input {
    input {
      cursor: pointer;
    }
  }

  ul {
    padding: 12px 0;
    margin: 0;
    width: 500px;

    li {
      text-decoration: none;
      text-transform: capitalize;
      padding: 12px 24px;
      list-style: none;
      font-weight: 400;
      cursor: pointer;

      &.active,
      &:hover {
        background: ${COLORS.Primary.blueWhite};
      }
    }
  }
`;

export const SearchIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VerticalLine = styled.div`
  position: relative;
  width: 1px;
  height: 24px;
  background-color: #eee;
`;

export const InputButton = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: pointer;

  .clear {
    position: relative;
  }
`;

export const LocationInput = styled(motion.div)`
  position: absolute;
  background: ${COLORS.white};
  top: 100%;
  left: 0;
  margin-top: 12px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  z-index: 9999;
`;

export const SpecialtyContainer = styled.div`
  position: absolute;
  background: ${COLORS.white};
  top: 100%;
  right: 0;
  margin-top: 12px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  z-index: 9999;
`;

export const ClearInput = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${COLORS.white};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: 6px;

  div.clear-input {
    height: 15px;
    width: 15px;
    border: none;
    border-radius: 50%;
    background: ${COLORS.lightgrey};
    padding: 3px;

    &:hover {
      filter: brightness(90%);
    }
  }
`;

export const AutocompleteElement = styled.div`
  display: flex;
  flex-flow: row;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

// DATEDROPDOWN COMPONENT

export const DateContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 12px;
  padding: 24px;
  background: ${COLORS.white};
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 12px;
    border-top: 1px solid #eee;
    /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); */

    button.clear-button {
      display: flex;
      background: none;
      border: none;
      border-radius: 6px;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      outline: none;
      padding: 10px 12px;
      margin: 0 12px;
      color: ${COLORS.Primary.darkblue};

      label {
        cursor: pointer;
      }

      &:hover {
        background-color: ${COLORS.Primary.blueWhite};
      }
    }
  }
`;

export const DateTitle = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding: 0 0 12px 7px;
  text-transform: capitalize;
  max-width: 300px;
`;

export const DateControls = styled.div`
  display: flex;
  flex-flow: row;

  .date-control {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 20px;
    padding: 6px;
    color: ${COLORS.Primary.darkblue};
    cursor: pointer;
  }
`;

export const Calendar = styled.div`
  height: 280px;
  max-width: 300px;
`;

export const CalendarContent = styled.div`
  table {
    display: table;
    width: 100%;
    text-align: center;

    thead {
      display: table-header-group;
      justify-content: space-between;
      tr {
        th {
          font-weight: 400;
          color: ${COLORS.darkgrey};
          text-transform: capitalize;
        }
      }
    }
    tbody {
      tr {
        td {
          /* padding: 12px; */
          color: ${COLORS.Primary.darkblue};
          width: 36px;
          height: 36px;
          cursor: pointer;

          &:hover:not(.disabled) {
            background: ${COLORS.Primary.lightblue};
            border-radius: 6px;
          }

          &.disabled {
            color: ${COLORS.lightgrey};
            cursor: default;
          }

          &.selected:not(.disabled) {
            background: ${COLORS.Primary.blue};
            border-radius: 6px;
            color: ${COLORS.white};
          }
        }
      }
    }
  }
`;
