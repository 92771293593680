import { IFilter } from 'interfaces';

export enum ActionType {
  SetFilter = 'SET_FILTER',
}

interface ISetFilter {
  type: ActionType.SetFilter;
  payload: IFilter;
}

export type Actions = ISetFilter; // IIncrementId | IDecrementId | ISetChangeValue |

// export const IncrementId = (): IIncrementId => ({
//   type: ActionType.IncrementId,
// });

// export const DecrementId = (): IDecrementId => ({
//   type: ActionType.DecrementId,
// });

// export const SetChangeValue = (value: number): ISetChangeValue => ({
//   type: ActionType.SetChangeValue,
//   payload: value,
// });

export const SetFilter = (response: IFilter): ISetFilter => ({
  type: ActionType.SetFilter,
  payload: response,
});
