import React from 'react';
import { Container } from './style';
import { H1 } from 'components/Styled';
import Rater from './Rater';
import { IRaterProps } from './interface';

const ServiceRating: React.FC<IRaterProps> = (props): JSX.Element => {
  return (
    <Container>
      <H1 align="center">Service Rating</H1>
      <Rater {...props} />
    </Container>
  );
};

export default ServiceRating;
