import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div<{ marginBottom?: boolean }>`
  display: flex;
  row-gap: 6px;
  flex-flow: column;

  select {
    position: relative;
    padding: 8px 12px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #dbdee1;
    background-color: ${COLORS.Primary.blueWhite};
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    font-size: 13px;
    font-weight: 400;
    color: ${COLORS.Primary.darkblue};
    line-height: inherit;

    &::after {
      content: '';
      display: block;
      width: 0.8em;
      height: 0.5em;
      background-color: ${COLORS.Primary.darkblue};
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    }
  }
`;

export const Label = styled.label`
  color: ${COLORS.Primary.darkblue};
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
`;
