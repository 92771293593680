import styled from 'styled-components';

export const Container = styled.div`
  font-size: 18px;
  color: #444;
  border-top: 1px solid #eaedf2;
  border-bottom: 1px solid #eaedf2;
`;

export const NotificationItem = styled.div<{ isNew: boolean }>`
  font-size: 18px;
  color: #444;
  padding: 12px;
  background-color: ${({ isNew }) => (isNew ? '#eaedf2' : '#fff')};
  /* transition: background-color 0.5 ease-in; */
  cursor: pointer;

  &:hover {
    transition: background-color 0.2s ease;
    background-color: #eaedf2;
  }

  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TimeStamp = styled.small`
  /* font-size: 10px; */
  color: #66799e;
`;
