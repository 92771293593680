import React, { useEffect, useState, useContext } from 'react';
import {
  MapContainer,
  SearchContainer,
  Providers,
  ProvidersList,
  Provider,
  ProviderImg,
  ProviderInfo,
  Title,
  Profession,
  Address,
  LinkWrapper,
  TimeSlotControl,
} from './style';
import { Text, H3 } from 'components/Styled';
import { IoChevronBack, IoChevronForward, IoLocationSharp } from 'react-icons/io5';
import { useLazyQuery } from '@apollo/client';
import { IAvailabilityArgs, IProvider } from 'interfaces';
import { GET_FILTERED_PROVIDERS } from 'graphql/queries';
import SearchFilter from './SearchFilter';
import { format, addDays, subDays, isAfter, isBefore, isPast, isToday } from 'date-fns';
import { da } from 'date-fns/locale';
import Rating from './Rating';
import ProviderCalendar from './ProviderCalendar';
import { store } from 'store/store';
import { MapV7 } from './MapV7';
import { Loading, NotFound } from 'components/ui/Animiations';
import { SetFilter } from 'store/store.actions';

interface IProvidersData {
  providers: IProvider[];
}

const Search = () => {
  const [hoveredId, setHoveredId] = useState<string>('');
  const {
    state: { filter },
    dispatch,
  } = useContext(store);
  const [getProviders, { loading, error, data, previousData }] = useLazyQuery<IProvidersData>(GET_FILTERED_PROVIDERS, {
    fetchPolicy: 'cache-and-network',
  });
  let providers = data?.providers || previousData?.providers || [];

  const availabilityArgs: IAvailabilityArgs = {
    startDate: filter.date,
    puser: undefined, // objectID
    location: undefined, // objectID
    product: undefined,
    days: 14,
  };

  useEffect(() => {
    if (filter) {
      const mappedFilter = {
        ...filter,
        genders: filter.genders.map((gender) => gender.gender),
        languages: filter.languages.map((lang) => lang.language),
      };

      getProviders({
        variables: {
          filter: mappedFilter,
          availabilityArgs,
        },
      });
    }
  }, [filter]);

  const handleOnStartDateChange = (date: Date) => {
    if (isPast(date) && !isToday(filter.date)) {
      dispatch(SetFilter({ ...filter, date: new Date() }));
    } else if (!isPast(date)) {
      dispatch(SetFilter({ ...filter, date: date }));
    }
  };

  const renderProvidersList = () => {
    if (loading && !previousData) {
      return <Loading />;
    }
    return (
      <ProvidersList>
        {providers.length > 0 &&
          providers.map((provider: IProvider, i: number) => (
            <Provider key={i} onMouseEnter={() => setHoveredId(provider._id)} onMouseLeave={() => setHoveredId('')}>
              <div className='provider-content'>
                <LinkWrapper
                  to={{
                    pathname: `/${provider.specialization.name.toLowerCase()}/${provider._id}`,
                  }}
                >
                  <ProviderImg src={`/__MOCKDATA__/profileImages/${provider.img.replace('.jpeg', '.jpg')}`} />
                  <ProviderInfo>
                    <Title>{provider.name}</Title>
                    <Profession>{provider.specialization.name}</Profession>
                    <Rating rating={provider.rating || 0} reviewCount={provider.reviewCount || 0} />
                    <Address>
                      <div className='icon'>
                        <IoLocationSharp />
                      </div>
                      {`${
                        provider.locations[0].distanceToProvider > 999
                          ? (provider.locations[0].distanceToProvider / 1000).toFixed(0) + ' km'
                          : provider.locations[0].distanceToProvider + ' m'
                      } • ${provider.locations[0].street}, ${provider.locations[0].postcode}, ${
                        provider.locations[0].city
                      }`}
                    </Address>
                  </ProviderInfo>
                </LinkWrapper>
                {<ProviderCalendar provider={provider} noPadding={true} loading={loading} />}
              </div>
            </Provider>
          ))}
        {data && data.providers.length === 0 && (
          <div className='notfound-container'>
            <NotFound />
            <H3>Ingen resultater fundet.</H3>
            <Text>Specificér din søgning for at se resultater.</Text>
          </div>
        )}
      </ProvidersList>
    );
  };
  return (
    <SearchContainer>
      <Providers>
        <SearchFilter providers={(data && data.providers) || []} />
        <div className='providerlist-header'>
          <span>{`${data?.providers.length || 0} behandlere`}</span>
          <TimeSlotControl>
            <div className='current-week'>
              {`${format(filter.date, 'eee, d. MMM', { locale: da }).replace('.', '')} - ${format(
                addDays(filter.date, 13),
                'eee, d. MMM',
                {
                  locale: da,
                }
              ).replace('.', '')}`}
            </div>
            <button
              className={`date-controller${isToday(filter.date) ? ' disabled' : ''}`}
              onClick={() => handleOnStartDateChange(subDays(filter.date, 14))}
            >
              <IoChevronBack />
            </button>
            <button className='date-controller' onClick={() => handleOnStartDateChange(addDays(filter.date, 14))}>
              <IoChevronForward />
            </button>
          </TimeSlotControl>
        </div>
        {renderProvidersList()}
      </Providers>
      <MapContainer>
        <MapV7 providers={data && data.providers ? data.providers : []} hoveredId={hoveredId} />
      </MapContainer>
    </SearchContainer>
  );
};

export default Search;
