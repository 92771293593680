import React from 'react';
import { TableContainer } from 'components/Styled';
import { IJournal } from 'interfaces';
import { Column, useTable } from 'react-table';
import { useEffect } from 'react';
import { useState } from 'react';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';

interface IProps {
  journals: IJournal[];
}

const JournalTable: React.FC<IProps> = ({ journals }): JSX.Element => {
  const [data, setData] = useState<IJournal[]>([]);
  const navigate = useNavigate();
  //   const data = React.useMemo(() => journals, []);

  useEffect(() => {
    setData(journals);
  }, [journals]);

  const columns = React.useMemo<Column<IJournal>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'journalId',
      },
      {
        Header: 'Patient',
        accessor: ({ patient }) => `${patient.name} ${patient.lastName}`,
      },
      {
        Header: 'Created',
        accessor: (journal) => format(new Date(journal.createdAt), 'dd. MMMM yyyy', { locale: da }),
      },
      {
        Header: 'Action',
        accessor: (journal) => <button onClick={() => navigate(`/admin/journals/${journal._id}`)}>Se Journal</button>,
      },
    ],
    [navigate]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <TableContainer>
      <table {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()}>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </TableContainer>
  );
};

export default JournalTable;
