import Slider from 'react-slick';
import { COLORS } from 'style';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* background-color: ${COLORS.Primary.offwhite}; */
`;

export const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: #5c3aff;
  }
`;

// SECTION STYLES

// S for shared
export const SContainer = styled.section<{
  bgColor?: string;
}>`
  background: ${(props) => (props.bgColor ? props.bgColor : COLORS.Primary.offwhite)};
  /* background: radial-gradient(circle at bottom, rgba(181, 205, 252, 1) 0%, rgba(249, 249, 249, 1) 100%); */
  padding: 48px 0;
`;

export const SectionContent = styled.div`
  position: relative;
  max-width: 1280px;
  padding: 0 24px;
  margin: 0 auto;
`;

export const SectionTitle = styled.h2`
  font-size: 36px;
  color: ${COLORS.white};
  font-weight: 700;
  margin: 0;
`;

export const SSubtitle = styled.h3`
  font-size: 20px;
  color: ${COLORS.white};
  font-weight: 600;
`;

export const StyledArrow = styled.div``;

export const StyledSlider = styled(Slider)`
  .slick-prev:before,
  .slick-next:before {
    color: ${COLORS.Primary.darkblue} !important;
    display: none;
    -
  }
  .slick-prev {
  }
  /* .slick-slider {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
   .slick-list {
    position: relative;
    display: block !important;
    overflow: hidden;
    padding: 100px !important;
    width: 100%;
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
  }
  .slick-slide div {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .slick-slide .image {
    object-fit: cover;
    border-radius: 2px;
    min-width: 150px !important;
    width: 80%;
  }
  .slick-slide div div span {
    margin-top: 10px;
    width: inherit;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
  }

  .slick-slide:not(.slick-active) {
    position: relative;
    z-index: 997;
  }
  .slick-slide.slick-active:not(.slick-current) {
    position: relative;
    z-index: 998;
  }
  .slick-slide.slick-active.slick-current {
    position: relative;
    z-index: 999;
  }
  .slick-slide.slick-active:not(.slick-current) .image,
  .slick-slide.slick-active.slick-current .image,
  .slick-slide:not(.slick-active) .image {
    width: 100% !important;
  }


   .custom-prevArrow,
  .custom-nextArrow {
    position: relative;
    z-index: 99999;
    top: -10px;
  }
  .custom-prevArrow {
    left: -10px;
  }
  .custom-nextArrow {
    right: -10px;
  }
  .custom-prevArrow:hover,
  .custom-nextArrow:hover {
    fill: red;
    cursor: pointer;
  } */
`;
