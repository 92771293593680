import styled from 'styled-components';

export const UploadProfileImage = styled.div`
  display: flex;
  flex-flow: row;

  .avatar {
    display: flex;
    position: relative;
    width: 72px;
    height: 72px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 12px;

    img {
      max-width: 100%;
      height: auto;
    }
    .remove-image {
      position: absolute;
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.8);
      font-size: 36px;
      justify-content: center;
      align-items: center;
    }
  }

  .file-data {
    display: flex;
    align-items: center;
    color: #444;
    .file-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 48px;
      color: #444;
      margin-right: 6px;
    }
    input[type='file'] {
      max-width: 230px;
      position: absolute;
      cursor: pointer;
      opacity: 0;
      padding-bottom: 30px;
    }
    .file-text {
    }
  }
`;

export const ImageDrop = styled.div`
  display: flex;
  flex: 1;
  form {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    width: 100%;
    border: 2px dashed #93a5be;
    border-radius: 6px;
    background: #fff;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
      background-color: transparent;
    }

    input {
      display: none;
    }
  }
`;
