import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_BOOKINGS, GET_PROVIDER_ADMIN } from 'graphql/queries';
import { Body, Container, Content, Control, Days, Header, TimeslotTable, Timeline, Cell } from './style';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { format, isEqual, isAfter, isBefore, addMinutes, addDays, addWeeks, subWeeks, subMinutes } from 'date-fns';
import { H2 } from 'components/Styled';
import { IBooking, IProviderAdmin } from 'interfaces';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import Booking from './Booking';
import BookingModal from './Modal';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import ClipLoader from 'react-spinners/ClipLoader';
import { useAuth } from 'helpers/auth';

const setTime = (date: Date, hour: number, minute: number) => {
  date.setHours(hour);
  date.setMinutes(minute);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

const Bookings = (): JSX.Element => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentWeek, setCurrentWeek] = useState<Date[]>([]);
  const [bookings, setBookings] = useState<IBooking[]>([]);
  const [isOpen, toggle] = useState<string>('');
  const [selectedBooking, setSelectedBooking] = useState<IBooking>({} as IBooking);
  const { data: providerData, loading: providerLoading } = useQuery<{ providerAdmin: IProviderAdmin }>(
    GET_PROVIDER_ADMIN,
    {
      variables: { providerId: currentUser?.providerId },
    }
  );
  const { data: bookingData, loading: bookingLoading } = useQuery<{ bookings: IBooking[] }>(GET_BOOKINGS);

  useEffect(() => {
    if (bookingData?.bookings) {
      // convert to date Objects
      const _bookings = bookingData.bookings.map((booking) => {
        return {
          ...booking,
          fromDate: new Date(booking.fromDate),
          toDate: new Date(booking.toDate),
        };
      });
      setBookings(_bookings);
    }
  }, [bookingData]);

  useEffect(() => {
    const week: Date[] = [];

    for (let i = 0; i < 7; i++) {
      week.push(addDays(currentDate, i));
    }
    setCurrentWeek(week);
  }, [currentDate]);

  const handleClick = (booking?: IBooking, date?: Date) => {
    if (booking && !date) {
      setSelectedBooking(booking);
    }

    toggle('booking');
  };

  const newBookingHandler = (fromDate: Date) => {
    const newBooking: IBooking = {
      providerId: providerData?.providerAdmin._id,
      fromDate,
    } as IBooking;

    setSelectedBooking(newBooking);

    toggle('booking');
  };

  const onDragEndHandler = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination || source.droppableId === destination.droppableId) {
      return;
    }

    if (window.confirm('Er du sikker på du vil flytte booking til ny dato?')) {
      const copiedBookings = [...bookings];
      const bookingIdx = copiedBookings.findIndex((booking: IBooking) => booking._id === draggableId);
      // droppableId is military time and 'ddmm'
      const hour = +destination.droppableId.slice(0, 2);
      const min = +destination.droppableId.slice(2, 4);
      const day = +destination.droppableId.slice(5, 7);
      const month = +destination.droppableId.slice(7, 9) - 1; // compensate zero index on date object..js thing
      const year = +destination.droppableId.slice(-4);
      const booking = copiedBookings[bookingIdx];

      booking.fromDate = new Date(year, month, day, hour, min, 0, 0);
      booking.toDate = addMinutes(booking.fromDate, booking.product.durationInMinutes);

      setBookings(copiedBookings);
    }
  };

  const onConfirmHandler = (booking: IBooking) => {
    if (!booking._id) {
      booking._id = (bookings.length + 1).toString(); // temp id to simulate response from server
      const bookingsCopy = [...bookings];
      bookingsCopy.push(booking);
      setBookings(bookingsCopy);
    } else if (booking.patient.journal) {
      navigate(`/admin/journals/${booking.patient.journal}`);
    } else {
      navigate(`/admin/journal/newJournal`);
    }

    toggle('');
  };

  const renderWeekDays = () => {
    return (
      <tr>
        {currentWeek.map((date, i) => (
          <td key={i}>
            <div>
              <p>{format(date, 'eee')}</p>
              <span>{format(date, 'd')}</span>
            </div>
          </td>
        ))}
      </tr>
    );
  };

  const renderRows = () => {
    // TODO: split up into smaller components, when/if this becomes huge.

    // get earliest opening time as Date object
    const startTime = providerData!.providerAdmin.openingHours
      .map((day) => day.from)
      .reduce((acc, curr) => (curr < acc ? curr : acc));
    const [starHour, startMin] = startTime.split(':');
    const startTimeDate = setTime(new Date(), +starHour, +startMin);

    // get latest closing time as Date object
    const endTime = providerData!.providerAdmin.openingHours
      .map((day) => day.to)
      .reduce((acc, curr) => (curr > acc ? curr : acc));
    const [endHour, endMin] = endTime.split(':');
    const endTimeDate = setTime(new Date(), +endHour, +endMin);

    let time: Date = setTime(new Date(), +starHour, +startMin);
    const timeline: Date[] = [startTimeDate];

    while (!isEqual(time, endTimeDate) && !isAfter(time, endTimeDate)) {
      time = addMinutes(time, 30);
      timeline.push(time);
    }

    const rows = [subMinutes(startTimeDate, 30), ...timeline].map((date, index) => {
      const cells = currentWeek.map((day, dayIdx) => {
        // get booking matching timeline range and index day
        const cellBookings = _.filter(
          bookings,
          (booking) =>
            booking.fromDate.getFullYear() === day.getFullYear() &&
            booking.fromDate.getMonth() === day.getMonth() &&
            booking.fromDate.getDate() === day.getDate() &&
            booking.fromDate.getHours() === date.getHours() &&
            booking.fromDate.getMinutes() === date.getMinutes()
        );

        // merging timeline with weekdays to get selected time slot
        const currentFromDate = new Date(
          day.getFullYear(),
          day.getMonth(),
          day.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds()
        );

        return (
          <Droppable key={index + '-' + dayIdx} droppableId={`${format(date, 'HHmm')}-${format(day, 'ddMMyyyy')}`}>
            {(provided, snapshot) => {
              return (
                <Cell
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                  ref={provided.innerRef}
                  key={index + dayIdx}
                  active={!isBefore(date, startTimeDate)}
                  onClick={() => newBookingHandler(currentFromDate)}
                >
                  {cellBookings.map((booking, i) => (
                    <Draggable key={booking._id} draggableId={booking._id} index={i}>
                      {(provided, snapshot) => {
                        return (
                          <Booking
                            provided={provided}
                            snapshot={snapshot}
                            key={i}
                            booking={booking}
                            onClick={() => handleClick(booking)}
                          />
                        );
                      }}
                    </Draggable>
                  ))}
                  <div style={{ height: '48px' }}>{provided.placeholder}</div>
                </Cell>
              );
            }}
          </Droppable>
        );
      });

      return <tr key={index}>{cells}</tr>;
    });

    return (
      <>
        {renderTimeline(timeline)}
        <TimeslotTable>
          <table>
            <tbody>{rows}</tbody>
          </table>
        </TimeslotTable>
      </>
    );
  };

  const renderTimeline = (timeline: Date[]) => {
    return (
      <Timeline>
        <div className='timeline-wrapper'>
          <table>
            <tbody>
              <tr>
                <td>
                  <div className='half'></div>
                  {timeline.map((time, timeIdx) => (
                    <div key={timeIdx}>
                      <span>{format(time, 'HH:mm')}</span>
                    </div>
                  ))}
                  <div className='half'></div>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              {Array(timeline.length + 1)
                .fill(null)
                .map((_, i) => (
                  <tr key={i}>
                    <td />
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Timeline>
    );
  };

  if (providerLoading || bookingLoading) {
    return <ClipLoader color={'white'} loading={providerLoading || bookingLoading} size={48} />;
  }

  return (
    <Container>
      <Header>
        <Control>
          <div onClick={() => setCurrentDate(subWeeks(currentDate, 1))}>
            <IoChevronBack size={20} color={'#444'} />
          </div>
          <div onClick={() => setCurrentDate(addWeeks(currentDate, 1))}>
            <IoChevronForward size={20} color={'#444'} />
          </div>
        </Control>
        <H2 noMargin>{format(currentDate, 'MMMM, yyyy')}</H2>
      </Header>
      <Days>
        <div className='filler'></div>
        <div>
          <table>
            <tbody>{renderWeekDays()}</tbody>
          </table>
        </div>
      </Days>
      <Body>
        {providerData && (
          <DragDropContext onDragEnd={onDragEndHandler}>
            <Content>{renderRows()}</Content>
          </DragDropContext>
        )}
      </Body>
      <BookingModal
        isOpen={isOpen === 'booking'}
        toggle={toggle}
        provider={providerData && providerData.providerAdmin}
        onConfirm={onConfirmHandler}
        booking={selectedBooking && selectedBooking}
      />
    </Container>
  );
};

export default Bookings;
