import React, { useState, ChangeEvent } from 'react';
import { Container, Description, Label, LabelContainer, Switch } from './style';
// import { FieldError, RefCallBack, RegisterOptions } from 'react-hook-form';

interface IProps {
  label: string;
  description: string;
  id: string;
  value: boolean;
  tooltip?: JSX.Element;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Toggle: React.FC<IProps> = ({
  label,
  description,
  id,
  value,
  onChange,
  tooltip,
  disabled = false,
}): JSX.Element => {
  return (
    <Container>
      <LabelContainer>
        <div className='container'>
          <Label>{label}</Label>
          {tooltip}
        </div>
        <Description>{description}</Description>
      </LabelContainer>

      <Switch $disabled={disabled}>
        <input type='checkbox' name={label} id={id} checked={value} onChange={onChange} disabled={disabled} />
        <label className='switch' htmlFor={id} />
      </Switch>
    </Container>
  );
};

export default Toggle;
