import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  justify-content: center;
  margin: 0 48px;
`;

export const Container = styled.section`
  /* background: radial-gradient(circle at bottom, rgba(181, 205, 252, 1) 0%, rgba(249, 249, 249, 1) 100%); */
  display: flex;
  flex: 1;
  padding: 48px 0;
`;
