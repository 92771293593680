import { H3 } from 'components/Styled';
import Tooltip from 'components/ui/Tooltip';
import React from 'react';
import { Container, Label } from './style';

interface IProps {
  label: string;
  value: string;
  onClick(): void;
  tooltip?: string;
}

const InputRow: React.FC<IProps> = ({ label, value, onClick, tooltip }) => {
  return (
    <Container>
      <Label>
        <H3>{label}</H3>
        {tooltip && <Tooltip alignment='right'>{tooltip}</Tooltip>}
      </Label>

      <p onClick={onClick}>{value}</p>
    </Container>
  );
};

export default InputRow;
