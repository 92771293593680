import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import { ANIMATIONS } from 'style';

interface IProps {
  width?: number;
  height?: number;
}

const NotFound: React.FC<IProps> = ({ width = 150, height = 150 }): JSX.Element => {
  return <Player style={{ width, height }} keepLastFrame autoplay src={ANIMATIONS.notFound} />;
};

export default NotFound;
