import styled, { css } from 'styled-components';

export const Container = styled.div<{
  size: number;
  color: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => `${color}`};
`;
