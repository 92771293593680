import React from 'react';
import { H1 } from 'components/Styled';
import InputRow from './InputRow';
import { Content, Rows } from './style';
import { IProfileContentProps } from './interfaces';

const GeneralTab: React.FC<IProfileContentProps> = ({ user, setUser, openModal }) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFieldName = e.target.name;
    console.log(inputFieldName);
  };

  return (
    <>
      <Content>
        <H1 noMargin>Profil</H1>
        <Rows>
          <InputRow label='Navn' value={`${user.firstName} ${user.lastName}`} onClick={() => openModal('Navn')} />
          <InputRow label='Email' value={user.email} onClick={() => openModal('Email')} />
          <InputRow
            label='Telefon nummer'
            value={`${user.phone.countryCode} ${user.phone.number}`}
            onClick={() => openModal('Telefonnummer')}
            tooltip='Dette er bare en test'
          />
          <InputRow label='Land' value={'Danmark'} onClick={() => openModal('Land')} />
          <InputRow label='Navn' value={'Christian da Silva'} onClick={() => openModal('Navn')} />
        </Rows>
      </Content>
    </>
  );
};

export default GeneralTab;
