import React from 'react';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { CheckboxInput, Container, InputText, Label, Wrapper, CheckboxIcon } from './style';

interface IProps {
  label?: string;
  onChange(): void;
  value: boolean;
}

const Checkbox: React.FC<IProps> = ({ label, onChange, value }): JSX.Element => {
  return (
    <Container>
      <Label>
        <Wrapper>
          <CheckboxInput>
            <input type='checkbox' checked={value} onChange={onChange} />
            <CheckboxIcon checked={value}>{value && <IoCheckmarkSharp />}</CheckboxIcon>
          </CheckboxInput>
          {label && <InputText>{label}</InputText>}
        </Wrapper>
      </Label>
    </Container>
  );
};

export default Checkbox;
