import styled from 'styled-components';

interface IColumnProps {
  flex?: number;
}

export const Block = styled.div`
  display: block;
  padding: 24px;
  position: relative;
  flex-flow: column;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eaedf2;
  border-radius: 6px;
  min-width: 0;
  word-wrap: break-word;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  transition: all 0.2s ease;

  & + & {
    margin-top: 12px;
  }
`;

export const Column = styled.div<IColumnProps>`
  display: block;
  flex: ${({ flex }) => (flex ? flex : 1)};
  flex-flow: column;
`;

// export const SideBlock = styled.div`
//   display: block;
//   position: relative;
//   flex-flow: column;
//   background-color: #fff;
//   background-clip: border-box;
//   border: 1px solid #eaedf2;
//   border-radius: 6px;
//   padding: 24px;
//   min-width: 0;
//   word-wrap: break-word;
//   box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
//   margin-top: 12px;
//   margin-bottom: 12px;
//   transition: all 0.2s ease;
// `;

export const Row = styled.div<{ center?: boolean }>`
  display: flex;
  flex-flow: row;
  margin-bottom: 12px;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};

  &:last-child {
    margin-bottom: 0;
  }

  & > *:not(:only-child) {
    margin: 0px 6px;

    &:first-child {
      margin: 0 6px 0 0;
    }

    &:last-child {
      margin: 0 0 0 6px;
    }
  }
`;

export const Container = styled.div`
  display: block;
  flex-flow: column;
`;

export const BlockTitle = styled.div`
  margin-bottom: 24px;
`;
