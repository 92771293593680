import { Navigate, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ME } from 'graphql/queries';
import { Loading } from 'components/ui/Animiations';

interface IProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<IProps> = ({ children }) => {
  const location = useLocation();
  const { data, loading } = useQuery(GET_ME);

  if (loading) {
    return <Loading />;
  }

  if (!data || (data && !data.me.providerId)) {
    // navigate('/');
    return <Navigate to={'/'} replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
