import React, { useState } from 'react';
import ProfileContent from './ProfileContent';
import ProfileNavigation from './ProfileNavigation';
import { Container, Main } from './style';

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState('tab1');
  return (
    <Main>
      <Container>
        <ProfileNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
        <ProfileContent activeTab={activeTab} />
      </Container>
    </Main>
  );
};

export default UserProfile;
