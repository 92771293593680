import React, { useEffect, useRef } from 'react';
import {
  IoEllipsisHorizontal,
  IoShareSocialOutline,
  IoShieldCheckmarkOutline,
  IoThumbsUpOutline,
  IoTrashOutline,
  IoCreateOutline,
  IoAlertCircleOutline,
} from 'react-icons/io5';
import {
  Container,
  Icon,
  ReviewButton,
  ReviewBody,
  ReviewFooter,
  ReviewHeader,
  ReviewSubTitle,
  ReviewTitle,
  ReviewDate,
  ButtonContainer,
  ProviderReponse,
  ResponseTitle,
  ReviewActions,
  ReviewOptions,
  Option,
  OptionSmallText,
  OptionText,
} from './style';
import UserImage from './UserImage';
import DefaultAvatar from 'assets/images/genderless-avatar.jpeg';
import { IProvider, IReview, IUser } from 'interfaces';
import { formatDistanceStrict } from 'date-fns';
import { da } from 'date-fns/locale';
import { useMutation } from '@apollo/client';
import { GET_PROVIDER_REVIEWS } from 'graphql/queries';
import { TOGGLE_VOTE, DELETE_REVIEW } from 'graphql/mutations';
import { isAfter } from 'date-fns';
import SmartText from 'components/ui/SmartText';
import { useOutsideClick } from 'helpers/hooks';
import { COLORS } from 'style';
import Stars from 'components/ui/Stars';

interface IProps {
  review: IReview;
  provider: IProvider;
  authUser?: IUser;
}

const Review: React.FC<IProps> = ({
  review: { _id, author, providerId, verifiedUser, rating, body, upvotes, providerResponse, createdAt, updatedAt },
  authUser,
  provider,
}): JSX.Element => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useOutsideClick(dropdownRef, false);

  const [toggleVote] = useMutation(TOGGLE_VOTE, {
    variables: { reviewId: _id },
    refetchQueries: [{ query: GET_PROVIDER_REVIEWS, variables: { providerId } }],
    awaitRefetchQueries: true,
  });

  const [deleteReview] = useMutation(DELETE_REVIEW, {
    variables: { reviewId: _id },
    refetchQueries: [{ query: GET_PROVIDER_REVIEWS, variables: { providerId } }],
    awaitRefetchQueries: true,
  });

  return (
    <Container>
      <ReviewHeader>
        <div className='review-header-info'>
          <UserImage
            imageUrl={author ? author.profileImage : DefaultAvatar}
            initials={author ? `${author.name.charAt(0).toUpperCase() + author.lastName.charAt(0).toUpperCase()}` : ''}
          />
          <div className='header-content'>
            <ReviewTitle>{author ? `${author.name} ${author.lastName}` : 'Brugeren er slettet'}</ReviewTitle>
            <ReviewSubTitle>
              {verifiedUser && (
                <>
                  <Icon>
                    <IoShieldCheckmarkOutline />
                  </Icon>
                  <span>Bekræftet patient</span>
                </>
              )}
            </ReviewSubTitle>
            <Stars rating={rating.avgRating} />
          </div>
        </div>
        <ReviewDate>{`Anmeldt for ${formatDistanceStrict(new Date(createdAt), new Date(), {
          locale: da,
        })} siden${isAfter(new Date(updatedAt), new Date(createdAt)) ? ' • Redigeret' : ''}`}</ReviewDate>
      </ReviewHeader>
      <ReviewBody>
        <SmartText text={body} />
        {providerResponse && (
          <ProviderReponse>
            <div className='response-title'>
              <ResponseTitle>{`Svar fra ${provider.name}`}</ResponseTitle>
              <ReviewDate>
                {`Svaret for ${formatDistanceStrict(new Date(providerResponse.createdAt), new Date(), {
                  locale: da,
                })} siden`}
              </ReviewDate>
            </div>
            <SmartText overlayColor={COLORS.Primary.blueWhite} text={providerResponse.response} />
          </ProviderReponse>
        )}
      </ReviewBody>
      <ReviewFooter>
        <ButtonContainer>
          <ReviewButton
            onClick={() => authUser && toggleVote()} // TODO: Promt user to register/login, when clicked
            isUpvotedByUser={upvotes.some((upvotee) => upvotee._id == authUser?._id)}
          >
            <Icon margin={8}>
              <IoThumbsUpOutline />
            </Icon>
            <span>
              Nyttig <span>{upvotes.length}</span>
            </span>
          </ReviewButton>
          <ReviewButton>
            <Icon margin={8}>
              <IoShareSocialOutline />
            </Icon>
            <span>Del</span>
          </ReviewButton>
        </ButtonContainer>
        <ReviewActions>
          <button
            onClick={(e) => {
              e.stopPropagation(); // needed after react 18 update for some reason or onClick will double trigger. Possible bug
              setIsActive(!isActive);
            }}
          >
            <IoEllipsisHorizontal />
          </button>
          {isActive && (
            <ReviewOptions ref={dropdownRef}>
              {author && author._id === authUser?._id && (
                <>
                  <Option onClick={() => deleteReview()}>
                    <Icon margin={6}>
                      <IoTrashOutline />
                    </Icon>
                    <span>Slet</span>
                  </Option>
                  <Option>
                    <Icon margin={6}>
                      <IoCreateOutline />
                    </Icon>
                    <span>Redigér</span>
                  </Option>
                </>
              )}
              {
                // TODO: Rapport system
              }
              <Option>
                <Icon margin={6}>
                  <IoAlertCircleOutline />
                </Icon>
                <div className='option-text'>
                  <OptionText>Rapportér anmeldelse</OptionText>
                  <OptionSmallText>Denne anmeldelse er dum</OptionSmallText>
                </div>
              </Option>
            </ReviewOptions>
          )}
        </ReviewActions>
      </ReviewFooter>
    </Container>
  );
};

export default Review;
