import styled from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS } from 'style';

export const AnimatedContainer = styled(motion.div)`
  display: flex;
  flex-flow: row;
  padding: 12px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${COLORS.Primary.blueWhite};
  }
`;

export const AccordionQuestion = styled(motion.div)`
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: ${COLORS.Primary.darkblue};
  padding-top: 4px;
`;

export const Icon = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 28px;
  height: 28px;
  cursor: pointer;
  color: ${COLORS.Primary.blue};
`;

export const AccordionQuestionText = styled(motion.div)``;

export const AccordionAnswer = styled(motion.div)`
  padding: 24px;
  background: ${COLORS.Primary.blueWhite};
  border-radius: 6px;
  margin-top: 12px;
`;
