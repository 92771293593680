import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(100, 116, 139, 0.1);
  padding: 6px 0;

  p {
    color: ${COLORS.Primary.blue};
    cursor: pointer;
  }
`;

export const Label = styled.div`
  display: flex;
  flex-flow: row;
`;
