import React from 'react';
import { Block, Container, Row } from '../../style';
import { useQuery } from '@apollo/client';
import { IJournal } from 'interfaces';
import { GET_JOURNAL } from 'graphql/queries';
import { InputLabel, JournalInput, PatientData } from './style';
import { Subtitle, Text } from 'components/Styled';

interface IJournalVars {
  journalId: string;
}

interface IJournalData {
  journal: IJournal;
}

const Journal = (): JSX.Element => {
  const {
    loading,
    error,
    data: { journal } = {},
  } = useQuery<IJournalData, IJournalVars>(GET_JOURNAL, {
    variables: { journalId: window.location.pathname.split('/').pop() || '' },
  });

  if (loading) return <p>Loading...</p>;
  if (error) {
    console.log(error.graphQLErrors);
    return <p>Error!</p>;
  }

  return (
    <Container>
      <Block>
        <Row>
          <div>
            <PatientData>
              <Subtitle>Patient:</Subtitle>
              <Text>
                {`${journal?.patient.name} ${journal?.patient.lastName}`}
              </Text>
            </PatientData>
          </div>
          <div></div>
        </Row>
      </Block>
      <Block>
        {journal?.journalEntries.map((input, inputIdx) => (
          <JournalInput key={inputIdx}>
            <InputLabel>{input.label}</InputLabel>
            <Text>{input.value}</Text>
          </JournalInput>
        ))}
      </Block>
    </Container>
  );
};

export default Journal;
