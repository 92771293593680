import React from 'react';
import { Container } from './style';
import { H1 } from 'components/Styled';
import { ISuccessProps } from './interface';
import { Loader } from 'app-style';
import { Loading } from 'components/ui/Animiations';

// TODO: Render container based on response from server
const Success: React.FC<ISuccessProps> = ({ loading, data, error, closeModal }): JSX.Element => {
  const renderResult = () => {
    if (loading) {
      return <Loading />;
    }
    if (error) {
      // TODO: make error component
      return <p>Couldn't create review, try again later...</p>;
    }

    setTimeout(() => {
      closeModal('');
    }, 2000);
    return <H1 align='center'>Success!</H1>;
  };
  return <Container>{renderResult()}</Container>;
};

export default Success;
