import styled from 'styled-components';

export const Container = styled.picture<{ size: number; shape: string }>`
  display: block;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border-radius: ${({ shape }) => (shape === 'round' ? '50%' : '0')}
  margin-right: 24px;

  img {
    width: 100%;
    height: auto;
  }
`;
