import React from 'react';
import logo from '../../assets/images/rivevo-logo.png';
import { LogoContainer, Img } from './style';

const Logo = () => {
  return (
    <LogoContainer>
      <Img src={logo} alt="Rivevo logo" />
    </LogoContainer>
  );
};

export default Logo;
