import React, { useState, ChangeEvent } from 'react';
import { Container, InputContainer, Label, ErrorText } from './style';
import {
  FieldError,
  FieldValues,
  RefCallBack,
  RegisterOptions,
  UseFormRegister,
  UseFormRegisterReturn,
} from 'react-hook-form';
import Icon from '../Icon';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { COLORS } from 'style';

interface IProps {
  label: string;
  name: string;
  id: string;
  type?: 'text' | 'phone' | 'email' | 'password' | 'date' | 'number';
  inputType?: 'form' | 'normal';
  error?: FieldError | undefined;
  rules?: RegisterOptions;
  active?: boolean;
  locked?: boolean;
  predicted?: string;
  tooltip?: JSX.Element;
  value?: string | number | undefined;
  register?: (name: any, options?: RegisterOptions) => UseFormRegisterReturn; // even name is a string, UseFormRegisterReturn expects the infered string literal.
  regOptions?: RegisterOptions;
  marginBottom?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  flex?: boolean;
  inputRef?: RefCallBack;
}

const FormInput: React.FC<IProps> = ({
  label,
  name,
  id,
  register,
  regOptions,
  value,
  type = 'text',
  inputType = 'normal',
  error,
  locked = false,
  active = true,
  marginBottom,
  tooltip,
  flex,
  onChange,
  inputRef,
}): JSX.Element => {
  const [inputActive, setInputActive] = useState((locked && active) || false);
  // const fieldClassName = `field ${(locked ? inputActive : inputActive || value) && 'active'} ${
  //   locked && !inputActive && 'locked'
  // }`;

  const registerOptions = regOptions || {
    required: 'Dette felt er påkrævet',
    // minLength: {
    //   value: 6,
    //   message: 'Min length is 2',
    // },
  };

  // console.log('value: ', value);

  // const constructProps = () => {
  //   if (register) {
  //     return
  //   }
  // }

  return (
    <Container marginBottom={marginBottom} flex={flex}>
      <div className='input-label'>
        <Label>{label}</Label>
        {tooltip}
        {error && <ErrorText role='alert'>{error.message}</ErrorText>}
      </div>
      <InputContainer error={!!error}>
        <input
          id={id}
          type={type}
          {...(onChange && { onChange, value })}
          {...(register && register(name, registerOptions))}
          aria-invalid={error ? 'true' : 'false'}
          onFocus={() => !locked && setInputActive(true)}
          onBlur={() => !locked && setInputActive(false)}
        />
        {error && <Icon icon={<IoAlertCircleOutline />} color={COLORS.Primary.red} />}
      </InputContainer>
    </Container>
  );
};

export default FormInput;
