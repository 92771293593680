import styled from 'styled-components';

export const Input = styled.div<{
  disabled: boolean;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;

  input {
    margin-right: 6px;
  }

  div.label-checkbox {
    flex: 0.2;

    label {
      color: ${({ disabled }) => (disabled ? '#bbb' : '#444')};
      text-transform: capitalize;
    }
  }

  div.input-wrapper {
    display: flex;
    align-items: center;
    margin-right: 24px;

    span {
      margin-right: 6px;
      text-transform: capitalize;
      font-weight: 500;
      color: ${({ disabled }) => (disabled ? '#bbb' : '#444')};
    }
  }
`;
