import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  margin-bottom: 12px;

  /* &:not(:last-child) {
  } */
`;

export const ReviewHeader = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;

  .review-header-info {
    display: flex;
    flex-flow: row;

    .header-content {
      flex-flow: column;
    }
  }
`;

export const ReviewBody = styled.div`
  padding: 24px 0;
`;

export const ReviewFooter = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  border-top: 1px solid ${COLORS.Primary.offwhite};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
`;

export const ReviewActions = styled.div`
  display: flex;
  flex-flow: row;
  position: relative;

  button {
    font-size: 18px;
    background: none;
    border: none;
    outline: none;
    padding: 6px;
    cursor: pointer;
    color: ${COLORS.grey};

    &:hover {
      color: ${COLORS.Primary.blue};
    }
  }
`;

export const ReviewOptions = styled.div`
  position: absolute;
  bottom: 40px;
  padding: 6px;
  right: 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  min-width: 150px;
`;

export const Option = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  border-radius: 6px;

  .option-text {
    display: flex;
    flex-flow: column;
  }

  &:hover {
    background: ${COLORS.Primary.blueWhite};
    color: ${COLORS.Primary.blue};
  }
`;

export const OptionText = styled.span`
  font-size: 14px;
  white-space: nowrap;
`;

export const OptionSmallText = styled.span`
  font-size: 12px;
  color: ${COLORS.grey};
`;

export const ReviewButton = styled.button<{ isUpvotedByUser?: boolean }>`
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 16px;
  font-weight: ${({ isUpvotedByUser }) => (isUpvotedByUser ? 500 : 400)};
  color: ${({ isUpvotedByUser }) => (isUpvotedByUser ? COLORS.Primary.blue : COLORS.darkgrey)};
  background: ${({ isUpvotedByUser }) => (isUpvotedByUser ? COLORS.Primary.blueWhite : 'none')};
  border: none;
  cursor: pointer;
  padding: 8px 10px;
  margin-right: 6px;
  border-radius: 6px;

  & > span {
    span {
      font-weight: 400;
      font-size: 14px;
      color: ${COLORS.grey};
      justify-content: baseline;
      margin-left: 3px;
    }
  }

  &:hover {
    background: ${COLORS.Primary.blueWhite};
  }
`;

export const ReviewDate = styled.span`
  /* padding: 12px; */
  font-size: 12px;
  color: ${COLORS.grey};
`;

export const Image = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  margin-right: 24px;
  align-items: center;
  justify-content: center;
`;

export const ImageSource = styled.img`
  height: auto;
  width: 100%;
  border-radius: 6px;
`;

export const DefaultImageSource = styled.div<{
  bgColor: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.bgColor};
  color: ${COLORS.Primary.darkblue};
  font-size: 22px;
  font-weight: 700;
  border-radius: 6px;
`;

export const ReviewTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.Primary.darkblue};
  margin-bottom: 3px;
`;

export const ReviewSubTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 3px;
  color: ${COLORS.grey};
`;

export const Icon = styled.div<{ margin?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ margin }) => (margin ? `${margin}px` : '3px')};
`;

export const ProviderReponse = styled.div`
  background: ${COLORS.Primary.blueWhite};
  border-radius: 6px;
  padding: 24px;
  margin-top: 24px;

  .response-title {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }
`;

export const ResponseTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.Primary.darkblue};
  margin-bottom: 3px;
`;
