import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import da from 'date-fns/locale/da';
import { TimeInput } from './style';
import { IoTimeOutline } from 'react-icons/io5';
import { format } from 'date-fns';
registerLocale('da', da);

interface IProps {
  value: string | Date;
  onChange(date: string): void;
  disabled: boolean;
}

const CustomInput = React.forwardRef(
  (
    { onClick, value, placeholder, disabled }: React.HTMLProps<HTMLDivElement>,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <TimeInput disabled={disabled} onClick={onClick} ref={ref}>
        <label>{value || placeholder}</label>
        <IoTimeOutline />
      </TimeInput>
    );
  }
);

const TimePicker: React.FC<IProps> = ({ value, onChange, disabled }) => {
  const onChangeHandler = (date: Date) => {
    const strDate = format(date, 'HH:mm');
    onChange(strDate);
  };

  return (
    <DatePicker
      selected={new Date(`01/01/1970 ${value}`)} // converting time string to date object. We don't care about day/month/year
      onChange={(date: Date) => onChangeHandler(date)}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={5}
      timeCaption="Vælg Tid"
      dateFormat="H:mm"
      locale="da"
      disabled={disabled}
      customInput={<CustomInput disabled={disabled} />}
    />
  );
};

export default TimePicker;
