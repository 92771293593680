import react from 'react';
import { IoStar, IoStarHalfOutline } from 'react-icons/io5';
import { Container } from './style';

interface IProps {
  rating: number;
  color?: string;
}

const Stars: React.FC<IProps> = ({ rating = 0, color }): JSX.Element => {
  const splitRating = [rating > 0 ? Math.floor(rating) : Math.ceil(rating), rating % 1];
  const roundedRating = splitRating ? Math.round(splitRating[1] * 2) / 2 : null;

  return (
    <Container color={color}>
      {[...Array(splitRating[0])].map((e, i) => (
        <IoStar key={i} />
      ))}
      {roundedRating === 1 && <IoStar />}
      {roundedRating === 0.5 && <IoStarHalfOutline />}
    </Container>
  );
};

export default Stars;
