import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { COLORS } from 'style';

const style = {
  color: COLORS.Primary.darkblue,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  marginTop: 24,
};

export const PreArrow = (props: any) => {
  const { className, onClick } = props;

  return (
    <div style={{ ...style, left: -40 }} onClick={onClick} className={className}>
      <IoChevronBack size={48} />
    </div>
  );
};

export const NextArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <div style={{ ...style, right: -40 }} onClick={onClick} className={className}>
      <IoChevronForward size={48} />
    </div>
  );
};
