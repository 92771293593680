import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  margin: 12px 0 24px 0;
`;

export const Inputs = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: ${COLORS.Primary.red};
  margin: 12px 0;
`;
