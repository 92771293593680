import React, { useState } from 'react';
import { Block, Container, Row } from '../style';
import { useLazyQuery } from '@apollo/client';
import JournalTable from './JournalTable';
import { IJournal, IPatient } from 'interfaces';
import { GET_JOURNALS } from 'graphql/queries';
import NewJournal from './NewJournal';
import PatientSearch from 'components/admin/PatientSearch';

const Journals = (): JSX.Element => {
  // const inputRef = useRef<HTMLInputElement>(null);
  // const [selectedPatient, setSelectedPatient] = useState<IPatient | null>(null);
  const [newJournalMode, setNewJournalMode] = useState<boolean>(false);
  const [getJournals, { data }] = useLazyQuery(GET_JOURNALS);

  // pretty ugle advanced desctruturing with types and defaults. Eww.
  const { journals = [] }: { journals: IJournal[] } = data || {};

  const handleSelectPatient = (patient: IPatient) => {
    if (patient) {
      getJournals({ variables: { patientId: patient._id } });
    }
  };

  return (
    <>
      {newJournalMode ? (
        <NewJournal cancel={() => setNewJournalMode(false)} />
      ) : (
        <Container>
          <Row>
            <PatientSearch onSelectPatient={handleSelectPatient} />
          </Row>
          {journals.length > 0 && (
            <Block>
              <Row className='test'>
                {journals.length > 0 && <JournalTable journals={journals} />}
              </Row>
            </Block>
          )}
        </Container>
      )}
    </>
  );
};

export default Journals;
