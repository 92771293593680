import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import { ModalContent } from 'components/Modal/style';
import Modal from 'components/Modal';
import { IProduct } from 'interfaces';
import { ButtonsContainer, FormContent, Row } from './style';
import Button from 'components/ui/Button';
import { GET_PROVIDER_ADMIN } from 'graphql/queries';
import { useMutation } from '@apollo/client';
import { Loading, Success } from 'components/ui/Animiations';
import { ADD_PRODUCT, DELETE_PRODUCT } from 'graphql/mutations/provider';
import Toggle from 'components/ui/Toggle';
import { Input } from 'components/ui/Input';
import Tooltip from 'components/ui/Tooltip';
import TextArea from 'components/ui/TextArea';
import { COLORS } from 'style';
import { Text } from 'components/Styled';
import { IoAlarmOutline, IoWarningOutline } from 'react-icons/io5';

interface IProps {
  toggle(modal: string): void;
  onCancel(): void;
  isOpen: boolean;
  selectedProduct: IProduct;
}

const DeleteProductModal: React.FC<IProps> = ({ toggle, onCancel, isOpen, selectedProduct }): JSX.Element => {
  const [success, setSuccess] = useState(false);
  const [deleteProduct, { loading }] = useMutation(DELETE_PRODUCT, {
    refetchQueries: [{ query: GET_PROVIDER_ADMIN }],
    onCompleted: () => setSuccess(true),
  });

  const handleOnDelete = () => {
    deleteProduct({ variables: { productId: selectedProduct._id } });
  };

  const renderContent = () => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
        onCancel();
      }, 2500);

      return <Success />;
    }

    return (
      <>
        <IoWarningOutline size={48} color={COLORS.Primary.red} />

        <Text>
          {selectedProduct.isDefault
            ? 'Du kan ikke slette produktet, da det er dit default. Skift default produkt til et andet, og prøv igen.'
            : 'Er du sikker på, at slette produktet? Dette kan ikke gøres om!'}
        </Text>

        <ButtonsContainer>
          <Button label='Slet produkt' onClick={handleOnDelete} color='red' />
          <Button label='Annuller' variant='hollow' onClick={onCancel} />
        </ButtonsContainer>
      </>
    );
  };

  return (
    <Modal toggle={toggle} isOpen={isOpen} title={'Slet produkt'}>
      <ModalContent align='center'>{loading ? <Loading /> : renderContent()}</ModalContent>
    </Modal>
  );
};

export default DeleteProductModal;
