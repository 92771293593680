import React, { useEffect, useRef, useState } from 'react';
import { MenuContainer, MenuTrigger, Menu, menuVariant } from './style';
import { useOutsideClick } from 'helpers/hooks';
import LoginModal from 'components/Modal/Login';
import NewUserModal from 'components/Modal/newUser';
import { AnimatePresence } from 'framer-motion';
import { useLazyQuery } from '@apollo/client';
import { GET_ME } from 'graphql/queries';
import { useNavigate } from 'react-router-dom';
import noAvatar from 'assets/images/genderless-avatar.jpeg';
import { IUser } from 'interfaces';
import { useAuth } from 'helpers/auth';

interface IProps {
  isAdmin?: boolean;
}

const DropdownMenu: React.FC<IProps> = ({ isAdmin = false }) => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useOutsideClick(dropdownRef, false);
  const [isOpen, toggle] = useState<string>('');
  const [isProvider, setIsProvider] = useState<boolean>(false);
  const { signout, currentUser, getMe } = useAuth();

  useEffect(() => {
    if (!currentUser) {
      getMe();
    }
  }, [currentUser]);

  const menuHandler = async (type: string) => {
    if (type === 'logout') {
      if (currentUser) {
        console.log('logging out: ', currentUser);

        await signout();
      }
    }

    if (type === 'profile') {
      if (currentUser?.providerId) {
        navigate('/admin/userprofile');
      } else {
        navigate('/me/profile');
      }
    }

    toggle(type);
    setIsActive(false);
  };

  return (
    <>
      <MenuContainer>
        <MenuTrigger
          onClick={(e) => {
            e.stopPropagation(); // needed after react 18 update for some reason or onClick will double trigger. Possible bug
            setIsActive(!isActive);
          }}
        >
          {currentUser ? (
            <div>
              <img src={currentUser.profileImage || noAvatar} alt='User avatar' />
              <span>{`${currentUser.name} ${currentUser.lastName}`}</span>
            </div>
          ) : (
            <span>Login</span>
          )}
        </MenuTrigger>
        <AnimatePresence>
          {isActive && (
            <Menu initial={'initial'} animate={'isOpen'} exit={'exit'} variants={menuVariant} ref={dropdownRef}>
              <ul>
                {!currentUser ? (
                  <>
                    <li>
                      <span onClick={() => menuHandler('newUser')}>Sign up</span>
                      <span onClick={() => menuHandler('login')}>Login</span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          menuHandler('login');
                          setIsProvider(true);
                        }}
                      >
                        Login som Behandler
                      </span>
                    </li>
                  </>
                ) : (
                  <li>
                    <span onClick={() => menuHandler('profile')}>{`Min ${
                      currentUser.providerId ? 'Behandler' : ''
                    } Profil`}</span>
                    <span onClick={() => menuHandler('logout')}>Logout</span>
                  </li>
                )}
              </ul>
            </Menu>
          )}
        </AnimatePresence>
      </MenuContainer>
      <LoginModal isOpen={isOpen === 'login'} isProvider={isProvider} toggle={toggle} />
      <NewUserModal isOpen={isOpen === 'newUser'} toggle={toggle} />
    </>
  );
};

export default DropdownMenu;
