import TimePicker from 'components/ui/TimePicker';
import React from 'react';
import { IOpeningHour } from 'interfaces';
import { Input } from './style';

interface IProps {
  value: IOpeningHour;
  onChange(day: IOpeningHour): void;
}

const DayInput: React.FC<IProps> = ({ value, onChange }) => {
  let label = value.day;

  // quick and dirty temporary translation. WILL be moved to language module, when time is there.
  return (
    <Input disabled={!value.isOpen}>
      <div className="label-checkbox">
        <input
          type="checkbox"
          name={value.day}
          checked={value.isOpen}
          onChange={() => onChange({ ...value, isOpen: !value.isOpen })}
        />
        <label>{label}</label>
      </div>
      <div className="input-wrapper">
        <span>fra</span>
        <TimePicker
          value={value.from}
          disabled={!value.isOpen}
          onChange={(time) => onChange({ ...value, from: time })}
        />
      </div>
      <div className="input-wrapper">
        <span>til</span>
        <TimePicker
          value={value.to}
          disabled={!value.isOpen}
          onChange={(time: string) => onChange({ ...value, to: time })}
        />
      </div>
    </Input>
  );
};

export default DayInput;
