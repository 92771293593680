import React, { useState } from 'react';
import StarIcon from './starIcon';
import { Container } from './style';
import { IRaterProps } from '../interface';

const Rater: React.FC<IRaterProps> = ({ rating, setRating }): JSX.Element => {
  const [hoverRating, setHoverRating] = useState<number>(0);

  const onMouseEnter = (index: number) => {
    setHoverRating(index);
  };
  const onSaveRating = (index: number) => {
    setRating(index);
  };
  const onMouseLeave = () => {
    setHoverRating(0);
  };

  return (
    <Container>
      {[1, 2, 3, 4, 5].map((index) => (
        <StarIcon
          key={index}
          index={index}
          hoverRating={hoverRating}
          rating={rating}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onSaveRating={onSaveRating}
        />
      ))}
    </Container>
  );
};

export default Rater;
