import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  background-color: ${COLORS.Primary.blueWhite};
  border: none;
  padding: 6px;
  border-radius: 6px;
  column-gap: 6px;
`;

// export const Icon = styled.div`
//   display: flex;
// `;

export const Input = styled.input`
  border: none;
  background: none;
  width: 100%;
  height: 100%;
  outline: none;

  &::placeholder {
    color: ${COLORS.darkgrey};
  }
`;
