import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0 48px;
  max-width: 800px;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

export const Rows = styled.div`
  padding: 24px 0;
`;

export const ButtonFooter = styled.footer`
  display: flex;
  flex-flow: row;
  column-gap: 12px;
  margin-top: 12px;
`;

export const ModalButton = styled.button``;

export const FormContainer = styled.div`
  form {
    display: flex;
    flex-flow: column;
    row-gap: 12px;
  }
`;
