import { H3 } from 'components/Styled';
import React from 'react';
import { Container, NotificationItem, TimeStamp } from './style';
import { formatDistance } from 'date-fns';
import { INotification } from '..';

interface IProps {
  notifications: INotification[];
}

const NotificationList: React.FC<IProps> = ({ notifications }) => {
  const renderList = () => {
    return notifications.map((notif, i) => {
      return (
        <NotificationItem key={i} isNew={notif.read}>
          <div className='item-header'>
            <H3>{notif.sender}</H3>
            <TimeStamp>
              {formatDistance(notif.createdAt, new Date(), {
                addSuffix: true,
              })}
            </TimeStamp>
          </div>
        </NotificationItem>
      );
    });
  };

  return <Container>{renderList()}</Container>;
};

export default NotificationList;
