import styled, { css } from 'styled-components';

export const Main = styled.div<{
  isLanding: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 72px);
  /* background-color: #ddd; */
  ${(props) =>
    props.isLanding &&
    css`
      margin-top: 72px;
    `}
`;
