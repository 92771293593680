import { gql } from '@apollo/client';
import { CORE_USER_FIELDS } from '../fragments';

export const LOGIN = gql`
  ${CORE_USER_FIELDS}
  mutation Login($input: LoginUserInput!) {
    login(loginUserInput: $input) {
      user {
        ...CoreUserFields
      }
    }
  }
`;

export const SIGNUP = gql`
  mutation Signup($createUserInput: CreateUserInput!) {
    signup(createUserInput: $createUserInput) {
      _id
      email
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const FACEBOOK_LOGIN = gql`
  mutation FacebookLogin {
    facebookLogin
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOTP($otpinput: VerifyOTPInput!) {
    verifyOTP(otpinput: $otpinput) {
      _id
    }
  }
`;

export const REQUEST_PASSWORDRESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPasswordInput($updateUserPasswordInput: UpdateUserPasswordInput!) {
    updateUserPassword(updateUserPasswordInput: $updateUserPasswordInput)
  }
`;
