import { Block, Container, Row, BlockTitle } from '../../style';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { H2 } from 'components/Styled';
import { Controls } from './style';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PROVIDER_ADMIN } from 'graphql/queries';
import {
  // ADD_PROVIDER_JOURNAL_TEMPLATE,
  // ADD_PROVIDER_PRODUCTS,
  UPDATE_PROVIDER_SETTINGS,
} from 'graphql/mutations';
import Button from 'components/ui/Button';
import { IoAdd } from 'react-icons/io5';
import { IJournalTemplate, IProduct, IProviderAdmin } from 'interfaces';
// import Product from './Product';
import ProductsTable from './ProductsTable';
import ProductModal from './Modal';
import SearchField from 'components/ui/SearchField';
import DeleteProductModal from './Modal/deleteProductModal';

const Products = (): JSX.Element => {
  const [journalTemplateState, setJournalTemplateState] = useState<IJournalTemplate[]>([]);
  const [productList, setProductList] = useState<IProduct[]>([]);
  const [filteredProductList, setFilteredProductList] = useState<IProduct[]>([]);
  const [searchStr, setSearchStr] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const [isOpen, toggle] = useState('');
  const { data } = useQuery<{ providerAdmin: IProviderAdmin }>(GET_PROVIDER_ADMIN);
  const [updateProviderSettings, { loading }] = useMutation(UPDATE_PROVIDER_SETTINGS, {
    variables: {
      providerSettings: {
        journalTemplate: journalTemplateState,
        providerProducts: productList,
      },
    },
  });

  // const { journalTemplate } = provider?.settings || {};
  // const { products } = provider || {};

  // Init data from api
  useEffect(() => {
    if (data?.providerAdmin) {
      if (data.providerAdmin.settings.journalTemplate) {
        setJournalTemplateState(data.providerAdmin.settings.journalTemplate);
      }
      if (data.providerAdmin.products.length > 0) {
        setProductList(data.providerAdmin.products);
        setFilteredProductList(data.providerAdmin.products);
      }
    }
  }, [data]);

  useEffect(() => {
    if (isOpen === '') {
      setSelectedProduct(null);
    }
  }, [isOpen]);

  const handleOnSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchStr(e.target.value);
    const filteredProducts = productList.filter((product) => product.name.includes(e.target.value));

    setFilteredProductList([...filteredProducts]);
  };

  const handleOnSelectProduct = (product: IProduct, action: 'delete' | 'edit') => {
    if (action === 'edit') {
      setSelectedProduct(product);
      toggle('product');
    }

    if (action === 'delete') {
      setSelectedProduct(product);
      toggle('deleteProduct');
    }
  };

  const forceDefault = () => {
    // force default, if selectedProduct is already default
    if (selectedProduct?.isDefault) {
      return true;
    }

    // force default if no default product is found (or if no products in list)
    if (!productList.some((product) => product.isDefault)) {
      return true;
    }

    // force default if no default product is found (or if no products in list)
    if (!productList.some((product) => product.isDefault)) {
      return true;
    }

    return false;
  };

  // const removeProductHandler = (index: number) => {
  //   productList.splice(index, 1);
  //   setProductList([...productList]);
  // };

  // const templateInputOnChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
  //   journalTemplateState[index].label = e.target.value;
  //   setJournalTemplateState([...journalTemplateState]);
  // };

  // const productInputOnChange = (product: IProduct, index: number) => {
  //   productList[index] = product;
  //   setProductList([...productList]);
  // };

  // const inputs = journalTemplateState.map((input, inputIdx) => {
  //   return (
  //     <div key={inputIdx}>
  //       <Input
  //         type='text'
  //         value={input.label}
  //         placeholder='Indtast titel på tekstfelt'
  //         onChange={(e) => templateInputOnChange(e, inputIdx)}
  //         name={`input-${inputIdx + 1}`}
  //       />
  //       <div className='remove-item' onClick={() => removeTemplateHandler(inputIdx)}>
  //         <IoTrashOutline />
  //       </div>
  //     </div>
  //   );
  // });

  return (
    <>
      <Container>
        {/* <Block>
        <BlockTitle>
          <H2 noMargin>Journaler</H2>
          <Text>Tilføj ønskede tekstblokke til journalskabelon.</Text>
        </BlockTitle>
        <Row>
          <InputContainer>{inputs}</InputContainer>
        </Row>
        <Button icon={<IoAdd />} label='Tilføj ny tekstblok' onClick={addTemplateHandler}></Button>
      </Block> */}
        <Block>
          <BlockTitle>
            <H2 noMargin>Produkter</H2>
            <Controls>
              <SearchField
                onChange={handleOnSearchChange}
                value={searchStr}
                name='product-search'
                placeholder='Søg efter produkt'
              />
              <Button icon={<IoAdd />} label='Tilføj nyt produkt' onClick={() => toggle('product')}></Button>
            </Controls>
          </BlockTitle>
          <Row>
            <ProductsTable products={filteredProductList} handleOnSelectProduct={handleOnSelectProduct} />
          </Row>
        </Block>
      </Container>
      {data && (
        <ProductModal
          isOpen={isOpen === 'product'}
          productsLength={productList.length}
          toggle={toggle}
          onCancel={() => toggle('')}
          product={selectedProduct}
          forcedDefault={forceDefault()}
        />
      )}

      {selectedProduct && (
        <DeleteProductModal
          isOpen={isOpen === 'deleteProduct'}
          toggle={toggle}
          onCancel={() => toggle('')}
          selectedProduct={selectedProduct}
        />
      )}
    </>
  );
};

export default Products;
