import { COLORS } from 'style';
import styled from 'styled-components';
import { size } from '../../../components/Styled';

export const Container = styled.div`
  inset: 0px;
  order: 2;
  z-index: 8;

  & > div {
    height: 100%;
    @media only screen and (min-width: ${size.sm}) {
      padding-top: 24px;
    }
    .innerContainer {
      background-color: #fff;
      position: sticky;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */

      .content {
        /* border: 1px solid rgb(221, 221, 221); */
        padding: 24px;
        border-radius: 6px;

        .timeslots {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          margin-top: 12px;
        }
      }
    }
  }
  @media only screen and (min-width: ${size.sm}) {
    left: 62.5%;
    position: absolute;
    padding-right: 24px;
  }
`;

export const OpeningHours = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  /* padding: 24px; */
  margin-top: 24px;
  border-radius: 6px;

  div.day {
    display: flex;
    flex: 1;
    flex-flow: column;

    div.day-header {
      position: relative;
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
      text-align: center;
      padding: 12px;
      color: #fff;
      background: ${COLORS.Primary.darkblue};

      .arrow-up {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        transform: translateX(-50%);
      }
    }

    div.day-hours {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      padding: 12px 6px;
      background #fff;
      flex: 1;

      div {
        flex: 0;
        text-align: left;
      }
    }

    &:first-of-type .day-header {
      border-top-left-radius: 6px;
    }

    &:last-of-type .day-header {
      border-top-right-radius: 6px;
    }

    &:first-of-type .day-hours{
      border-bottom-left-radius: 6px;
    }

    &:last-of-type .day-hours {
      border-bottom-right-radius: 6px;
    }
  }
`;

export const Select = styled.div`
  position: relative;
  padding: 12px 0;

  &:last-of-type {
    margin-bottom: 12px;
  }

  label {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
  }

  select {
    position: relative;
    width: 100%;
    padding: 12px 12px;
    border: 1px solid #dbdee1;
    background-color: ${COLORS.Primary.blueWhite};
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    font-size: 13px;
    font-weight: 400;
    /* width: 326px; */
    color: ${COLORS.Primary.darkblue};
    line-height: inherit;
    // remove arrow on older IE
  }
  &::-ms-expand {
    display: none;
  }

  &::after {
    content: '';
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
`;

export const Title = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  margin-bottom: 12px;
`;

export const BookingSlots = styled.div`
  flex: 1;
  /* padding: 24px; */
  /* background: ${COLORS.Primary.blueWhite}; */
  .date-control {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .date-controller {
      font-size: 20px;
      font-weight: 600;
      color: ${COLORS.Primary.darkblue};
      padding: 6px;
      cursor: pointer;

      &.disabled {
        color: ${COLORS.lightgrey};
        pointer-events: none;
      }
    }
    .current-week {
      display: flex;
      width: 100%;
      justify-content: center;

      .date {
        padding: 3px;
        display: flex;
        flex-flow: column;
        align-items: center;
        text-transform: capitalize;
        font-weight: 600;
        flex: 1 1 0px;

        .dayname {
          font-weight: 400;
        }
      }
    }
  }
`;
