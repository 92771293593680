import React from 'react';
import { Image, ImageSource, DefaultImageSource } from './style';

interface IProps {
  imageUrl: string | undefined;
  initials: string;
}

const UserImage = ({ imageUrl, initials }: IProps): JSX.Element => {
  return (
    <Image>
      {imageUrl ? (
        <ImageSource src={imageUrl} />
      ) : (
        <DefaultImageSource bgColor={'#' + Math.floor(Math.random() * 16777215).toString(16)}>
          {initials}
        </DefaultImageSource>
      )}
    </Image>
  );
};

export default UserImage;
