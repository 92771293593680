import styled, { css } from 'styled-components';

const lighten = (value: number) => css`
  filter: brightness(${value + 1});
`;

export const Container = styled.div<{
  height: number;
  isDragging: boolean;
}>`
  display: block;
  position: absolute;
  top: 0%; // set 25% if 15 min past, or 50% if 30 min // TODO: convert start/end time to position
  height: ${(props) => props.height}px;
  padding: 3px;
  left: 3px;
  right: 3px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: ${(props) => (props.isDragging ? '#77d17750' : '#77d177')};
  color: #444;
  z-index: 99;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: ${(props) => (props.isDragging ? 'none' : 'auto')};
  transition: 0.3s;

  &:hover {
    ${lighten(0.1)};
  }
`;
