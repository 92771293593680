import React, { useEffect, useState } from 'react';
import { IProvider, IProviderAvailability, IProviderTimeSLot } from 'interfaces';
import { format } from 'date-fns';
import { AvailabilityListContainer, TimeSlotContainer, TimeSlot } from './style';
import { H3 } from 'components/Styled';
import { da } from 'date-fns/locale';
import BookingModal from 'containers/Provider/Booking/BookingModal';
import { Loading } from 'components/ui/Animiations';

interface IProps {
  availabilityList: IProviderAvailability[];
  provider: IProvider;
  setSelectedSlot(slot: IProviderTimeSLot): void;
}

const AvailabilityList: React.FC<IProps> = ({ setSelectedSlot, availabilityList, provider }): JSX.Element => {
  return (
    <>
      <AvailabilityListContainer>
        {availabilityList.map((availability, i) => {
          return (
            <div key={i} className='availability-list'>
              <H3>{format(new Date(availability.date), 'eeee, dd. MMMM', { locale: da })}</H3>
              <TimeSlotContainer>
                {availability.timeslots.length > 0 ? (
                  availability.timeslots.map((timeslot) => {
                    return (
                      <TimeSlot onClick={() => setSelectedSlot(timeslot)} key={`${timeslot.timeslot}`}>
                        {format(new Date(timeslot.timeslot), 'HH:mm')}
                      </TimeSlot>
                    );
                  })
                ) : (
                  <div className='no-available-timeslots'>Ingen ledige tider</div>
                )}
              </TimeSlotContainer>
            </div>
          );
        })}
      </AvailabilityListContainer>
    </>
  );
};

export default AvailabilityList;
