import { gql } from '@apollo/client';

export const ADD_INVITATION = gql`
  mutation AddInvitation($invitationInput: CreateInvitationInput!) {
    addInvitation(invitationInput: $invitationInput) {
      _id
      email
    }
  }
`;
export const DELETE_INVITATION = gql`
  mutation DeleteInvitation($invitationId: String!) {
    deleteInvitation(invitationId: $invitationId) {
      _id
    }
  }
`;

export const UPDATE_INVITATION = gql`
  mutation ResendInvitation($invitationId: String!) {
    resendInvitation(invitationId: $invitationId) {
      _id
    }
  }
`;

export const GET_INVITATION = gql`
  mutation GetInvitation($token: String!) {
    invitation(token: $token) {
      _id
      provider {
        name
      }
      invitee {
        name
        lastName
        accounts {
          kind
        }
      }
      # email
      # createdAt
      # expiration
    }
  }
`;

export const ADD_USER_CREDENTIALS = gql`
  mutation addUserCredentials($credentialsInput: CredentialsInput!) {
    addUserCredentials(credentialsInput: $credentialsInput) {
      _id
      provider {
        name
      }
      invitee {
        name
        lastName
        accounts {
          kind
        }
      }
      # email
      # createdAt
      # expiration
    }
  }
`;
