import Navigation from 'components/admin/Navigation';
import Topbar from 'components/admin/Topbar';
import { Outlet } from 'react-router-dom';
import { Content } from './style';

const AdminLayout = (): JSX.Element => (
  <>
    <Navigation />
    <Content>
      <Topbar />
      <Outlet />
    </Content>
  </>
);

export default AdminLayout;
