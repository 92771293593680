import { gql } from '@apollo/client';
import { CORE_PROVIDER_USER_FIELDS } from '../fragments';

export const GET_PROVIDER_USERS = gql`
  ${CORE_PROVIDER_USER_FIELDS}
  query GetProviderUsers {
    users {
      ...CoreProviderUserFields
      email
      phone
      roles
    }
  }
`;
