import { gql } from '@apollo/client';

export const GET_JOURNALS = gql`
  query GetJournals($patientId: String!) {
    journals(patientId: $patientId) {
      _id
      journalId
      createdAt
      patient {
        _id
        name
        lastName
        email
        phone
        createdAt
      }
    }
  }
`;

export const GET_JOURNAL = gql`
  query GetJournal($journalId: String!) {
    journal(journalId: $journalId) {
      _id
      journalId
      createdAt
      updatedAt
      providerAccess
      patient {
        _id
        name
        lastName
        email
        phone
        createdAt
      }
      journalEntries {
        label
        type
        value
      }
    }
  }
`;
