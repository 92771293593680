import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  width: 300px;
  padding: 12px 12px 0 12px;
`;

export const DateTitle = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding: 0 0 12px 7px;
  text-transform: capitalize;
`;

export const DateControls = styled.div`
  display: flex;
  flex-flow: row;

  .date-control {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 20px;
    padding: 6px;
    color: ${COLORS.Primary.darkblue};
    cursor: pointer;
  }
`;

export const CalendarContent = styled.div`
  height: 280px;

  table {
    display: table;
    width: 100%;
    text-align: center;

    thead {
      display: table-header-group;
      justify-content: space-between;
      tr {
        th {
          font-weight: 400;
          color: ${COLORS.darkgrey};
          text-transform: capitalize;
        }
      }
    }
    tbody {
      tr {
        td {
          /* padding: 12px; */
          color: ${COLORS.Primary.darkblue};
          width: 36px;
          height: 36px;
          cursor: pointer;

          &:hover:not(.disabled) {
            background: ${COLORS.Primary.lightblue};
            border-radius: 6px;
          }

          &.disabled {
            color: ${COLORS.lightgrey};
            cursor: default;
          }

          &.selected:not(.disabled) {
            background: ${COLORS.Primary.blue};
            border-radius: 6px;
            color: ${COLORS.white};
          }
        }
      }
    }
  }
`;

export const LocationInput = styled.div`
  display: flex;
  border-radius: 6px;
  /* border: 1px solid #ddd; */
  background-color: ${COLORS.Primary.blueWhite};
  padding: 6px;
  margin-bottom: 6px;

  input {
    background: none;
    outline: none;
    width: 100%;
    border: none;
    height: 24px;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  color: ${COLORS.grey};
  /* position: absolute; */
`;

export const CityList = styled.div`
  display: flex;
  flex-flow: column;
  height: 300px;
  overflow: scroll;

  ul {
    padding: 0;

    li {
      text-decoration: none;
      list-style: none;
      padding: 6px;
      cursor: pointer;
      border-radius: 6px;

      &.active,
      &:hover {
        background: ${COLORS.Primary.lightblue};
      }
    }
  }
`;
