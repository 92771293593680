import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_ME } from 'graphql/queries';
import { LOGIN, LOGOUT, SIGNUP } from 'graphql/mutations/auth';
import { useNavigate } from 'react-router-dom';
import { IUser } from 'interfaces';

export const useAuth = () => {
  const [getMe, { data: userData, error: userError }] = useLazyQuery<{ me: IUser }>(GET_ME, {});
  const [login, { data: loginData, error: loginError }] = useMutation(LOGIN);
  const [logout] = useMutation(LOGOUT);
  const client = useApolloClient();
  const navigate = useNavigate();
  const { me } = client.readQuery<{ me: IUser }>({ query: GET_ME }) || {};

  const signup = () => null;

  const signin = async (input: { email: string; password: string }) => {
    return login({
      variables: { input },
      refetchQueries: [{ query: GET_ME }],
      awaitRefetchQueries: true,
      update: (store, { data }) => {
        if (!data) return null;
        store.writeQuery({
          query: GET_ME,
          data: {
            me: data.login.user,
          },
        });
      },
    });
  };

  const socialSignin = async (provider: 'facebook') => {
    const url = process.env.NODE_ENV === 'production' ? 'https://api.rivevo.com' : 'http://localhost:3001';
    if (provider === 'facebook') {
      window.open(`${url}/auth/facebook?return_url=${window.location.href}`, '_self');
      console.log(`${url}/auth/facebook?return_url=${window.location.href}`);

      // return new Promise((resolve) => setTimeout(resolve, 2000));
      getMe();
    }
  };

  const signout = async () => {
    await logout({
      onCompleted(data, clientOptions) {
        client.resetStore();
        navigate('/');
      },
      update: (store, { data }) => {
        store.writeQuery({
          query: GET_ME,
          data: {
            me: null,
          },
        });
      },
    });
  };

  const resetPassword = () => null;

  return {
    signup,
    signin,
    socialSignin,
    signout,
    getMe,
    resetPassword,
    currentUser: me ? me : userData?.me,
  };
};
