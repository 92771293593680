import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  padding: 24px 0;

  .filter-title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    span {
      margin-left: 6px;
    }
  }
`;

export const Filters = styled.div`
  display: inline-flex;
`;

export const FilterButton = styled.button`
  position: relative;
  display: inline-flex;
  background-color: #fff;
  border: 1px solid ${COLORS.Primary.darkblue};
  border-radius: 30px;
  padding: 8px 16px;
  margin: 0 6px 0 0;
  outline: none;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.Primary.darkblue};
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    border-color: ${COLORS.Primary.blue};
    color: ${COLORS.Primary.blue};
  }

  .filters-active {
    position: absolute;
    display: flex;
    right: -3px;
    top: -3px;
    justify-content: center;
    border-radius: 45px;
    border: 2px solid #fff;
    width: 14px;
    height: 14px;
    background-color: ${COLORS.Primary.darkblue};
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
  }
  .button-icon {
    display: flex;
    margin-right: 6px;
    font-size: 18px;
  }
`;
