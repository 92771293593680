import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Containers
import Overview from 'containers/admin/Overview';
import Profile from 'containers/admin/Settings/Profile';
import Journal from 'containers/admin/Journals/Journal';
import Products from 'containers/admin/Settings/Products';
import Bookings from 'containers/admin/Bookings';
import Users from 'containers/admin/Users';
import Home from 'containers/Home';
import PageNotFound from 'containers/PageNotFound';
import About from 'containers/About';
import Provider from 'containers/Provider';
import Search from 'containers/Search';
import Journals from 'containers/admin/Journals';
import PUserProfile from 'containers/admin/UserProfile';
import UserProfile from 'containers/UserProfile';

// HOC
import MainLayout from 'hoc/Layouts/Main';
import AdminLayout from 'hoc/Layouts/Admin';
import ProtectedRoute from 'hoc/Routes/protectedRoute';
import Terms from 'containers/About/Terms';
import Privacy from 'containers/About/Privacy';
import ResetPassword from 'containers/ResetPassword';
import Invitation from 'containers/Invitation';

const AllRoutes: React.FC = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path='/' element={<Home />} />
        <Route path='/search' element={<Search />} />
        {['kiropraktor', 'psykolog', 'fysioterapeut', 'tandlaege', 'akupunktoer'].map((profession) => (
          <Route key={profession} path={`/${profession}/:id`} element={<Provider />} />
        ))}
        <Route path='/about' element={<About />} />
        <Route path='/about/terms' element={<Terms />} />
        <Route path='/about/privacy' element={<Privacy />} />

        <Route path='/*' element={<PageNotFound />} />
        <Route path='/me/profile' element={<UserProfile />} />
      </Route>
      <Route path='/auth/resetPassword' element={<ResetPassword />} />
      <Route path='/invitation' element={<Invitation />} />
      <Route
        element={
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path='/admin'
          element={
            <ProtectedRoute>
              <Overview />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin/profile'
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin/bookings'
          element={
            <ProtectedRoute>
              <Bookings />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin/products'
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin/users'
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin/journals'
          element={
            <ProtectedRoute>
              <Journals />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin/journals/:id'
          element={
            <ProtectedRoute>
              <Journal />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin/userprofile'
          element={
            <ProtectedRoute>
              <PUserProfile />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
};

export default AllRoutes;
