import React, { useEffect, useState } from 'react';
import { Header, Container, HeaderContainer } from './style';
import Logo from '../Logo';
import DropdownMenu from './DropdownMenu';
import Notification from './Notifications';
import Search from './Search';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

const Toolbar = () => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState<boolean>(false);

  const { pathname } = useLocation();
  const isLanding = pathname === '/' ? true : false;

  useEffect(() => {
    const onScroll = () => setScrolled(window.pageYOffset > 10);
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll);
    }

    // clean up evenlistener on unmount
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Container isLanding={isLanding}>
      <Header scrolled={scrolled} isLanding={isLanding}>
        <HeaderContainer>
          <div className='logo' onClick={() => navigate('/')}>
            <Logo />
          </div>
          {/* <Navigation /> */}
          <Search />
          <div className='right'>
            <Notification />
            <DropdownMenu />
          </div>
        </HeaderContainer>
      </Header>
    </Container>
  );
};

export default Toolbar;
