import React, { useState, ChangeEvent } from 'react';
import { Container } from './style';
import { FieldError, RefCallBack, RegisterOptions } from 'react-hook-form';
import defaultImage from '../../../assets/images/genderless-avatar.jpeg';

interface IProps {
  src?: string;
  size?: number;
  shape?: string;
  alt?: string;
}

const ProfilImage: React.FC<IProps> = ({ src, size = 32, shape = 'round', alt }): JSX.Element => {
  return (
    <Container size={size} shape={shape}>
      <img src={src || defaultImage} alt={alt || 'user profile image'} />
    </Container>
  );
};

export default ProfilImage;
