import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-flow: column;
  padding: 24px;
`;

export const UserList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 12px;
  row-gap: 12px;
  flex-flow: column;
  /* background: ${COLORS.white}; */
  border-radius: 6px;
  margin-top: 12px;
`;

export const UserImage = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 24px;
  transition: 0.2s;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const User = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 24px;
  background: #fff;
  border-radius: 6px;

  &:hover ${UserImage} {
    transition: 0.2s;
    transform: scale(1.15);
  }
`;

export const UserName = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: ${COLORS.Primary.darkblue};
`;

export const UserTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.11em;
  line-height: 1.6;
  color: ${COLORS.Primary.lightblue};
  text-transform: uppercase;
`;

export const ProfileImage = styled(UserImage)`
  position: absolute;
  background-color: #fff;
  top: -30px;
  left: 30px;
  box-shadow: none;
  border: 5px solid #fff;
`;

export const ProfileHeader = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 161px;
`;

export const ProfileName = styled.div`
  font-size: 30px;
  font-weight: 600;
  color: ${COLORS.Primary.darkblue};
`;

export const ProfileUserTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${COLORS.Primary.lightblue};
  text-transform: uppercase;
  letter-spacing: 1.8;
  min-height: 23px;
`;

export const ProfileContent = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 36px;
`;

export const Text = styled.div<{
  margin?: number;
}>`
  margin-bottom: ${({ margin }) => (margin ? `${margin}px` : '0')};
`;
