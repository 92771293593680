import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Content, ModalContent, Seperator, SmallLink, Text, ErrorMessage } from '../style';
import Modal from '..';
import { ISignInForm } from 'interfaces';
import Button from 'components/ui/Button';
import { Input } from 'components/ui/Input';
import { useAuth } from 'helpers/auth';
import { useNavigate } from 'react-router-dom';
import ResetPasswordForm from './resetPasswordForm';
import { Loading, Success } from 'components/ui/Animiations';

interface IProps {
  toggle(modal: string): void;
  isOpen: boolean;
  isProvider: boolean;
}
type ILoginState = 'login' | 'reset' | 'success';

const LoginModal: React.FC<IProps> = ({ toggle, isOpen, isProvider }): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [loginState, setLoginState] = useState<ILoginState>('login');
  const [formError, setFormError] = useState<string>('');
  const navigate = useNavigate();
  const { signin, socialSignin, getMe, currentUser } = useAuth();

  // signin with facebook
  const facebookLoginHandler = async () => {
    setLoading(true);
    socialSignin('facebook').then(async () => {
      if (currentUser) {
        setLoading(false);
        toggle('');
        if (currentUser.providerId) {
          console.log('navigate to admin');
          navigate('/admin');
        }
        console.log('navigate to start');
        navigate(-2);
      }
    });
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onLogin = async (form: ISignInForm) => {
    setLoading(true);

    // login via email and password
    signin(form)
      .then(() => {
        toggle('');
        navigate('/admin');
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setFormError(e.message);
        console.log(e.message);
      });
  };

  const renderLoginForm = () => (
    <>
      {formError && <ErrorMessage>{formError}</ErrorMessage>}
      <form onSubmit={handleSubmit(onLogin)}>
        <Input
          name='email'
          label='Email'
          id='email-input'
          type='email'
          inputType='form'
          error={errors.email}
          register={register}
          regOptions={{
            validate: {
              matchPattern: (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email er ikke korrekt',
            },
          }}
          marginBottom
        />
        <Input
          name='password'
          label='Password'
          id='password-input'
          type='password'
          inputType='form'
          error={errors.password}
          register={register}
          marginBottom
        />
        <Button label='Log ind' type='submit' marginTop={12} flex />
        <SmallLink>
          <a href='#' onClick={() => setLoginState('reset')}>
            Glemt dit kodeord?
          </a>
        </SmallLink>
      </form>
      <Seperator>
        <span>Eller</span>
      </Seperator>
      <Button some='facebook' flex onClick={facebookLoginHandler} />
    </>
  );

  const renderResetPasswordForm = () => {
    return <ResetPasswordForm onSuccess={onSuccess} />;
  };

  const renderResetPasswordSuccess = () => {
    return (
      <Content>
        <Success />
        <Text>
          Hvis email adressen er registreret hos os, vil du modtage et link på din email, inden for kort tid.{' '}
        </Text>
      </Content>
    );
  };

  const onSuccess = () => {
    setLoginState('success');
  };

  useEffect(() => {
    if (!isOpen) {
      // reset state on modal close
      setLoginState('login');
    }
  }, [isOpen]);

  // useEffect(() => {
  //   if (error) console.log(error);
  //   if (loading) console.log(loading);
  // }, [error, loading]);

  return (
    <Modal toggle={toggle} isOpen={isOpen} title={loginState === 'reset' ? 'Nulstil kodeord' : 'Login'}>
      <ModalContent>
        {loginState === 'login' && !loading && renderLoginForm()}
        {loginState === 'reset' && !loading && renderResetPasswordForm()}
        {loginState === 'success' && !loading && renderResetPasswordSuccess()}
        {loading && <Loading />}
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
