import DropdownMenu from 'components/Toolbar/DropdownMenu';
import React from 'react';
import { Container } from './style';
import ProviderSearch from './ProviderSearch';
import { useAuth } from 'helpers/auth';
import { useQuery } from '@apollo/client';
import { IProvider } from 'interfaces';
import { GET_PROVIDER_NAME } from 'graphql/queries';

const Topbar: React.FC = (): JSX.Element => {
  const { currentUser } = useAuth();
  const { data } = useQuery<{ providerAdmin: IProvider }>(GET_PROVIDER_NAME);

  return (
    <Container>
      {currentUser?.roles.some((el) => ['ADMIN', 'OWNER'].includes(el)) && data?.providerAdmin && (
        <ProviderSearch provider={data.providerAdmin} />
      )}
      <DropdownMenu isAdmin />
    </Container>
  );
};

export default Topbar;
