import styled from 'styled-components';

export const TimeInput = styled.div<{
  disabled?: boolean;
}>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 6px;
  border: solid 1px #cbd4c9;
  border-radius: 6px;
  width: 80px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) => (disabled ? '#bbb' : '#444')};

  label {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;
