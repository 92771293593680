import React, { useContext } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { SectionContent } from 'containers/Home/style';
import { SContainer } from '../../style';
// import AcupuncturistLottie from 'assets/lotties/AcupuncturistLottie.json';
// import ChiropractorLottie from 'assets/lotties/ChiropractorLottie.json';
// import DentistLottie from 'assets/lotties/DentistsLottie.json';
// import PsychologistLottie from 'assets/lotties/PsychologistLottie.json';
import { LottieCard, LottieWrapper, Title, CardTitle } from './style';
import Tooltip from 'components/ui/Tooltip';
import { store } from 'store/store';
import { SetFilter } from 'store/store.actions';
import { useQuery } from '@apollo/client';
import { ISpecialization } from 'interfaces';
import { GET_SPECIALIZATIONS } from 'graphql/queries';
import { useNavigate } from 'react-router-dom';

interface IProps {
  specializations?: ISpecialization[];
  loading: boolean;
}

const TopSpecialties: React.FC<IProps> = ({ specializations = [], loading }) => {
  // API

  const iconSize: { height: string; width: string } = { height: '120px', width: '120px' };
  const {
    state: { filter },
    dispatch,
  } = useContext(store);
  const navigate = useNavigate();

  return (
    <SContainer bgColor='#ffffff'>
      <SectionContent>
        <Title>
          <h2>De mest efterspurgte specialiteter</h2>
        </Title>
        <LottieWrapper>
          {specializations.map((specialty: ISpecialization) => {
            const lottieUrl = require(`assets/lotties/${specialty.name}Lottie.json`);
            // console.log(specialty);

            // const LottieAnimation = React.lazy(() => import('assets/lotties/AcupuncturistLottie.json'));
            return (
              <LottieCard
                key={specialty.name}
                onClick={(e: React.MouseEvent) => {
                  const { _id, name } = specialty;
                  dispatch(SetFilter({ ...filter, specialty: { _id, name } }));
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  navigate('/search');
                }}
              >
                <Player hover keepLastFrame speed={0.5} src={lottieUrl} style={iconSize}></Player>
                <CardTitle>{specialty.name}</CardTitle>
              </LottieCard>
            );
          })}
          {/* <LottieCard>
            <Player hover keepLastFrame speed={0.5} src={ChiropractorLottie} style={iconSize}></Player>
            <CardTitle>Kiropraktor</CardTitle>
          </LottieCard>
          <LottieCard>
            <Player hover keepLastFrame speed={0.5} src={DentistLottie} style={iconSize}></Player>
            <CardTitle>Tandlæge</CardTitle>
          </LottieCard>
          <LottieCard>
            <Player hover keepLastFrame speed={0.5} src={PsychologistLottie} style={iconSize}></Player>
            <CardTitle>Psykolog</CardTitle>
          </LottieCard> */}
        </LottieWrapper>
      </SectionContent>
    </SContainer>
  );
};

export default TopSpecialties;
