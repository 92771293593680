import { H3 } from 'components/Styled';
import styled from 'styled-components';

export const JournalInput = styled.div`
  display: flex;
  flex-flow: column;
  margin: 12px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const PatientData = styled.span`
  display: flex;
  align-items: center;
  flex-flow: row;
`;

export const InputLabel = styled(H3)``;

export const InputTextArea = styled.textarea``;
