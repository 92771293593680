import React from 'react';
import { Nav } from './style';
import { IoHome, IoPeople, IoSettings, IoNewspaper, IoBarChart, IoCalendar } from 'react-icons/io5';
import NavItem from './NavItem';
import Accordion from './Accordion';

const Navigation: React.FC = (): JSX.Element => {
  return (
    <Nav>
      <div className='inner'>
        <NavItem link='/admin' exact icon={<IoHome size={20} />}>
          Oversigt
        </NavItem>
        <NavItem link='/admin/bookings' icon={<IoCalendar size={20} />}>
          Aftaler
        </NavItem>
        <NavItem link='/admin/users' icon={<IoPeople size={20} />}>
          Brugere
        </NavItem>
        <Accordion icon={<IoSettings size={20} />} label='Indstillinger'>
          <NavItem link='/admin/profile'>Profil</NavItem>
          <NavItem link='/admin/products'>Produkter</NavItem>
        </Accordion>
        <NavItem link='/admin/journals' icon={<IoNewspaper size={20} />}>
          Journaler
        </NavItem>
        {/* <NavItem link="/admin/analytics" icon={<IoBarChart size={20} />}>
          Analytics
        </NavItem> */}
      </div>
    </Nav>
  );
};

export default Navigation;
