import styled from 'styled-components';
import { IoNotificationsOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';

export const NotificationContainer = styled.div`
  position: relative;
`;

export const NotificationTrigger = styled.button<{
  isActive: boolean;
}>`
  display: flex;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin-right: 12px;
  border: none;
  background-color: ${(props) => (props.isActive ? '#eee' : 'transparent')};
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: #eee;
  }
`;

export const Icon = styled(IoNotificationsOutline)`
  font-size: 18px;
  color: #444;
`;

export const Header = styled.div`
  display: flex;
  padding: 18px 24px;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.button`
  display: flex;
  font-size: 18px;
  color: #444;
  padding: 18px 24px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const NotificationContent = styled(motion.div)`
  display: flex;
  flex-flow: column;
  background: #ffffff;
  border-radius: 6px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 360px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
`;

// window animation
export const notificationVariant = {
  initial: { opacity: 0 },
  isActive: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
};
