import { IProvider, IProviderAvailability } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { Calendar, Container } from './style';
import TimeSlotsModal from './TimeslotsModal';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';

interface IProps {
  noPadding?: boolean;
  provider: IProvider;
  loading: boolean;
}

const ProviderTimeSlots: React.FC<IProps> = ({ provider, noPadding = false, loading }): JSX.Element => {
  const [selectedDatelot, setSelectedDatelot] = useState<IProviderAvailability | null>(null);
  const [isOpen, toggle] = useState('');
  const [numOfTimeSlots, setTumOfTimeSlots] = useState(0);

  const handleOnTimeSlotClick = (datelot: IProviderAvailability) => {
    if (datelot.timeslots.length > 0) {
      setSelectedDatelot(datelot);
      toggle('timeslots');
    }
  };

  const getNumAppts = (datelot: IProviderAvailability): number => {
    const numOfAppts = datelot.timeslots.map((timeslot) => timeslot.availablePUsers).flat().length;

    return numOfAppts;
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedDatelot(null);
    }
  }, [isOpen]);

  return (
    <Container>
      <Calendar noPadding={noPadding}>
        {provider.availability.map((datelot, i) => {
          if (loading) {
            return (
              <div key={i} className='timeslot-placeholder blink'>
                <div />
                <div />
              </div>
            );
          }
          return (
            <a
              className={`datelot${datelot.timeslots.length === 0 ? ' no-timeslots' : ''}`}
              key={i}
              onClick={() => handleOnTimeSlotClick(datelot)}
            >
              <div className='datelot-container'>
                <div className='datelot-date'>
                  <span>
                    {format(new Date(datelot.date), 'eee', {
                      locale: da,
                    }).replace('.', '')}
                  </span>
                  <span>
                    {format(new Date(datelot.date), 'd. MMM', {
                      locale: da,
                    }).slice(0, -1)}
                  </span>
                </div>

                <div className='available-appts'>
                  <span>{datelot.timeslots.length > 0 ? getNumAppts(datelot) : 'ingen'}</span>
                  <span>ledige tider</span>
                </div>
              </div>
            </a>
          );
        })}
      </Calendar>
      {selectedDatelot && (
        <TimeSlotsModal
          isOpen={isOpen === 'timeslots' && !!selectedDatelot?.date}
          onCancel={() => toggle('')}
          selectedDatelot={selectedDatelot}
          provider={provider}
        />
      )}
    </Container>
  );
};

export default ProviderTimeSlots;
