import React, { useState, ChangeEvent } from 'react';
import { Container, Label } from './style';
import { FieldError, RefCallBack, RegisterOptions } from 'react-hook-form';

interface IProps {
  label: string;
  id: string;
  options: ISelectOption[];
  value: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export interface ISelectOption {
  value: string;
  label: string;
}

const Input: React.FC<IProps> = ({ label, id, options, value, onChange }): JSX.Element => {
  return (
    <Container>
      <Label>{label}</Label>
      <select value={value} onChange={onChange} id={id}>
        <option key='default' value=''>
          {`Vælg ${label.toLowerCase()}...`}
        </option>
        {options.map((opt, i) => (
          <option key={i} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    </Container>
  );
};

export default Input;
