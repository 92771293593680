import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background-color: #f2f1f9;
  width: 300px;
  border: 1px solid #e1deee;
  border-radius: 6px;
  color: ${COLORS.darkgrey};
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-left: 24px;
  outline: none;
  border: none;
  background-color: transparent;
`;

export const InputResults = styled.div<{ noResults?: boolean }>`
  position: absolute;
  background-color: ${COLORS.white};
  border-radius: 6px;
  border: 1px solid transparent;
  width: inherit;
  z-index: 99;
  box-shadow: 0px 2px 4px #00000026;
  padding: ${({ noResults }) => (noResults ? 12 : 0)}px;

  .no-results {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    img {
      height: 30px;
      width: auto;
    }
  }

  .patient {
    display: flex;
    flex-flow: column;
    padding: 12px;
    cursor: pointer;
    color: ${COLORS.darkgrey};
    font-weight: 500;

    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .sub-text {
      display: flex;
      align-items: flex-start;
      font-size: 12px;
      color: #999;

      span {
        margin-right: 6px;
        margin-left: 6px;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3px;
      }
    }
  }

  .active {
    background-color: ${COLORS.Primary.darkblue};
    span {
      color: ${COLORS.white};
    }

    .sub-text {
      color: #ccc;
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 8px;
  top: 10px;
  font-size: 20px;
  color: #666;
`;
