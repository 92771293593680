import React, { ChangeEvent, useEffect, useState } from 'react';
import { ModalContent } from 'components/Modal/style';
import Modal from 'components/Modal';
import { IProduct } from 'interfaces';
import { ButtonsContainer, FormContent, Row } from './style';
import Button from 'components/ui/Button';
import { GET_PROVIDER_ADMIN } from 'graphql/queries';
import { useMutation } from '@apollo/client';
import { Loading, Success } from 'components/ui/Animiations';
import { ADD_PRODUCT, UPDATE_PRODUCT } from 'graphql/mutations/provider';
import Toggle from 'components/ui/Toggle';
import { Input } from 'components/ui/Input';
import Tooltip from 'components/ui/Tooltip';
import TextArea from 'components/ui/TextArea';

interface IProps {
  toggle(modal: string): void;
  onCancel(): void;
  isOpen: boolean;
  product: IProduct | null;
  productsLength: number;
  forcedDefault: boolean;
}

interface IProductInput {
  _id?: string;
  name: string;
  description: string;
  price: number;
  durationInMinutes: number;
  isActive: boolean;
  isPrivate: boolean;
  isDefault: boolean;
  reference: string;
}

const ProductModal: React.FC<IProps> = ({
  toggle,
  onCancel,
  isOpen,
  product,
  productsLength,
  forcedDefault,
}): JSX.Element => {
  const [productInput, setProductInput] = useState<IProductInput>({
    name: '',
    description: '',
    price: 0,
    durationInMinutes: 30,
    isPrivate: false,
    isActive: true,
    isDefault: forcedDefault,
    reference: 'test-reference',
  });
  const [success, setSuccess] = useState(false);
  const [addProduct, { loading: addLoading }] = useMutation(ADD_PRODUCT, {
    refetchQueries: [{ query: GET_PROVIDER_ADMIN }],
    onCompleted: () => setSuccess(true),
  });
  const [updateProduct, { loading: updateLoading, error: updateError, reset }] = useMutation(UPDATE_PRODUCT, {
    refetchQueries: [{ query: GET_PROVIDER_ADMIN }],
    onCompleted: () => setSuccess(true),
  });

  const loading = addLoading || updateLoading;

  useEffect(() => {
    if (product !== null) {
      const { __typename, ...rest } = product as any;
      setProductInput(rest as IProduct);
    } else {
      setProductInput({
        name: '',
        description: '',
        price: 0,
        durationInMinutes: 30,
        isPrivate: false,
        isActive: true,
        isDefault: forcedDefault,
        reference: 'test-reference',
      });
    }
  }, [product]);

  useEffect(() => {
    if (!isOpen && updateError) {
      // reset apollo errors when modal closes.
      reset();
    }
  }, [isOpen]);

  const handleOnSave = () => {
    if (productInput._id) {
      updateProduct({ variables: { productInput } });
    } else {
      addProduct({ variables: { productInput } });
    }
  };

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    }

    if (updateError) {
      return <div>ERROR, NO AUTHORIZATION!</div>;
    }

    if (success) {
      setTimeout(() => {
        setSuccess(false);
        onCancel();
      }, 2500);

      return <Success />;
    }

    return renderForm();
  };

  const renderForm = () => {
    return (
      <FormContent>
        <Input
          label='Navn'
          name={'product-name'}
          id='product-name-input'
          value={productInput.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setProductInput({ ...productInput, name: e.target.value })}
        />
        <Row>
          <Input
            label='Pris'
            name={'product-price'}
            id='product-price-input'
            value={productInput.price}
            flex
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setProductInput({ ...productInput, price: +e.target.value })
            }
          />
          <Input
            label='Varighed (i minutter)'
            name={'product-duration'}
            id='product-duration-input'
            value={productInput.durationInMinutes}
            flex
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setProductInput({ ...productInput, durationInMinutes: +e.target.value })
            }
          />
        </Row>
        <TextArea
          label='Produkt beskrivelse'
          value={productInput.description}
          id='product-description-input'
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setProductInput({ ...productInput, description: e.target.value })
          }
        />
        {/* <Input label='price' type='number' />
          <Input label='duration' type='number' />  */}
        <Toggle
          id='toggle-1'
          label='Privat'
          description='Kan kun bookes af admin'
          value={productInput.isPrivate}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setProductInput({ ...productInput, isPrivate: e.target.checked })
          }
        />
        <Toggle
          id='toggle-2'
          label='Aktiv'
          description='Hvis produktet ikke er aktivt, kan det ikke bookes'
          value={productInput.isActive}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setProductInput({ ...productInput, isActive: e.target.checked })
          }
        />
        <Toggle
          id='toggle-3'
          label='Default'
          description='Der kan kun være et "default", produkt'
          disabled={product?.isDefault}
          value={productInput.isDefault}
          tooltip={
            <Tooltip margin={3} alignment='right'>
              Hvis produktet er markeret som "default", vil det være ud fra dette produkt, de ledige tider vil beregnes
              ud fra. <br />
              <br /> Der kan kun være et "default" produkt, som vil overskrives. <br />
              <br /> Det er typisk det mest normale/bookede produkt.
            </Tooltip>
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            // only make user able to change default, if there is a default already
            if (
              (product?._id || !forcedDefault) &&
              !product?.isDefault
              // (((product?._id && productsLength > 1) || (!product?._id && productsLength > 0)) && !product?.isDefault)
            )
              setProductInput({ ...productInput, isDefault: e.target.checked });
          }}
        />
        <ButtonsContainer>
          <Button label={product?._id ? 'Gem ændring' : 'Opret produkt'} onClick={handleOnSave} />
          <Button label='Annuller' variant='hollow' onClick={onCancel} />
        </ButtonsContainer>
      </FormContent>
    );
  };

  return (
    <Modal toggle={toggle} isOpen={isOpen} title={product?._id ? 'Redigér produkt' : 'Opret nyt produkt'}>
      <ModalContent>{renderContent()}</ModalContent>
    </Modal>
  );
};

export default ProductModal;
