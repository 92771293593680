import { IBooking } from 'interfaces';
import React from 'react';
import { Container } from './style';
import { format, differenceInMinutes } from 'date-fns';
import { Text } from 'components/Styled';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

interface IProps {
  booking: IBooking;
  onClick(): void;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}

const BookingBlock: React.FC<IProps> = ({
  booking: { patient, fromDate, toDate },
  onClick,
  provided,
  snapshot,
}): JSX.Element => {
  const dateFormat = 'H:mm';
  const bookingDurationInMins = differenceInMinutes(toDate, fromDate);
  const bookingHeight = (bookingDurationInMins / 30) * 48;

  return (
    <Container
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      isDragging={snapshot.isDragging}
      height={bookingHeight}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <Text>{`${patient.name} ${patient.lastName}`}</Text>
      <Text>{`${format(fromDate, dateFormat)} - ${format(toDate, dateFormat)}`}</Text>
    </Container>
  );
};

export default BookingBlock;
