import React from 'react';
import { TableContainer } from 'components/Styled';
import { IInvitation } from 'interfaces';
import { Column, useTable } from 'react-table';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, ButtonContainer, Email, UserInfo, UserName } from './style';
import { formatDistanceToNow, isBefore } from 'date-fns';
import { da } from 'date-fns/locale';
import { TextColumn } from '../style';
import { DELETE_INVITATION, UPDATE_INVITATION } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { GET_INVITATIONS } from 'graphql/queries';

interface IProps {
  invitations: IInvitation[];
}

const InviteeTable: React.FC<IProps> = ({ invitations }): JSX.Element => {
  const [data, setData] = useState<IInvitation[]>(invitations);

  const [removeInvitation] = useMutation(DELETE_INVITATION, {
    refetchQueries: [{ query: GET_INVITATIONS }],
  });

  const [resendInvitation] = useMutation(UPDATE_INVITATION, {
    refetchQueries: [{ query: GET_INVITATIONS }],
  });

  useEffect(() => {
    setData(invitations);
  }, [invitations]);

  const columns = React.useMemo<Column<IInvitation>[]>(
    () => [
      {
        Header: 'Navn/email',
        accessor: ({ invitee, email }) => (
          <UserInfo>
            <UserName>{`${invitee.name} ${invitee.lastName}`}</UserName>
            <Email>{email}</Email>
          </UserInfo>
        ),
      },
      {
        Header: 'Sendt',
        accessor: (invitation) => (
          <TextColumn>
            {formatDistanceToNow(new Date(invitation.createdAt), { addSuffix: true, locale: da })}
          </TextColumn>
        ),
      },
      {
        Header: 'Udløber',
        accessor: (invitation) => (
          <TextColumn exp={isBefore(new Date(invitation.expiration), new Date())}>
            {formatDistanceToNow(new Date(invitation.expiration), { addSuffix: true, locale: da })}
          </TextColumn>
        ),
      },
      {
        Header: ' ',
        accessor: (invitation) => (
          <ButtonContainer>
            <Button onClick={() => resendInvitation({ variables: { invitationId: invitation._id } })}>Gensend</Button>
            <Button onClick={() => removeInvitation({ variables: { invitationId: invitation._id } })} isRed>
              Fjern
            </Button>
          </ButtonContainer>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <TableContainer>
      <table {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()}>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </TableContainer>
  );
};

export default InviteeTable;
