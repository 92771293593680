import React, { useState } from 'react';
import { AccordionButton, Container } from './style';
import { IoChevronForward } from 'react-icons/io5';

interface IProps {
  icon: JSX.Element;
  label: string;
  children: React.ReactNode;
}

const Accordion: React.FC<IProps> = ({
  icon,
  label,
  children,
}): JSX.Element => {
  const [show, setShow] = useState<Boolean>(false);
  return (
    <Container>
      <AccordionButton onClick={() => setShow(!show)}>
        <span className="navitem-wrapper">
          {icon && <span className="navitem-icon">{icon}</span>}
          <div className="navitem-text-wrapper">
            <span className="navitem-text">{label}</span>
            <span className="accordion-toggle-icon" aria-expanded={!show}>
              <IoChevronForward className="arrow-icon" size={20} />
            </span>
          </div>
        </span>
      </AccordionButton>
      <div aria-expanded={!show} className="accordion-body">
        {children}
      </div>
    </Container>
  );
};

export default Accordion;
