import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { ModalContent } from 'components/Modal/style';
import Modal from 'components/Modal';
import { IProvider, IProviderAvailability, IProviderTimeSLot, IUser } from 'interfaces';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import Button from 'components/ui/Button';
import {
  IoLockClosedOutline,
  IoCallOutline,
  IoCalendarOutline,
  IoCheckmarkSharp,
  IoCloseSharp,
  IoIdCardOutline,
} from 'react-icons/io5';
import {
  SecureBookingText,
  Icon,
  CheckBoxTermsOfUse,
  OptionalText,
  SelectedDate,
  Label,
  PatientInfo,
  BehandlerInfo,
  ProviderImage,
  ProviderData,
  ProviderName,
  ProviderAddress,
  PatientName,
  SelectedPUser,
  Patient,
  PatientImage,
  PatientPhone,
  PatientCPR,
  ErrorText,
  SelectedIssue,
} from './style';
import Checkbox from 'components/ui/Checkbox';
import Tooltip from 'components/ui/Tooltip';
import { Select } from '../style';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ME } from 'graphql/queries';
import { ADD_BOOKING } from 'graphql/mutations';
import { Loader } from 'app-style';
import { H2 } from 'components/Styled';
import { Loading } from 'components/ui/Animiations';
import BookingContent from './bookingContent';

interface IProps {
  toggle(modal: string): void;
  isOpen: boolean;
  slot: IProviderTimeSLot;
  provider: IProvider;
}

interface ICreateBookingInput {
  providerId: string;
  puserId: string;
  date: Date;
  // CPRNumber: string;
  phoneNumber: string;
  notesToProvider: string;
  productId: string | null;
  acceptedTerms: boolean;
  locationId: string;
  roomId: string;
}

const BookingModal: React.FC<IProps> = ({ toggle, isOpen, slot, provider }): JSX.Element => {
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [notesToProvider, setNotesToProvider] = useState<string>('');
  const [selectedPUser, setSelectedPUser] = useState<IUser | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberEditMode, setPhoneNumberEditMode] = useState<boolean>(false);
  // const [CPRNumber, setCPRNumber] = useState<string>('');
  // const [CPRNumberEditMode, setCPRNumberEditMode] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { data: { me } = {} } = useQuery<{ me: IUser }>(GET_ME);
  const [addBooking, { data: bookingData, loading, error }] = useMutation(ADD_BOOKING);

  useEffect(() => {
    if (bookingData) setSuccess(true);
  }, [bookingData]);

  useEffect(() => {
    // reset on modal close;
    if (!isOpen) {
      setSelectedPUser(null);
      setAcceptedTerms(false);
      setNotesToProvider('');
      setPhoneNumberEditMode(false);
      // setCPRNumber('');
      // setCPRNumberEditMode(false);
    } else {
      if (me?.phone) setPhoneNumber(me.phone);
      if (slot.availablePUsers.length === 1) setSelectedPUser(slot.availablePUsers[0]);
    }
  }, [isOpen]);

  // const onChangeCPRHandler = (e: any) => {
  //   const val = e.target.value;

  //   if (val.length === 6) {
  //     if (e.nativeEvent.inputType === 'deleteContentBackward') {
  //       setCPRNumber(val.slice(0, -1));
  //     } else {
  //       setCPRNumber(val + '-');
  //     }
  //   } else {
  //     setCPRNumber(val);
  //   }
  // };

  const onAddBookingHandler = () => {
    const createBookingInput: ICreateBookingInput = {
      providerId: provider._id,
      puserId: selectedPUser!._id,
      date: slot.timeslot,
      // CPRNumber,
      phoneNumber,
      notesToProvider,
      productId: slot.product ? slot.product._id : null,
      acceptedTerms,
      locationId: slot.availableLocations.length > 1 ? '' : slot.availableLocations[0]._id,
      roomId: slot.availableLocations.length > 1 ? '' : slot.availableLocations[0].rooms[0]._id, // default to first room;
    };
    addBooking({ variables: { createBookingInput } });
  };

  return (
    <Modal toggle={toggle} isOpen={isOpen} title='Book en tid'>
      <ModalContent>
        <BookingContent onCloseModal={() => toggle('')} slot={slot} provider={provider} />
      </ModalContent>
    </Modal>
  );
};

export default BookingModal;
