import styled from 'styled-components';

export const Datarow = styled.div`
  display: inline-flex;
  padding-top: 12px;
  padding-bottom: 12px;

  span {
    color: #666;
    font-size: 16px;
  }

  &:first-child {
    padding-top: 0;
  }

  .patient-name {
    display: flex;
    flex-flow: row;
    align-items: center;

    span {
      margin-left: 12px;
      font-size: 12px;
      cursor: pointer;
      color: blue;
    }
  }
`;

export const Select = styled.div`
  select {
    padding: 12px 12px;
    border: 1px solid #e1deee;
    background-color: #f2f1f9;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    font-size: 13px;
    font-weight: 400;
    /* width: 326px; */
    color: #666;
    line-height: inherit;
    // remove arrow on older IE
    &::-ms-expand {
      display: none;
    }

    &::after {
      content: '';
      width: 0.8em;
      height: 0.5em;
      background-color: var(--select-arrow);
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 18px;
`;

export const ButtonsContainer = styled.div`
  display: inline-flex;
  margin-top: 12px;
`;
