import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.button<{
  bgColor: string;
  border: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  fixedWidth: boolean;
  flex: boolean;
  shadow: boolean;
  color: string;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  padding: ${(props) => (props.border === 'none' ? '10px 12px' : '9px 11px')};
  border: ${(props) => props.border};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) => props.color || '#fff'};
  border-radius: 6px;
  outline: none;
  background: ${(props) => (props.disabled ? COLORS.lightgrey : props.bgColor || '#fff')};
  transition: all 0.3s ease 0s;

  ${({ shadow }) =>
    shadow &&
    `
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  `}

  ${({ fixedWidth }) =>
    fixedWidth &&
    `
    max-width: 100px;
  `}

  ${({ flex }) =>
    flex &&
    `
    width: 100%;
  `}

  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin-bottom: ${marginBottom}px;
  `}

  ${({ marginTop }) =>
    marginTop &&
    `
    margin-top: ${marginTop}px;
  `}

${({ marginLeft }) =>
    marginLeft &&
    `
    margin-left: ${marginLeft}px;
  `}

${({ marginRight }) =>
    marginRight &&
    `
    margin-right: ${marginRight}px;
  `}

  :hover {
    transition: all 0.3s ease 0s;
    ${({ disabled }) =>
      !disabled &&
      `
    filter: brightness(120%);
  `}
  }
`;

export const Icon = styled.div`
  display: flex;
  margin-right: 6px;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  color: inherit;
`;

export const Label = styled.label<{ fontSize?: number }>`
  font-size: ${({ fontSize }) => `${fontSize || 14}px`};
  flex: 1;
  color: inherit;
  font-weight: 600;
  cursor: pointer;
  pointer-events: none;
`;
