import { ApolloError } from '@apollo/client';
import React, { ChangeEvent, useState, KeyboardEvent, FocusEvent } from 'react';
import SingleOTPInput from './SingleOTPInput';
import { Container, ErrorText, Inputs } from './style';

interface IProps {
  numInputs: number;
  value: string;
  onChange(optValue: string): void;
  isDisabled?: boolean;
  shouldAutoFocus?: boolean;
  hasError: ApolloError | undefined;
}

const OTP: React.FC<IProps> = ({
  numInputs = 6,
  value = '',
  onChange,
  isDisabled = false,
  shouldAutoFocus = false,
  hasError,
}): JSX.Element => {
  const [activeInput, setActiveInput] = useState<number>(0);
  // const [inputValues, setInputValues] = useState({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' });

  // const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
  //   const inputName = e.target.name;

  //   setInputValues({ ...inputValues, [inputName]: e.target.value });
  // };

  const getOtpValue = (): string[] => (value ? value.toString().split('') : []);

  const handleOtpChange = (otp: string[]) => {
    const otpValue = otp.join('');

    onChange(otpValue);
  };

  const isValidValue = (value: string) => {
    return !isNaN(parseInt(value, 10)) && value.trim().length === 1;
  };

  // Focus on input by index
  const focusInput = (input: number) => {
    const activeInput = Math.max(Math.min(numInputs - 1, input), 0);
    setActiveInput(activeInput);
  };

  // Focus on next input
  const focusNextInput = () => {
    focusInput(activeInput + 1);
  };

  // Focus on previous input
  const focusPrevInput = () => {
    focusInput(activeInput - 1);
  };

  // Change OTP value at focused input
  const changeCodeAtFocus = (value: string) => {
    const otp = getOtpValue();
    otp[activeInput] = value[0];

    handleOtpChange(otp);
  };

  // Handle pasted OTP
  const handleOnPaste = (e: React.ClipboardEvent) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    const otp = getOtpValue();
    let nextActiveInput = activeInput;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() || '';
        nextActiveInput++;
      }
    }

    setActiveInput(nextActiveInput);
    focusInput(nextActiveInput);
    handleOtpChange(otp);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    if (isValidValue(val)) {
      changeCodeAtFocus(val);
    }
  };

  // Handle cases of backspace, delete, left arrow, right arrow, space
  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      changeCodeAtFocus('');
      focusPrevInput();
    } else if (e.key === 'Delete') {
      e.preventDefault();
      changeCodeAtFocus('');
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault();
      focusPrevInput();
    } else if (e.key === 'ArrowRight') {
      e.preventDefault();
      focusNextInput();
    } else if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Space') {
      e.preventDefault();
    }
  };

  // The content may not have changed, but some input took place hence change the focus
  const handleOnInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (isValidValue(e.target.value)) {
      focusNextInput();
    } else {
      // This is a workaround for dealing with keyCode "229 Unidentified" on Android.
      // if (!isInputNum) {
      //   const nativeEvent = e.nativeEvent;
      //   if (nativeEvent.data === null && nativeEvent.inputType === 'deleteContentBackward') {
      //     e.preventDefault();
      //     this.changeCodeAtFocus('');
      //     this.focusPrevInput();
      //   }
      // }
    }
  };

  const renderInputs = () => {
    const otp = getOtpValue();
    const inputs = [];
    for (let i = 0; i < numInputs; i++) {
      inputs.push(
        <SingleOTPInput
          key={i}
          index={i}
          focus={activeInput === i}
          value={otp && otp[i]}
          shouldAutoFocus={shouldAutoFocus}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onPaste={handleOnPaste}
          onInput={handleOnInput}
          onFocus={(e: FocusEvent<HTMLInputElement>) => {
            setActiveInput(i);
            e.target.select();
          }}
          onBlur={() => setActiveInput(-1)}
          isDisabled={isDisabled}
          hasError={!!hasError}
        />
      );
    }
    return inputs;
  };

  return (
    <Container>
      <Inputs>{renderInputs()}</Inputs>
      {hasError && <ErrorText>{hasError?.message}</ErrorText>}
    </Container>
  );
};

export default OTP;
