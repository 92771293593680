import { COLORS } from 'style';
import styled from 'styled-components';

export const Container = styled.div<{ isFocused?: boolean; hasError: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ hasError }) => (hasError ? COLORS.Primary.red : COLORS.grey)};
  border-radius: 6px;
  height: 48px;
  width: 48px;
  padding: 3px;

  &:not(:last-of-type) {
    margin-right: 12px;
  }

  input {
    border: none;
    height: 100%;
    width: 100%;
    font-size: 32px;
    font-weight: 700;
    color: ${COLORS.Primary.darkblue};
    text-align: center;
    outline: none;
  }
`;
