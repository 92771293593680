import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AnimatedContainer, AccordionQuestion, AccordionQuestionText, AccordionAnswer, Icon } from './style';
import { IoAdd, IoRemove } from 'react-icons/io5';

interface IProps {
  question: string;
  children: React.ReactNode;
}
const Accordion: React.FC<IProps> = ({ question, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const variants = {
    open: { height: 'auto' },
    collapsed: { height: 0, overflow: 'hidden' },
  };

  return (
    <AnimatedContainer>
      <AnimatePresence>
        <Icon
          onClick={() => setIsOpen(!isOpen)}
          key='icon'
          animate={{
            rotate: isOpen ? 90 : 0,
          }}
        >
          {isOpen ? <IoRemove style={{ transform: 'rotate(-90deg)' }} /> : <IoAdd />}
        </Icon>
        <motion.div>
          <AccordionQuestion onClick={() => setIsOpen(!isOpen)}>
            <AccordionQuestionText>{question}</AccordionQuestionText>
          </AccordionQuestion>
          <motion.div
            key={'answer'}
            initial={isOpen ? 'collapsed' : 'open'}
            animate={isOpen ? 'open' : 'collapsed'}
            exit={isOpen ? 'open' : 'collapsed'}
            variants={variants}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <AccordionAnswer>{children}</AccordionAnswer>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </AnimatedContainer>
  );
};

export default Accordion;
