import styled from 'styled-components';

export const Container = styled.div`
  padding: 12px;
`;
export const SliderInput = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  font-weight: 600;
  font-size: 18px;

  input {
    width: 100%;
    margin-bottom: 12px;
    cursor: pointer;
  }
`;
