import React, { useState } from 'react';
import { Container, Overlay, Text } from './style';
import { AnimatePresence } from 'framer-motion';

interface IProps {
  rows?: number;
  text: string;
  overlayColor?: string;
  children?: React.ReactNode;
}

const SmartText: React.FC<IProps> = ({ children, rows = 5, text, overlayColor = '#ffffff' }): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const lineLength = 98;
  const rowHeight = 20;

  const variants = {
    open: { height: 'auto', overflow: 'hidden' },
    collapsed: { height: rows * rowHeight, overflow: 'hidden' },
  };

  return (
    <Container>
      {text.length > rows * lineLength ? (
        <>
          <AnimatePresence>
            <Text
              initial={expanded ? 'collapsed' : 'open'}
              animate={expanded ? 'open' : 'collapsed'}
              exit={expanded ? 'open' : 'collapsed'}
              variants={variants}
            >
              {text}
              {!expanded && <Overlay color={overlayColor} />}
            </Text>
          </AnimatePresence>
          <button onClick={() => setExpanded(!expanded)}>{expanded ? 'Læs mindre' : 'Læs mere'}</button>
        </>
      ) : (
        text
      )}
    </Container>
  );
};

export default SmartText;
