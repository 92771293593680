import styled, { css } from 'styled-components';
import { COLORS } from 'style';
import { H3 } from 'components/Styled';

export const AvailabilityListContainer = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 24px;

  .availability-list {
    text-transform: capitalize;
  }
`;

export const TimeSlotContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 6px;

  .no-available-timeslots {
    color: ${COLORS.grey};
    text-transform: none;
    font-style: italic;
  }
`;

export const InfoContainer = styled.div<{ isSticky: boolean }>`
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
  display: flex;
  flex-flow: row;
  column-gap: 48px;
  margin-top: 12px;
  margin-bottom: 36px;
  background-color: ${COLORS.white};

  ${(props) =>
    props.isSticky &&
    css`
      top: -24px;
      padding: 24px 0;
    `}
`;

export const SelectOptions = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 12px;
  flex: 0.8;
`;

export const TimeSlot = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  padding: 6px 12px;
  border-radius: 6px;
  background-color: ${COLORS.Primary.lightblue};
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.Primary.darkblue};
    color: ${COLORS.white};
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  flex: 1;

  .info-text {
    display: flex;
    align-items: center;
  }

  a {
    color: ${COLORS.Primary.darkblue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const InfoHeader = styled.div`
  display: flex;
  flex-flow: row;
  gap: 24px;
  align-items: center;
`;

export const ProviderImage = styled.picture`
  display: block;
  height: 48px;
  width: 48px;
  border-radius: 50%;

  img {
    width: 100%;
    height: auto;
  }
`;

export const GetMoreTimeslots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  a {
    font-weight: 500;
    cursor: pointer;
    color: ${COLORS.Primary.blue};
    padding: 6px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ProviderName = styled.a`
  font-size: 18px;
  font-weight: 500;
`;

export const ProviderAddress = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${COLORS.darkgrey};
`;
