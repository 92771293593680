import { COLORS } from 'style';
import styled from 'styled-components';

export const Content = styled.div`
  /* margin-top: -100px; */
  /* max-height: 100vh; */
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 500px;
  height: 500px;
`;

export const Title = styled.h2`
  color: ${COLORS.Primary.darkblue};
  font-size: 28px;
  margin-bottom: 6px;
`;

export const Text = styled.p`
  color: ${COLORS.darkgrey};
  font-size: 14px;
  margin: 0 0 24px 0;
`;
