import { H1, H2, H3, H4, Subtitle, Text } from 'components/Styled';
import React from 'react';
import { Aside, Container, Content } from '../style';

const Privacy = () => {
  return (
    <Container>
      <Aside></Aside>
      <Content>
        <H1>Persondatapolitik</H1>
        <Subtitle>Sidst opdateret d. 7. marts, 2022</Subtitle>
        <H3>Vi er den dataansvarlige – hvordan kontakter du os?</H3>
        <Text>
          Sanus ApS er dataansvarlig for behandlingen af de personoplysninger, som vi har modtaget om dig. Du finder
          vores kontaktoplysninger nedenfor.
          <br />
          <br />
          Sanus ApS Ægirsgade 2, 2. th. 2200 København N CVR-nr.: 40406646
          <br />
          <br />
          Telefon: +0045 24759525
          <br />
          <br />
          E-mailadresse: <a href='mailto:info@rivevo.com'>info@rivevo.com</a>
        </Text>
        <H3>Kontaktoplysninger på databeskyttelsesrådgiveren</H3>
        <Text>
          Hvis du har spørgsmål til vores behandling af dine oplysninger, er du altid velkommen til at kontakte vores
          databeskyttelsesrådgiver.
        </Text>
        <br />
        <br />
        Du kan kontakte vores databeskyttelsesrådgiver på følgende måder:
        <ul>
          <li>
            På e-mail: <a href='mailto:az@rivevo.com'>az@rivevo.com</a>
          </li>
          <li>På telefon: +45 24759525</li>
          <li>Ved brev: Ægirsgade 2, 2. th. 2200 København N – husk att. ”Aleksandar Zamboni”</li>
        </ul>
        <H3>
          Formålene med vores behandling af personoplysninger, hvilke typer/kategorier af oplysninger og retsgrundlaget
          for behandlingen af dine personoplysninger
        </H3>
        <H4 italic>Når du bruger vores hjemmeside, indsamler vi følgende personoplysninger:</H4>
        <ul>
          <li>
            Formål: Hvordan bruger vi oplysningerne?
            <ul>
              <li>Levering, personliggørelse og forbedring af vores produkter.</li>
              <li>Levering af måling, analyser og andre erhvervsservicer.</li>
              <li>Forbedring af sikkerhed og integritet.</li>
              <li>Kommunikation med dig.</li>
              <li>Forskning og innovation til gavn for samfundet.</li>
            </ul>
            <br />
          </li>
          <li>Hvilke typer personoplysninger behandler vi: </li>
        </ul>
        <Text>
          Vi indsamler indhold, kommunikation og øvrige oplysninger, du angiver, når du bruger vores produkter, herunder
          de oplysninger du angiver, når du opretter en konto, uploader indhold samt skriver beskeder eller kommunikerer
          med andre. Det kan omfatte oplysninger i eller om det indhold, du angiver (såsom metadata), f.eks. lokationen
          af et billede eller datoen for oprettelsen af en fil. Det kan også omfatte det, du ser via funktioner, som vi
          leverer, f.eks. vores lokations tjenester. Vores systemer behandler automatisk indhold og kommunikation, som
          du og andre angiver, for at analysere konteksten og indholdet til de formål, der er beskrevet nedenfor. Læs
          mere om, hvordan du kan styre, hvem der kan se de ting, du deler.
        </Text>
        <ul>
          <li>Retsgrundlaget for vores behandling af dine personoplysninger følger af:</li>
          <ul>
            <li>
              Litra a i artikel 5, stk. 1, siger, at personoplysninger skal behandles “lovligt, rimeligt og på en
              gennemsigtig måde”. Dette kaldes også populært for god databehandlingsskik. Rivevo behandler dine
              personoplysninger i henhold til de gældende regler jf. persondataforordningen art. 5, stk. 1. litra a.
            </li>
          </ul>
          <br />
          <li>Hvor stammer dine personoplysninger fra:</li>
          <ul>
            <li>
              Når du angiver dine oplysninger for at oprette en konto hos os eller interagerer med vores platform,
              f.eks. ved at skrive eller besvare anmeldelser, Behandler vi de personoplysninger som indsamles.
            </li>
            <br />
            <li>
              Hvis vi modtager oplysninger om dig fra tredjeparter. Hvis du f.eks. opretter en Rivevo-konto via
              Facebook, google, eller lignende sociale tjenester, udfyldes din konto automatisk med de oplysninger, vi
              modtager fra disse. Når en virksomhed beder os om at sende en anmeldelsesinvitation til dig på deres
              vegne, giver de os dit navn, din e-mailadresse og et referencenummer, det kan f.eks. være et ordrenummer
              eller lignende.
            </li>
            <br />
            <li>
              Vi genererer eller indsamler også automatisk oplysninger fra din computer eller enhed, når du bruger vores
              services, f.eks. din IP-adresse og placering, eller oplysninger om den enhed og browser, du bruger, når du
              besøger Rivevo.com.
            </li>
            <br />
          </ul>
          <li>
            <b>Modtagere eller kategorier af modtagere:</b>
          </li>
          Vi videregiver dine personoplysninger til følgende modtagere:
          <ul>
            <li>
              Rivevo vidergiver dine personoplysninger med udvalgte tredjeparter som leverer en række forskellige
              services til os, der understøtter den tekniske drift af vores platform og levering af vores services,
              disse tredjeparter kaldes "databehandlere". Disse databehandlere, behandler de personoplysninger, som vi
              er dataansvarlige for. Vi har indgået databehandleraftaler med databehandlerne, hvoraf det fremgår, at de
              kun må handle i overensstemmelse med vores instruktioner. Ved at acceptere denne persondatapolitik giver
              du os tilladelse til at give disse databehandlere instruktioner om at behandle oplysninger i
              overensstemmelse med denne politik og med henblik på at bruge vores platform.
            </li>
            <br />
            <li>
              Vores databehandlere har truffet passende tekniske og organisatoriske foranstaltninger for at værne om
              oplysningerne mod hændelig eller ulovlig tilintetgørelse, tab eller forringelse og for at beskytte
              oplysningerne mod videregivelse til uautoriserede personer, misbrug eller anden behandling i strid med
              databeskyttelseslove.
            </li>
            <br />
            <li>
              Vores databehandlere og tredjepartsservices kan være lokaliseret i andre lande end Danmark, og kan derfor
              være underlagt andre eller mindre strenge databeskyttelseskrav end dem vi kender fra EU´s
              Databeskyttelsesforordning. I henhold til gældende love i disse lande sikrer vi alligevel, at dine
              oplysninger er beskyttet af passende sikkerhedsforanstaltninger uanset placering. Det gør vi via en
              sammensætning af passende tekniske, organisatoriske og administrative sikkerhedsforanstaltninger og ved at
              sikre, at de nødvendige juridiske aftaler er på plads til at støtte disse krav. Vi overfører f.eks. kun
              europæiske brugeres personoplysninger til lande uden for Det Europæiske Økonomiske Samarbejdsområde, hvis
              der findes en metode til verificering af overførslen, som er godkendt af Europa-Kommissionen.
            </li>
          </ul>
        </ul>
        <H4 italic>Overførsel til modtagere i andre lande:</H4>
        <ul>
          <li>
            <i>
              Vi overfører dine personoplysninger til modtagere i Sverige. Det drejer sig om AMAZON, som i dette
              tilfælde er beliggende i Stockholm Sverige. Sverige har været medlem af EU siden 1995 og er derfor
              underlagt EU´s regler om persondatabeskyttelse på lige for med Danmark.
            </i>
          </li>
        </ul>
        <H4 italic>Opbevaring af dine personoplysninger:</H4>
        <ul>
          <li>Vi opbevarer dine personoplysninger i op til 5år efter du lukker din profil hos Rivevo.</li>
        </ul>
        <H4 italic>Hvis du ansøger om et job hos os, behandles følgende personoplysninger:</H4>
        <ul>
          <li>
            Vi ansætter ikke på baggrund af proaktive ansøgninger, vores ansættelser foregår udelukkende via headhunting
            services som ikke bliver varetaget af vores personale, men af eksterne underleverandører. Vi opbevarer og
            behandler derfor ikke personoplysninger i forbindelse med ansættelser.
          </li>
        </ul>
        <H4 italic>Når du benytter vores tjenester, behandles følgende personoplysninger:</H4>
        <ul>
          <li>
            Formålet med behandlingen af dine personoplysninger er at forbedre vores tjenester, for at maksimere dit
            udbytte af vores platform. Derudover er det nødvendigt for os for at kunne varetage din sikkerhed på vores
            platform.
          </li>
        </ul>
        <H4 italic>Hvilke typer personoplysninger behandler vi:</H4>
        <ul>
          <li>
            Personoplysninger er alle oplysninger, der kan bruges til at identificere en person. Når du opretter en
            brugerkonto på Rivevo, skriver en anmeldelse, opretter en virksomhedskonto på vegne af din virksomhed eller
            på anden måde bruger vores platform, kan vi indsamle og behandle de følgende personoplysninger om dig:
          </li>
          <br />
          <ol>
            <li>
              Kontaktoplysninger: Dit navn og din e-mailadresse eller andre kontaktoplysninger, du til enhver tid
              videregiver til os.
            </li>
            <br />
            <li>
              Oplysninger om din enhed og placering: Din IP-adresse, dine browserindstillinger (hvilken browser du
              bruger, browsersproget og din tidszone) og din placering.
            </li>
            <br />
            <li>
              Oplysninger om din brugerkonto: Dit brugernavn, password, billede, foretrukne sprog og din placering.
            </li>
            <br />
            <li>
              Oplysninger om din virksomhedskonto: Dit password, din virksomheds navn og domæne samt visse andre
              oplysninger, du vælger at føje til din virksomhedsprofil, såsom detaljer om din virksomheds services.
            </li>
            <br />
            <li>
              Brugs- og profiloplysninger: Din søgehistorik på Trustpilot, hvordan du har interageret med vores
              platform, herunder hvor meget tid du bruger på vores website, hvilke funktioner du har brugt, og hvilke
              links du har klikket på.
            </li>
            <br />
            <li>Oplysninger om anmeldelser og bedømmelser, herunder:</li>
            <ol type='a'>
              <li>Hvilken virksomhed du har anmeldt.</li>
              <li>Om du har skrevet en serviceanmeldelse, lokationsanmeldelse eller produktanmeldelse.</li>
              <li>Dit anmeldelsesindhold og din stjernebedømmelse.</li>
              <li>Den anmeldte virksomheds placering.</li>
              <li>Den dato, du skrev, redigerede eller slettede anmeldelsen.</li>
              <li>Dit referencenummer, ordrenummer eller lignende (hvis du eller virksomheden har angivet et).</li>
              <li>
                Hvis du bliver bedt om at opgive flere oplysninger eller sende os dokumentation for din oplevelse,
                indsamler og bruger vi dokumentationen til det/de formål, der er angivet i anmodningen.
              </li>
              <li>
                Oplysninger om rapporteringer og svar på anmeldelser fra din virksomhedskonto: Dit navn, dine
                virksomhedsoplysninger, de oplysninger, du inkluderer i et svar til en anmeldelse, hvilken anmeldelse du
                rapporterede, rapporteringsårsagen samt rapporteringsdatoen.
              </li>
              <li>
                Oplysninger om indstillinger: Indstillinger knyttet til din konto, herunder specifikke samtykker, du har
                givet eller nægtet at give, nyhedsbreve eller lignende e-mailindstillinger og cookies, som indeholder
                dine indstillinger.
              </li>
            </ol>
            <br />
            <li>
              Kommunikationsoplysninger: Omfatter kommunikation, vi modtager fra dig, såsom anmeldelsesnotifikationer,
              feedback, supporthenvendelser og forespørgsler via e-mail og andre elektroniske kommunikationsformer samt
              metadata tilknyttet denne kommunikation, såsom tidspunkt og dato.
            </li>
            <br />
            <li>
              Oplysninger om opkald: Alle indgående og udgående telefonopkald foretaget til eller af Rivevo kan blive
              optaget med henblik på intern undervisning og kvalitetskontrol. Personoplysninger (f.eks. navn og
              kontaktoplysninger) opgivet i et telefonopkald vil blive registreret digitalt for at kunne levere en
              relevant service.
            </li>
            <br />
            <li>
              Oplysninger fra andre services, herunder sociale netværk: Du kan forbinde din Rivevo-profil med dine
              SoMe-profiler, f.eks. Facebook. Hvis du gør det, indsamler vi automatisk udvalgte oplysninger om dig fra
              dit sociale netværk, afhængigt af hvilke oplysninger du har gjort tilgængelige, og dine
              privatlivsindstillinger for deling af sådanne oplysninger på det sociale netværk. Vi kan indsamle følgende
              oplysninger: Navn og/eller brugernavn, E-mailadresse, Profilbillede, og Dine sociale forbindelser.
            </li>
          </ol>
        </ul>
        <Text>
          Før du anmoder om at forbinde din Rivevo-profil med en SoMe-profil, vil du blive informeret om, hvilke
          oplysninger vi indsamler fra det pågældende sociale netværk. Du kan når som helst afbryde forbindelsen mellem
          de to profiler via din Rivevo-konto. Det gør vi ved at fjerne dit ID for det sociale netværk og afbryde/fjerne
          forbindelsen mellem Rivevo og det sociale netværk. Dit profilbillede fra det sociale netværk bliver dog stadig
          vist på din Rivevo-profil, medmindre du ændrer det. <br />
          <br />
          Vi beder dig om ikke at dele andre følsomme personlige dokumenter eller oplysninger med os end dem der er
          nødvendige for vores udbud af behandlinger. De følsomme personlige dokumenter kan være oplysninger, der
          afslører etnisk herkomst, eller religion.
        </Text>
        <H3>Dine rettigheder</H3>
        <Text>
          Du har efter databeskyttelsesforordningen en række rettigheder i forhold til vores behandling af oplysninger
          om dig.
          <br />
          <br />
          Hvis du vil gøre brug af dine rettigheder, skal du kontakte os.
        </Text>
        <H3>Ret til at se oplysninger (indsigtsret)</H3>
        <Text>
          Du har ret til at få indsigt i de oplysninger, som vi behandler om dig, samt en række yderligere oplysninger.
        </Text>
        <H3>Ret til berigtigelse (rettelse)</H3>
        <Text>Du har ret til at få urigtige oplysninger om dig selv rettet.</Text>
        <H3>Ret til sletning</H3>
        <Text>
          I særlige tilfælde har du ret til at få slettet oplysninger om dig, inden tidspunktet for vores almindelige
          generelle sletning indtræffer.
        </Text>
        <H3>Ret til begrænsning af behandling</H3>
        <Text>
          Du har visse tilfælde ret til at få behandlingen af dine personoplysninger begrænset. Hvis du har ret til at
          få begrænset behandlingen, må vi fremover kun behandle oplysningerne – bortset fra opbevaring – med dit
          samtykke, eller med henblik på at retskrav kan fastlægges, gøres gældende eller forsvares eller for at
          beskytte en person eller vigtige samfundsinteresser.
        </Text>
        <H3>Ret til indsigelse</H3>
        <Text>
          Du har i visse tilfælde ret til at gøre indsigelse mod vores eller lovlige behandling af dine
          personoplysninger.
        </Text>
        <Text italic noMargin>
          Du kan også gøre indsigelse mod behandling af dine oplysninger til direkte markedsføring.
        </Text>
        <H3>Ret til at transmittere oplysninger (dataportabilitet)</H3>
        <Text>
          Du har i visse tilfælde ret til at modtage dine personoplysninger i et struktureret, almindeligt anvendt og
          maskinlæsbart format samt at få overført disse personoplysninger fra én dataansvarlig til en anden uden
          hindring.
        </Text>
        <Text>
          Du kan læse mere om dine rettigheder i Datatilsynets vejledning om de registreredes rettigheder, som du finder
          på{' '}
          <a href='https://www.datatilsynet.dk' target='_blank' rel='noreferrer'>
            www.datatilsynet.dk
          </a>
          .
        </Text>
        <H3>Klage til Datatilsynet</H3>
        <Text>
          Du har ret til at indgive en klage til Datatilsynet, hvis du er utilfreds med den måde, vi behandler dine
          personoplysninger på. Du finder Datatilsynets kontaktoplysninger på{' '}
          <a href='https://www.datatilsynet.dk' target='_blank' rel='noreferrer'>
            www.datatilsynet.dk
          </a>
          .
        </Text>
        <H3>Dokumentoplysning</H3>
        <Text>Dette er 1. version af Rivevos persondatapolitik skrevet 17. november 2021.</Text>
      </Content>
    </Container>
  );
};

export default Privacy;
